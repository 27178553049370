import React from 'react';
import {Input,Button,Row,Col,Modal} from 'reactstrap'
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import WalletLog from '../components/walletLog.jsx';
import * as GLOBAL from './global.js'
import FileDownload from "js-file-download";

import Badge12 from '../assets/images/saleslog/badge/12.png'
import Badge13 from '../assets/images/saleslog/badge/13.png'
import Badge14 from '../assets/images/saleslog/badge/14.png'
import Badge15 from '../assets/images/saleslog/badge/15.png'
import Badge16 from '../assets/images/saleslog/badge/16.png'

import DarkBadge12 from '../assets/images/saleslog/badge/12a.png'
import DarkBadge13 from '../assets/images/saleslog/badge/13a.png'
import DarkBadge14 from '../assets/images/saleslog/badge/14a.png'
import DarkBadge15 from '../assets/images/saleslog/badge/15a.png'
import DarkBadge16 from '../assets/images/saleslog/badge/16a.png'

import {TiDelete} from 'react-icons/ti'
import {MdRefresh,MdEdit,MdDelete} from 'react-icons/md'
import {FaFileExcel,FaCopy} from 'react-icons/fa'
import {GoLink} from 'react-icons/go'
import {AiFillCaretDown,AiFillCaretUp,AiOutlineQrcode} from 'react-icons/ai'
import {BsInfoCircleFill} from 'react-icons/bs'
import {AiOutlineRight} from 'react-icons/ai'
import Select from 'react-select'

import ReactPaginate from 'react-paginate'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState,convertToRaw,convertFromRaw,ContentState,AtomicBlockUtils } from 'draft-js'

export default class SalesLog extends React.Component {
    constructor(props) {     
        super(props)

        this.state = {
            loading:false,
            findField:"",
            findField2:"",
            memberList:[],
            viewMode:"search",  //search || detail
            currentMember:{},

            editProfileMode:false,
            toChangeCurrentMember:{},
            newReferrerUsername:"",

            newSalesLog:"",
            tagModal:false,
            selectedTag:[],
            allTags:[],
            tagsToShow:[],
            tagSearch:"",
            salesLogHistory:[],
            activityLog:[],
            archivedConferenceBooking:[],
            offlineEventHistory:[],
            promoList:[],
            courseCreditList:[],
            ticketList:[],
            offlineEventTicketList:[],
            bookedCourseSession:[],
            bookedOfflineEvent:[],
            referredMember:[],
            referralBadgeList:[],
            pWallet:0,
            wWallet:0,
            partialWallet:0,
            theLogsCount:0,
            promoModalVoucher:false,
            currentPromoVoucher:null,

            relationshipModal:false,
            createRelationship:false,
            userOneUsername:"",
            userTwoUsername:"",
            userTwoName:"",
            relationshipOne:"Select a relationship",
            relationshipTwo:"Select a relationship",
            relationshipList:[],

            addTagModal:false,
            tagList:[],
            clientTagList:[],
            clientTagSearch:"",
            relatedClientTag:[],

            quickSearchModal:false,
            quickSearchResult:[],
            quickFindField:"",

            expiryRecord:[],

            transactionHistoryList:[],
            transactionHistoryPages:0,
            pageTransactionHistory:0,

            sortName:"",
            sortPhone:"",
            sortEmail:"",
            sortMembership:"",
            sortJob:"",
            sortJoinDate:"",
            sortCompany:"",
            sortReferrer:"",
            sortPIC:"",

            createTagModal:false,
            editTagModal:false,
            selectedEditTag:null,
            createTagName:"",

            conferenceBookingList:[],
            qrModal:false,
            qrCode:null,
            ticketNumber:null,

            checkExistModal:false,
            checkExistPhone:"",

            walletLogModal:false,
            loadingFromChild:null,           

            earningRecordModal:false,
            earningRecordList:[],

            page:0,
            pageMemberIndex:0,
            displayedPageMemberIndex:0,
            pageSalesLogHistory:0,
            salesLogHistoryPages:0,
            memberPages:0,
            totalMemberCount:0,
            itemPerPage:20,

            totalMemberList:[],

            memberTicketList:[],

            createTicketModal:false,
            createTicketReferrer:null,
            createTicketPIC:null,
            createTicketChannel:null,
            createTicketInterest:null,

            createTicketReferrerError:false,
            createTicketPICError:false,
            createTicketChannelError:false,
            createTicketInterestError:false,


            filterModal:false,
            filterMode:null,
            filterMembership:[],
            filterJob:[],
            filterCompany:"",
            filterPIC:"",
            filterTag:[],
            filterJoinDateFrom:"",
            filterJoinDateTo:"",
            filterBadge:[],
            filterCredit:"",
            filterInstallment:"",
            filterVoucher:[],
            filterArchivedSession:[],
            filterTicket:[],
            filterCourseCredit:[],
            filterCertificate:[],
            filterBookedSession:[],
            filterReferCode:"",
            filterReferRank:[],
            filterReferrer:"",
            filterState:[],
            badgeTitle:[],
            certificateTitle:[],
            courseHistoryTitle:[],
            ticketCourse:[],
            clientTagTitle:[],
            voucherTitle:[],
            excludeMembership:false,
            excludeJob:false,
            excludeReferrer:false,
            excludeReferRank:false,
            excludeBadge:false,
            excludeCertificate:false,

            agentListModal:false,
            agentCompanyList:[],
            agentList:[],
            agentListMode:"agentCompany",
            staffListModal:false,
            staffList:[],

            editorState:EditorState.createEmpty(),

            editModeEditorState:EditorState.createEmpty(),
            editSalesLogMode:false,
            editedLogID:"",

            jobList:[],
            clientStateList:[],

            editBackgroundModal:false,
            editMemberBackground:null,
        }

        this.divRef = React.createRef();
        this.extensionList=[]
        this.relationshipChoices=["Select a relationship","Friend","Boyfriend","Girlfriend","Boss","Subordinate","Colleague","Business Partner","Husband","Wife",
            "Mother","Father","Son","Daughter","Sibling","Cousin","Aunt","Uncle","Nephew","Niece","Relative"]
        this.chineseDay=["日","一","二","三","四","五","六"]
    }
    
    componentDidMount=()=>{
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()

        window.addEventListener("popstate",this.popState)
    }

    componentWillUnmount=()=>{
        window.removeEventListener("popstate",this.popState)
    }

    popState=(event)=>{
        //load current url
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }
        if(window.innerWidth <= 600){
            this.setState({itemPerPage:10})
        }
        else
        {
            this.setState({itemPerPage:20})
        }

        this.parseSearchPath()
    }

    getAllTags=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getalltags`,{timeout:40000})
            .then(async response => {
                var tagGroup=[]
                for(const tag of response.data.tags){
                    var foundGroup=false
                    for(var tagArray of tagGroup){
                        if(tag.group==tagArray[0].group){
                            tagArray.push(tag)
                            foundGroup=true
                            break
                        }
                    }
                    if(!foundGroup){
                        tagGroup.push([tag])
                    }
                }

                this.setState({
                    allTags:tagGroup,
                    tagsToShow:this.hideSubgroupTag(response.data.tags),
                    loading:false,
                    tagModal:true,
                })
            })
            .catch(error =>{
                alert("Get all tags error\n"+error)
                this.setState({loading:false})
            })
    }

    getBadgeTitle=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getbadgetitle`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    badgeTitle:response.data,
                    filterModal:true,
                    filterMode:"badge",
                    filterBadge:[],
                })
            })
            .catch(error =>{
                alert("Get badge title error\n"+error)
                this.setState({loading:false})
            })
    }

    getCertificateTitle=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getcertificatetitle`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    certificateTitle:response.data,
                    filterModal:true,
                    filterMode:"certificate",
                    filterCertificate:[],
                })
            })
            .catch(error =>{
                alert("Get certificate title error\n"+error)
                this.setState({loading:false})
            })
    }

    getCourseHistoryTitle=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getcoursehistorytitle`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    courseHistoryTitle:response.data,
                    filterModal:true,
                    filterMode:"archivedSession",
                    filterArchivedSession:[],
                })
            })
            .catch(error =>{
                alert("Get course history title error\n"+error)
                this.setState({loading:false})
            })
    }

    getClientTagTitle=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getclienttagtitle`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    clientTagTitle:response.data,
                    filterModal:true,
                    filterMode:"tag",
                    filterTag:[],
                })
            })
            .catch(error =>{
                alert("Get client tag title error\n"+error)
                this.setState({loading:false})
            })
    }

    getTicketCourse=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getticketcourse`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    ticketCourse:response.data,
                    filterModal:true,
                    filterMode:"ticket",
                    filterTicket:[],     
                })
            })
            .catch(error =>{
                alert("Get ticket course error\n"+error)
                this.setState({loading:false})
            })
    }

    getCourseCredit=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getcoursecredit`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    courseCredit:response.data,
                    filterModal:true,
                    filterMode:"courseCredit",
                    filterCourseCredit:[],   
                })
            })
            .catch(error =>{
                alert("Get course credit error\n"+error)
                this.setState({loading:false})
            })
    }

    getBookedSession=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getbookedsession`,{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    bookedSession:response.data,
                    filterModal:true,
                    filterMode:"bookedSession",
                    filterBookedSession:[],
                })
            })
            .catch(error =>{
                alert("Get booked session error\n"+error)
                this.setState({loading:false})
            })
    }

    getVoucherTitle=()=>{
        this.setState({loading:true})

        axios
            .get(`sales/getvouchertitle`,{timeout:40000})
            .then(async response => {
                for(let f=0;f<response.data.length;f++){
                    let rawDateSplit=response.data[f].dateTimeCreated.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data[f].dateTimeCreated=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.length;f++){
                    let rawDateSplit=response.data[f].expiryDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data[f].expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    voucherTitle:response.data,
                    filterModal:true,
                    filterMode:"voucher",
                    filterVoucher:[],
                })
            })
            .catch(error =>{
                alert("Get voucher title error\n"+error)
                this.setState({loading:false})
            })
    }

    parseSearchPath=()=>{
        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(let input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="username"){  //seek user by id
                this.searchMemberByUsername(decodeURI(value))
            }else if(param=="search"){
                this.setState({findField:value},()=>{
                    this.searchMember()
                })
            }
        }
    }

    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('Copy to clipboard successful');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }
    copyList=async()=>{
        if(this.state.findField.trim()==""){
            this.setState({findField:"PIC:"+reactLocalStorage.get("username")})
            return
        }
        //request the entire list data from server instead of only local data
        this.setState({loading:true})
        const payload = {
            searchTerm:this.state.findField,
        }
        try {
            const resp = await axios.post(`sales/getsaleslogcopylist`, qs.stringify(payload), {timeout: 180000, responseType: "blob"});
            const filename = "saleslog_result.xlsx"
            FileDownload(resp.data, filename)
        }finally {
            this.setState({loading: false})
        }
    }

    copyListToClipboard=()=>{
        this.setState({loading:true})

        const searchTerm = this.state.findField
            .replace(/\s{2,}/g, ' ')// replace two or more space to single space
            .trim();
        const payload={
            searchTerm:searchTerm,
            page:0,
            itemPerPage:this.state.totalMemberCount
        }
        const endpoint=searchTerm.indexOf(":")!=-1? `sales/filteredfindmemberlist`:`sales/findmemberlist`

        axios
            .post(endpoint,qs.stringify(payload),{timeout:100000})
            .then(async response=>{
                // parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.date_joined
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.date_joined=parsedDate
                }
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.expirydate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.expirydate=parsedDate
                }

                this.setState({
                    loading:false,
                    totalMemberList:response.data.memberList,
                },()=>{
                    // copy the data in excel format to paste
                    var theText="Phone\tName\tEmail\tMembership\tJob\tJoin Date\tCompany\tReferrer\tPIC\n"
                    for(var member of this.state.totalMemberList){
                        theText+="'" + member.username+"\t"
                        theText+=member.name+"\t"
                        theText+=member.email+"\t"
                        theText+=member.membership_type+"\t"
                        theText+=member.job+"\t"
                        theText+=member.date_joined+"\t"
                        if(member.company){
                            theText+=member.company+"\t"
                        }else{
                            theText+="\t"
                        }
                        if(member.referrerName){
                            theText+=member.referrerName+"\t"
                        }else{
                            theText+="\t"
                        }
                        if(member.personInCharge){
                            theText+=member.personInCharge.name
                        }
                        theText+="\n"
                    }
                    this.copyTextToClipboard(theText)
                })
            })
            .catch(error=>{
                if(error.response.status==444){
                    alert("Cannot search empty or No such field")
                    this.setState({loading:false})
                }else if(error.response.status==445){
                    this.setState({
                        viewMode:"search",
                        memberList:[],
                        loading:false,
                    })
                }
            })
    }

    searchMemberByUsername=(username,page=0)=>{
        if(username.trim()==""){
            this.setState({findField:"PIC:"+reactLocalStorage.get("username")},()=>this.searchMember())
            return
        }
        const payload = {
            searchTerm:username,
            page:page,
            itemPerPage:this.state.itemPerPage,
        }
        axios
            .post(`sales/findmemberlist`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.date_joined
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.date_joined=parsedDate
                }
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.expirydate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.expirydate=parsedDate
                }
                if(response.data.memberList.length==0){ //cannot even find one related member
                    this.setState({
                        viewMode:"search",
                        memberList:[],
                        loading:false,
                        currentMember:null,
                        findField:username,
                        totalMemberCount:response.data.totalMemberCount,
                        page:response.data.page,
                        memberPages:response.data.theEmployeePages
                    })
                }else{
                    this.setState({
                        viewMode:"detail",
                        memberList:response.data.memberList,
                        loading:false,
                        currentMember:response.data.memberList[0], //always assume single search result
                        findField:username,
                        totalMemberCount:response.data.totalMemberCount,
                        page:response.data.page,
                        memberPages:response.data.theEmployeePages
                    })
                    this.loadSalesLogHistory(response.data.memberList[0].username) // to get the salesloghistory when they refreshed the page
                }
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot search empty")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("You do not have access right to this user: "+username)
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Find member error\n"+error)
                this.setState({loading:false})
            })
    }

    searchMember=(page=0, searchMemberByIndex=false)=>{
        this.setState({loading:true})
        //in case of filtered search
        if(this.state.findField.indexOf(":")!=-1){
            let searchTerm = this.state.findField
            // .replace(/,\s*/g, ',')// remove space after commas
            const payload = {
                searchTerm:searchTerm,
                page:page,
                itemPerPage:this.state.itemPerPage,
            }
            axios
                .post(`sales/filteredfindmemberlist`, qs.stringify(payload),{timeout:70000})
                .then(async response => {
                    //parse the date
                    for(let item of response.data.memberList){
                        let parsedDate=item.date_joined
                        parsedDate=parsedDate.split("T")
                        parsedDate=parsedDate[0].split("-")
                        parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                        item.date_joined=parsedDate
                    }
                    //parse the date
                    for(let item of response.data.memberList){
                        let parsedDate=item.expirydate
                        parsedDate=parsedDate.split("T")
                        parsedDate=parsedDate[0].split("-")
                        parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                        item.expirydate=parsedDate
                    }
                    if(searchMemberByIndex==true){
                        this.setState({
                            viewMode:"detail",
                            memberList:response.data.memberList,
                            loading:false,
                            currentMember:response.data.memberList[this.state.pageMemberIndex],
                            memberPages:response.data.theEmployeePages,
                            page:page,
                        }, ()=>{
                            this.viewMember(this.state.currentMember)
                            this.loadSalesLogHistory(this.state.currentMember.username)
                        });
                    }else{
                        this.setState({
                            viewMode:"search",
                            memberList:response.data.memberList,
                            loading:false,
                            memberPages: response.data.theEmployeePages,
                            page:response.data.page,
                            totalMemberCount:response.data.totalMemberCount,
                        })
                    }
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            alert("No such field")
                            this.setState({loading:false})
                            return
                        }else if(error.response.status==445){   //cannot find any search result
                            this.setState({
                                viewMode:"search",
                                memberList:[],
                                loading:false,
                            })
                            return
                        }
                    }
                    alert("Find filtered member error\n"+error)
                    this.setState({loading:false})
                })
        }else{
            if(this.state.findField.trim()==""){
                if(reactLocalStorage.get("tier")!="null" && JSON.parse(reactLocalStorage.get("tier")).tier<=0){
                    this.setState({findField:"referrer:"+reactLocalStorage.get("username")},()=>this.searchMember())
                }else{
                    this.setState({findField:"PIC:"+reactLocalStorage.get("username")},()=>this.searchMember())
                }
                return
            }
            let searchTerm = this.state.findField
            .replace(/\s{2,}/g, ' ')// replace two or more space to single space
            .trim();
            const payload = {
                searchTerm:searchTerm,
                page:page,
                itemPerPage:this.state.itemPerPage,
            }
            axios
                .post(`sales/findmemberlist`, qs.stringify(payload),{timeout:30000})
                .then(async response => {
                    //parse the date
                    for(let item of response.data.memberList){
                        let parsedDate=item.date_joined
                        parsedDate=parsedDate.split("T")
                        parsedDate=parsedDate[0].split("-")
                        parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                        item.date_joined=parsedDate
                    }
                    //parse the date
                    for(let item of response.data.memberList){
                        let parsedDate=item.expirydate
                        parsedDate=parsedDate.split("T")
                        parsedDate=parsedDate[0].split("-")
                        parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                        item.expirydate=parsedDate
                    }
                    if(searchMemberByIndex==true){
                        this.setState({
                            viewMode:"detail",
                            memberList:response.data.memberList,
                            loading:false,
                            currentMember:response.data.memberList[this.state.pageMemberIndex],
                            memberPages:response.data.theEmployeePages,
                            page:page,
                        }, ()=>{
                            this.viewMember(this.state.currentMember)
                            this.loadSalesLogHistory(this.state.currentMember.username)                           
                        });
                    }else{
                        this.setState({
                            viewMode:"search",
                            memberList:response.data.memberList,
                            loading:false,
                            memberPages: response.data.theEmployeePages,
                            page:response.data.page,
                            totalMemberCount:response.data.totalMemberCount,
                        })
                    }
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            alert("Cannot search empty")
                            this.setState({loading:false})
                            return
                        }else if(error.response.status==445){   //cannot find any search result
                            this.setState({
                                viewMode:"search",
                                memberList:[],
                                loading:false,
                            })
                            return
                        }
                    }
                    alert("Find member error\n"+error)
                    this.setState({loading:false})
                })
        }
    }

    viewMember=async (theMember)=>{
        //add the member username to url to enable refresh
        let here = new URL(window.location.href)
        here.searchParams.set('username',theMember.username)
        window.history.pushState({},"",here)
        this.setState({
            viewMode:"detail",
            currentMember:theMember,
        })
    }

    getCertificateString=(certs)=>{
        if(certs.length==0)return "-"
        var certString=""
        for(var g=0;g<certs.length;g++){
            certString+=certs[g].title+", "
        }
        certString=certString.substring(0,certString.length-2)
        return certString
    }

    getBadgeString=(badges)=>{
        if(badges.length==0)return "-"
        var badgeString=""
        for(var g=0;g<badges.length;g++){
            badgeString+=badges[g].title+", "
        }
        badgeString=badgeString.substring(0,badgeString.length-2)
        return badgeString
    }

    handlePreviousPage = () => {
        if(this.state.displayedPageMemberIndex>0){
          const previousMember = parseInt(this.state.pageMemberIndex)-1
          const displayedPage = parseInt(this.state.displayedPageMemberIndex)-1
          const pageNumber=parseInt((displayedPage-1)/this.state.itemPerPage)
          if(this.state.page==pageNumber){
            this.setState({
                displayedPageMemberIndex:displayedPage,
                pageMemberIndex:previousMember,
                currentMember:this.state.memberList[previousMember],
            }, ()=>{
                this.viewMember(this.state.currentMember)
                this.loadSalesLogHistory(this.state.currentMember.username)
            });
          }
          else{
            this.setState({
                displayedPageMemberIndex:displayedPage,
                pageMemberIndex:this.state.itemPerPage-1,
                page:pageNumber,
            });
            this.searchMember(pageNumber, true)
          }
        }
      }

    handleNextPage = () => {
    if(this.state.displayedPageMemberIndex>0 && this.state.displayedPageMemberIndex<this.state.totalMemberCount){
        const nextMember=parseInt(this.state.pageMemberIndex)+1;
        const displayedPage=parseInt(this.state.displayedPageMemberIndex)+1
        const pageNumber=parseInt((displayedPage-1)/this.state.itemPerPage)
        if(this.state.page==pageNumber){
        this.setState({
            displayedPageMemberIndex:displayedPage,
            pageMemberIndex:nextMember,
            currentMember:this.state.memberList[nextMember],
        }, ()=>{
            this.viewMember(this.state.currentMember)
            this.loadSalesLogHistory(this.state.currentMember.username)
        })
        }else{
        this.setState({
            displayedPageMemberIndex:displayedPage,
            pageMemberIndex:0,
            page:pageNumber,
        })
        this.searchMember(pageNumber, true)
        }
    }
    }


    hideSubgroupTag=(inputTags)=>{
        var allTags=null
        if(inputTags){
            allTags=inputTags
        }else{
            //dissemble the tag group of alltags
            var dissembledTags=[]
            for(const group of this.state.allTags){
                for(const tag of group){
                    dissembledTags.push(tag)
                }
            }
            allTags=dissembledTags
        }

        //collect all the subgroup names
        var subgroupNames=[]
        for(const tag of allTags){
            if(tag.subgroup){
                subgroupNames.push(tag.subgroup)
            }
        }
        //delete duplicates
        subgroupNames= subgroupNames.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        //go through the subgroup names and remove them from the tag list
        var filteredTags=[]
        for(const tag of allTags){
            var isSubgroupMember=false
            for(const name of subgroupNames){
                if(tag.group==name){
                    isSubgroupMember=true
                    break
                }
            }
            if(!isSubgroupMember){
                filteredTags.push(tag)
            }
        }

        //regroup them by group name
        //group the tags [[tags],[tags]]
        var tagGroup=[]
        for(const tag of filteredTags){
            var foundGroup=false
            for(var tagArray of tagGroup){
                if(tag.group==tagArray[0].group){
                    tagArray.push(tag)
                    foundGroup=true
                    break
                }
            }
            if(!foundGroup){
                tagGroup.push([tag])
            }
        }

        return tagGroup
    }

    addTag=(theTag)=>{
        var currentTag=this.state.selectedTag
        //check if the tag was already added
        for(var j=0;j<currentTag.length;j++){
            if(currentTag[j].name==theTag.name){
                this.setState({tagModal:false})
                return
            }
        }
        currentTag.push(theTag)

        //if the tag has a subgroup, one must tag the subgroup too next
        if(theTag.subgroup){
            //filter out the subgroup
            var subgroupList=[]
            for(var tGroup of this.state.allTags){
                for(var t of tGroup){
                    if(t.group==theTag.subgroup){
                        subgroupList.push(t)
                    }
                }
            }

            //regroup them by group name
            //group the tags [[tags],[tags]]
            var tagGroup=[]
            for(const tag of subgroupList){
                var foundGroup=false
                for(var tagArray of tagGroup){
                    if(tag.group==tagArray[0].group){
                        tagArray.push(tag)
                        foundGroup=true
                        break
                    }
                }
                if(!foundGroup){
                    tagGroup.push([tag])
                }
            }

            this.setState({
                tagsToShow:tagGroup,
                selectedTag:currentTag,
            })
        }else{
            this.setState({
                selectedTag:currentTag,
                tagModal:false,
                tagsToShow:this.hideSubgroupTag(),
            })
        }
    }

    removeTag=(theTag)=>{
        var currentTag=this.state.selectedTag
        for(var r=0;r<currentTag.length;r++){
            if(currentTag[r].name==theTag.name){
                currentTag.splice(r,1)
                break
            }
        }
        this.setState({selectedTag:currentTag})
    }

    postSalesLog=()=>{
        //prepare the tag as list of tag names
        var tagIdList=[]
        for(var t=0;t<this.state.selectedTag.length;t++){
            tagIdList.push(this.state.selectedTag[t].id)
        }

        //post sales log to server
        this.setState({loading:true})
        const payload = {
            message:this.state.newSalesLog,
            tag:JSON.stringify(tagIdList),
            clientUsername:this.state.currentMember.username,
        }
        axios
            .post(`sales/postsaleslog`, qs.stringify(payload))
            .then(async response => {
                this.loadSalesLogHistory()
                this.setState({
                    newSalesLog:"",
                    selectedTag:[],
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Post sales log error\n"+error)
                this.setState({loading:false})
            })
    }

    loadSalesLogHistoryOnPageChange=(currentMemberUsername,page)=>{
        this.setState({
            loading:true,
            salesLogHistory:[],
        })
        if(!currentMemberUsername){
            var currentMemberUsername=this.state.currentMember.username
        }
        const payload = {
            clientUsername:currentMemberUsername,
            page:page,
            itemPerPage:10,
            searchTerm: this.state.findField2
        }
        axios
            .post(`sales/loadsalesloghistoryonpagechange`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse time
                for(var f=0;f<response.data.salesLogHistory.length;f++){
                    let rawDateSplit=response.data.salesLogHistory[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.salesLogHistory[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                for(let i=0;i<response.data.salesLogHistory.length;i++){
                    if(response.data.salesLogHistory[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.salesLogHistory[i].message))
                        response.data.salesLogHistory[i].message=EditorState.createWithContent(content)
                    }
                }
                this.setState({
                    salesLogHistory:response.data.salesLogHistory,
                    salesLogHistoryPages:response.data.theLogsPages,
                    theLogsCount:response.data.theLogsCount,
                    pageSalesLogHistory:response.data.page,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Load sales log history error\n"+error)
                this.setState({loading:false})
            })
    }

    loadSalesLogHistoryOnPageChange=(currentMemberUsername,page)=>{
        this.setState({
            loading:true,
            salesLogHistory:[],
        })
        if(!currentMemberUsername){
            var currentMemberUsername=this.state.currentMember.username
        }
        const payload = {
            clientUsername:currentMemberUsername,
            page:page,
            itemPerPage:10,
            searchTerm: this.state.findField2
        }
        axios
            .post(`sales/loadsalesloghistoryonpagechange`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse time
                for(var f=0;f<response.data.salesLogHistory.length;f++){
                    let rawDateSplit=response.data.salesLogHistory[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.salesLogHistory[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                for(let i=0;i<response.data.salesLogHistory.length;i++){
                    if(response.data.salesLogHistory[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.salesLogHistory[i].message))
                        response.data.salesLogHistory[i].message=EditorState.createWithContent(content)
                    }
                }
                this.setState({
                    salesLogHistory:response.data.salesLogHistory,
                    salesLogHistoryPages:response.data.theLogsPages,
                    theLogsCount:response.data.theLogsCount,
                    pageSalesLogHistory:response.data.page,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Load sales log history error\n"+error)
                this.setState({loading:false})
            })
    }

    searchSalesLog=(theLog)=> {
        this.setState({
            loading:true,
            salesLogHistory:[],
        })
        if(!currentMemberUsername){
            var currentMemberUsername=this.state.currentMember.username
        }
        const payload = {
            clientUsername:currentMemberUsername,
            page:0,
            itemPerPage:10, 
            searchTerm:this.state.findField2
        }
        axios
            .post(`sales/loadsalesloghistoryonpagechange`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse time
                // if (theLog ==1)
                for(let f=0;f<response.data.salesLogHistory.length;f++){
                    let rawDateSplit=response.data.salesLogHistory[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.salesLogHistory[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                for(let i=0;i<response.data.salesLogHistory.length;i++){
                    if(response.data.salesLogHistory[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.salesLogHistory[i].message))
                        response.data.salesLogHistory[i].message=EditorState.createWithContent(content)
                    }
                }

                this.setState({
                    salesLogHistory:response.data.salesLogHistory,
                    salesLogHistoryPages:response.data.theLogsPages,
                    page:response.data.page,
                    theLogsCount:response.data.theLogsCount,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Load sales log history on page change error\n"+error)
                this.setState({loading:false})
            })
    }

    loadTransactionHistoryOnPageChange=(currentMemberUsername, page=0)=>{
        this.setState({
            loading:true,
            transactionHistoryList:[],
        })
        if(!currentMemberUsername){
            var currentMemberUsername=this.state.currentMember.username
        }
        const payload={
            clientUsername:currentMemberUsername,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/loadtransactionhistoryonpagechange`, qs.stringify(payload), {timeout:30000})
            .then(async response=>{
                for(var f=0;f<response.data.transactionHistoryList.length;f++){
                    let rawDateSplit=response.data.transactionHistoryList[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.transactionHistoryList[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+" "+timeSplit[0]+":"+timeSplit[1]
                }
                this.setState({
                    transactionHistoryList:response.data.transactionHistoryList,
                    transactionHistoryPages:response.data.transactionHistoryPages,
                    pageTransactionHistory:page,
                    loading:false,
                })
            })
    }

    loadSalesLogHistory=(currentMemberUsername,page=0)=>{
        this.setState({
            loading:true,
            promoList:[],
            activityLog:[],
            archivedConferenceBooking:[],
            offlineEventHistory:[],
            salesLogHistory:[],
            courseCreditList:[],
            ticketList:[],
            bookedCourseSession:[],
            bookedOfflineEvent:[],
            referredMember:[],
            relationshipList:[],
            transactionHistoryList:[],
            expiryRecord:[],
            clientTagList:[],
        })
        if(!currentMemberUsername){
            var currentMemberUsername=this.state.currentMember.username
        }
        const payload = {
            clientUsername:currentMemberUsername,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/loadsalesloghistory`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse time
                for(let f=0;f<response.data.salesLogHistory.length;f++){
                    let rawDateSplit=response.data.salesLogHistory[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.salesLogHistory[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //parse the date
                for(let item of response.data.courseCreditList){
                    let parsedDate=item.signupDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.signupDate=parsedDate
                }

                //parse the date
                for(let item of response.data.ticketList){
                    let parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate
                }

                //parse data
                for(let item of response.data.offlineEventTicketList){
                    let parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate
                }

                //parse the date
                for(let item of response.data.bookedCourseSession){
                    let parsedDate=item.courseSessionDate
                    parsedDate=parsedDate.split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.courseSessionDate=parsedDate
                }
                //parse date and time
                for(let offlineEventBooking of response.data.bookedOfflineEvent){
                    let theDate=new Date(offlineEventBooking.offlineEvent.startDate)
                    let rawDateSplit=offlineEventBooking.offlineEvent.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split("+")[0].split(":")
                    offlineEventBooking.offlineEvent.calendarDate=dateSplit[0]+"年 "+dateSplit[1]+"月 "+dateSplit[2]+"日 （星期"+this.chineseDay[theDate.getDay()]+"）"
                    let hourSplit=parseInt(timeSplit[0])
                    offlineEventBooking.offlineEvent.time=theDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                    const endTime=theDate.getTime() + 120*60000
                    offlineEventBooking.offlineEvent.timeUntil=new Date(endTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                }

                //parse the date
                for(let item of response.data.activityLog){
                    let parsedDate=item.courseSessionDate                    
                    parsedDate=parsedDate.split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.courseSessionDate=parsedDate                    
                }

                //parse the date
                for(let item of response.data.archivedConferenceBooking){
                    const dateObject = new Date(item.archivedConferenceDateTime);

                    const year = dateObject.getFullYear();
                    const month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
                    const day = dateObject.getDate();

                    const formattedDate = `${day}.${month}.${year}`;   
                    
                    item.archivedConferenceDateTime = formattedDate 
                }
                //parse date and time
                for(let archivedOfflineEventBooking of response.data.offlineEventHistory){
                    let theDate=new Date(archivedOfflineEventBooking.archivedOfflineEvent.startDate)
                    let rawDateSplit=archivedOfflineEventBooking.archivedOfflineEvent.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split("+")[0].split(":")
                    archivedOfflineEventBooking.archivedOfflineEvent.calendarDate=dateSplit[0]+"年 "+dateSplit[1]+"月 "+dateSplit[2]+"日 （星期"+this.chineseDay[theDate.getDay()]+"）"
                    let hourSplit=parseInt(timeSplit[0])
                    archivedOfflineEventBooking.archivedOfflineEvent.time=theDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                    const endTime=theDate.getTime() + 120*60000
                    archivedOfflineEventBooking.archivedOfflineEvent.timeUntil=new Date(endTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                }

                //parse the date
                for(let f=0;f<response.data.promoList.length;f++){
                    let rawDateSplit=response.data.promoList[f].dateTimeCreated.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.promoList[f].dateTimeCreated=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.promoList.length;f++){
                    let rawDateSplit=response.data.promoList[f].expiryDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.promoList[f].expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //parse date
                for(var f=0;f<response.data.referredMember.length;f++){
                    let rawDateSplit=response.data.referredMember[f].date_joined.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.referredMember[f].date_joined=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //parse date
                for(let f=0;f<response.data.transactionHistoryList.length;f++){
                    let rawDateSplit=response.data.transactionHistoryList[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.transactionHistoryList[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //parse date
                for(let f=0;f<response.data.expiryRecord.length;f++){
                    let rawDateSplit=response.data.expiryRecord[f].expiryDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    response.data.expiryRecord[f].expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                //parse date
                for(let f=0;f<response.data.conferenceBookingList.length;f++){
                    let rawDateSplit=response.data.conferenceBookingList[f].conferenceStartTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    response.data.conferenceBookingList[f].conferenceStartTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]

                    rawDateSplit=response.data.conferenceBookingList[f].bookDate.split("T")
                    dateSplit=rawDateSplit[0].split("-")
                    response.data.conferenceBookingList[f].bookDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }
                //wallet log

                //earning record

                //member ticket list
                for(let f=0;f<response.data.memberTicketList.length;f++){
                    let rawDateSplit=response.data.memberTicketList[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.memberTicketList[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.memberTicketList.length;f++){
                    let rawDateSplit=response.data.memberTicketList[f].lastUpdate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.memberTicketList[f].lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                for(let ticket of response.data.memberTicketList){
                    if(ticket.expiryDate!=null){
                        let rawDateSplit=ticket.expiryDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        ticket.expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }
                
                for(let i=0;i<response.data.salesLogHistory.length;i++){
                    if(response.data.salesLogHistory[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.salesLogHistory[i].message))
                        response.data.salesLogHistory[i].message=EditorState.createWithContent(content)
                    }
                }

                this.setState({
                    promoList:response.data.promoList,
                    activityLog:response.data.activityLog,
                    archivedConferenceBooking:response.data.archivedConferenceBooking, 
                    offlineEventHistory:response.data.offlineEventHistory,
                    salesLogHistory:response.data.salesLogHistory,
                    courseCreditList:response.data.courseCreditList,
                    ticketList:response.data.ticketList,
                    offlineEventTicketList:response.data.offlineEventTicketList,
                    conferenceBookingList:response.data.conferenceBookingList,
                    bookedCourseSession:response.data.bookedCourseSession,
                    bookedOfflineEvent:response.data.bookedOfflineEvent,
                    referredMember:response.data.referredMember,
                    relationshipList:response.data.relationshipList,
                    transactionHistoryList:response.data.transactionHistoryList,
                    expiryRecord:response.data.expiryRecord,
                    clientTagList:response.data.clientTagList,
                    pWallet:response.data.pWallet,
                    wWallet:response.data.wWallet,
                    partialWallet:response.data.partialWallet,
                    theLogsCount:response.data.theLogsCount,
                    loading:false,

                    salesLogHistoryPages:response.data.theLogsPages,
                    pageSalesLogHistory:page,

                    transactionHistoryPages:response.data.transactionHistoryPages,
                    pageTransactionHistory:page,

                    memberTicketList:response.data.memberTicketList,
                })
            })
            .catch(error =>{
                alert("Load sales log history error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteSalesLog=(theLog)=>{
        this.setState({loading:true})
        const payload = {
            logID:theLog.id,
        }
        axios
            .post(`sales/deletesaleslog`, qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.loadSalesLogHistory()
            })
            .catch(error =>{
                alert("Delete sales log history error\n"+error)
                this.setState({loading:false})
            })
    }

    saveProfile=()=>{
        //submit the new profile
        this.setState({loading:true})
        const payload = {
            id:this.state.toChangeCurrentMember.id,
            username:this.state.toChangeCurrentMember.username,
            name:this.state.toChangeCurrentMember.name,
            email:this.state.toChangeCurrentMember.email,
            dateOfBirth:this.state.toChangeCurrentMember.dateOfBirth,
            membership:this.state.toChangeCurrentMember.membership_type,
            company:this.state.toChangeCurrentMember.company,
            jobTitle:this.state.toChangeCurrentMember.jobTitle,
            description:this.state.toChangeCurrentMember.description,
            referrerUsername:this.state.newReferrerUsername,
            billingAddress: this.state.toChangeCurrentMember.billingAddress
        }
        axios
            .post(`sales/updateprofile`, qs.stringify(payload))
            .then(async response => {
                this.state.toChangeCurrentMember.referrerName=this.state.newReferrerUsername
                //change the data at the search result list
                let newMemberList=this.state.memberList
                for(let member of newMemberList){
                    if(member.id==this.state.toChangeCurrentMember.id){
                        member.username=this.state.toChangeCurrentMember.username
                        member.name=this.state.toChangeCurrentMember.name
                        member.email=this.state.toChangeCurrentMember.email
                        member.dateOfBirth=this.state.toChangeCurrentMember.dateOfBirth
                        member.membership_type=this.state.toChangeCurrentMember.membership_type
                        member.company=this.state.toChangeCurrentMember.company
                        member.jobTitle=this.state.toChangeCurrentMember.jobTitle
                        member.description=this.state.toChangeCurrentMember.description
                        member.referrerName=this.state.newReferrerUsername
                        member.billingAddress=this.state.toChangeCurrentMember.billingAddress
                        break
                    }
                }
                this.setState({
                    loading:false,
                    editProfileMode:false,
                    currentMember:this.state.toChangeCurrentMember,
                    memberList:newMemberList,

                })

                alert("New profile detail saved")
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //cannot find referrer
                        alert("Cannot find referrer with given username (phone number)")
                    }else{
                        alert("Edit profile data error\n"+error)
                    }
                    this.setState({loading:false,editProfileMode:false})
                    return
                }
                alert("Edit profile data error\n"+error)
                this.setState({loading:false,editProfileMode:false})
            })
    }

    savePromoVoucher=()=>{
        var sureSave=window.confirm("Save changes, are you sure?")
        if(!sureSave){return}

        this.setState({loading:true})
        const payload = {
            promoID:this.state.currentPromoVoucher.id,
            amount:this.state.currentPromoVoucher.amount,
        }
        axios
            .post(`sales/savepromochanges`, qs.stringify(payload))
            .then(async response => {
                alert("Save promo changes successful")
                this.setState({
                    loading:false,
                    promoVoucherModal:false,
                })
                this.loadSalesLogHistory()
            })
            .catch(error =>{
                alert("Save promo changes error\n"+error)
                this.setState({loading:false})
            })
    }

    deletePromoVoucher=()=>{
        var sureDelete=window.confirm("Delete this promo voucher, are you sure?")
        if(!sureDelete){return}

        this.setState({loading:true})
        const payload = {
            promoID:this.state.currentPromoVoucher.id,
        }
        axios
            .post(`sales/deletepromovoucher`, qs.stringify(payload))
            .then(async response => {
                alert("Delete promo changes successful")
                this.setState({
                    loading:false,
                    promoVoucherModal:false,
                })
                this.loadSalesLogHistory()
            })
            .catch(error =>{
                alert("Delete promo changes error\n"+error)
                this.setState({loading:false})
            })
    }

    createNewRelationship=()=>{
        //validate
        if(this.state.userOneUsername==""){
            alert("Please insert username 1")
            return
        }
        if(this.state.userTwoUsername==""){
            alert("Please insert username 2")
            return
        }
        if(this.state.relationshipOne=="Select a relationship"){
            alert("Please select relationship 1")
            return
        }
        if(this.state.relationshipTwo=="Select a relationship"){
            alert("Please select relationship 2")
            return
        }

        this.setState({loading:true})
        const payload = {
            userOneUsername:this.state.userOneUsername,
            userTwoUsername:this.state.userTwoUsername,
            relationshipOne:this.state.relationshipOne,
            relationshipTwo:this.state.relationshipTwo,
        }
        axios
            .post(`sales/createnewrelationship`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                this.setState({
                    loading:false,
                    relationshipModal:false,
                })
                this.loadSalesLogHistory()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot find username 1")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Cannot find username 2")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Create new relationship error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteRelationship=(relationship)=>{
        var deleteConfirm=window.confirm("Delete the relationship?")
        if(deleteConfirm){
            this.setState({loading:true})
            const payload = {
                relationshipID:relationship.id
            }
            axios
                .post(`sales/deleterelationship`, qs.stringify(payload),{timeout:10000})
                .then(async response => {
                    this.setState({
                        loading:false,
                    })
                    this.loadSalesLogHistory()
                })
                .catch(error =>{
                    alert("Delete relationship error\n"+error)
                    this.setState({loading:false})
                })
        }
    }

    quickSearchMember=(searchTerm,page=0)=>{
        this.setState({loading:true})
        const payload = {
            searchTerm:searchTerm,
            page: page,
            itemPerPage:this.state.itemPerPage,
        }
        axios
            .post(`sales/findmemberlist`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    quickSearchResult:response.data.memberList,
                    loading:false,
                    totalMemberCount:response.data.totalMemberCount,
                    page:response.data.page,
                })
            })
            .catch(error =>{
                alert("Find member error\n"+error)
                this.setState({loading:false})
            })
    }

    selectMember=(member)=>{
        this.setState({
            quickSearchModal:false,
            userTwoUsername:member.username,
            userTwoName:member.name,
        })
    }

    loadAllTagList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getallclienttaglist`,{timeout:10000})
            .then(async response => {
                this.setState({
                    addTagModal:true,
                    loading:false,
                    tagList:response.data,
                })
            })
            .catch(error =>{
                alert("Get all client tag list error\n"+error)
                this.setState({loading:false})
            })
    }

    createNewClientTag=()=>{
        if(this.state.createTagName==""){
            return
        }

        this.setState({loading:true})
        const payload = {
            newClientTag:this.state.createTagName,
        }
        axios
            .post(`sales/createnewclienttag`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Create new client tag successful")
                this.setState({loading:false,createTagModal:false})
                this.loadAllTagList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //same client tag exists
                        alert("Same client tag exists")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Create new client tag error\n"+error)
                this.setState({loading:false})
            })
    }

    addTagToClient=(theTag)=>{
        this.setState({loading:true,addTagModal:false})
        const payload = {
            tagTitle:theTag.title,
            clientUsername:this.state.currentMember.username,
        }
        axios
            .post(`sales/addtagtoclient`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                this.setState({loading:false})
                this.loadSalesLogHistory(this.state.currentMember.username)
            })
            .catch(error =>{
                alert("Add tag to client error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteClientTag=(tag)=>{
        let deleteConfirm=window.confirm("Delete the tag for this client?")
        if(deleteConfirm){
            this.setState({loading:true})
            const payload = {
                tagTitle:tag.title,
                username:this.state.currentMember.username
            }
            axios
                .post(`sales/deleteclienttag`, qs.stringify(payload),{timeout:10000})
                .then(async response => {
                    this.setState({
                        loading:false,
                    })
                    this.loadSalesLogHistory()
                })
                .catch(error =>{
                    alert("Delete client tag error\n"+error)
                    this.setState({loading:false})
                })
        }
    }

    fallbackCopyTextToClipboard=(text)=>{
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    
    sortColumn=(column)=>{
        if(column=="Phone"){
            if(this.state.sortPhone=="Down"){
                this.setState({sortPhone:""})
            }else{  //""
                //rearrange array by phone
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    return a.username - b.username
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"Down",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Name"){
            if(this.state.sortName=="Down"){
                this.setState({sortName:""})
            }else{  //""
                //rearrange array by name
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(a.name<b.name)return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"Down",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Email"){
            if(this.state.sortEmail=="Down"){
                this.setState({sortEmail:""})
            }else{  //""
                //rearrange array by phone
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(a.email<b.email)return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"Down",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Membership"){
            if(this.state.sortMembership=="Down"){
                this.setState({sortMembership:""})
            }else{  //""
                //rearrange array by membership
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    let sequence=["Free","CM","CP","GP","SP"]
                    if(sequence.indexOf(a.membership_type)<sequence.indexOf(b.membership_type))return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"Down",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Job"){
            if(this.state.sortJob=="Down"){
                this.setState({sortJob:""})
            }else{  //""
                //rearrange array by job
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    let sequence=["Staff","Agent Leader","Agent","Affiliate Leader","Affiliate","Member"]
                    if(sequence.indexOf(a.job)<sequence.indexOf(b.job))return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"Down",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Join Date"){
            if(this.state.sortJoinDate=="Down"){
                this.setState({sortJoinDate:""})
            }else{  //""
                //rearrange array by join date
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    let aDateSplit=a.date_joined.split(".")
                    let bDateSplit=b.date_joined.split(".")
                    let aDate=new Date(aDateSplit[2],aDateSplit[1],aDateSplit[0])
                    let bDate=new Date(bDateSplit[2],bDateSplit[1],bDateSplit[0])
                    if(aDate<bDate)return 1
                    else return -1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"Down",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Company"){
            if(this.state.sortCompany=="Down"){
                this.setState({sortCompany:""})
            }else{  //""
                //rearrange array
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(!a.company&&!b.company)return 0
                    if(!a.company)return 1
                    if(b.company){
                        if(a.company<b.company)return 1
                        else return -1
                    }else{
                        return -1
                    }
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"Down",
                    sortReferrer:"",
                    sortPIC:"",
                })
            }
        }else if(column=="Referrer"){
            if(this.state.sortReferrer=="Down"){
                this.setState({sortReferrer:""})
            }else{  //""
                //rearrange array
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(!a.referrerName&&!b.referrerName)return 0
                    if(!a.referrerName)return -1
                    if(b.referrerName){
                        if(a.referrerName<b.referrerName)return -1
                        else return 1
                    }else{
                        return 1
                    }
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"Down",
                    sortPIC:"",
                })
            }
        }else if(column=="PIC"){
            if(this.state.sortPIC=="Down"){
                this.setState({sortPIC:""})
            }else{  //""
                //rearrange array
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(!a.personInCharge&&!b.personInCharge)return 0
                    if(!a.personInCharge)return -1
                    if(b.personInCharge){
                        if(a.personInCharge.name<b.personInCharge.name)return -1
                        else return 1
                    }else{
                        return 1
                    }
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortMembership:"",
                    sortJob:"",
                    sortJoinDate:"",
                    sortCompany:"",
                    sortReferrer:"",
                    sortPIC:"Down",
                })
            }
        }
    }

    checkBadge=(badgeArray,courseName)=>{
        for(var badge of badgeArray){
            if(badge.title==courseName){
                return true
            }
        }
        return false
    }

    deleteTag=(theTag)=>{
        if(!window.confirm("Are you sure you want to delete this tag?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            tagID:theTag.id,
        }
        axios
            .post(`sales/deletetag`, qs.stringify(payload))
            .then(async response => {
                alert("Tag successfully deleted.")
                this.setState({createTagModal:false})
                this.loadAllTagList()
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Delete tag error\n"+error)
                this.setState({loading:false})
            })
    }

    saveEditTag=()=>{
        this.setState({loading:true})
        const payload = {
            tagID:this.state.selectedEditTag.id,
            title:this.state.createTagName,
        }
        axios
            .post(`sales/saveedittag`, qs.stringify(payload))
            .then(async response => {
                alert("Tag successfully edited.")
                this.setState({editTagModal:false})
                this.loadAllTagList()
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Edit tag error\n"+error)
                this.setState({loading:false})
            })
    }

    checkPhoneExist=()=>{
        this.setState({loading:true})
        const payload = {
            phone:this.state.checkExistPhone,
        }
        axios
            .post(`sales/checkusernameexist`, qs.stringify(payload))
            .then(async response => {
                let newDate=new Date(response.data.joinDate)
                alert("Member with phone number "+this.state.checkExistPhone+"\nEXIST\nJoin Date: "+newDate)
                this.setState({loading:false})
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Member with phone number "+this.state.checkExistPhone+"\nDOES NOT EXIST")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Check exist error\n"+error)
                this.setState({loading:false})
            })
    }

    handleChildLoading = (loading) => {
        // Update the parent state with data from the child
        this.setState({ loadingFromChild: loading });      
      };

    getEarningRecords=(page=0)=>{        
        const payload = {
            target:this.state.currentMember.id,
            page:page,
            itemPerPage : window.innerWidth <=600? 4: 4,
        }
        axios
            .post(`sales/getearningrecord`,qs.stringify(payload),{timeout:60000})
            .then(async response => {
                //parse date
                for(let log of response.data.earningRecordList){
                    let rawDateSplit=log.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    log.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                    let splitArray=log.description.split(" ")
                    let fromString=splitArray[3]
                    let fromStringrawDateSplit=fromString.split("T")
                    let fromStringdateSplit=fromStringrawDateSplit[0].split("-")
                    fromString=fromStringdateSplit[2]+"."+fromStringdateSplit[1]+"."+fromStringdateSplit[0]
                    let toString=splitArray[6]
                    let toStringrawDateSplit=toString.split("T")
                    let toStringdateSplit=toStringrawDateSplit[0].split("-")
                    toString=toStringdateSplit[2]+"."+toStringdateSplit[1]+"."+toStringdateSplit[0]
                    log.description="Commission distribution from "+fromString+" to "+toString
                }

                this.setState({
                    loading:false,
                    earningRecordModal:true,
                    earningRecordList:response.data.earningRecordList,

                    page:response.data.page,
                    earningRecordPage: response.data.earningRecordPage,
                    earningRecordTotal: response.data.earningRecordTotal
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get earning records error\n"+error)
                this.setState({loading:false})
            })
    }
   
    createTicket=()=>{
        //validate
        let errorMessage=[]
        if(this.state.createTicketPIC==null||this.state.createTicketPIC.length==0){
            errorMessage.push("PIC cannot be left empty")
            this.setState({createTicketPICError:true})
        }else{
            this.setState({createTicketPICError:false})
        }

        if(this.state.createTicketChannel==null){
            errorMessage.push("Please select a channel")
            this.setState({createTicketChannelError:true})
        }else{
            this.setState({createTicketChannelError:false})
        }

        if(this.state.createTicketInterest==null||this.state.createTicketInterest.trim().length==0){
            errorMessage.push("Please enter interest of the client")
            this.setState({createTicketInterestError:true})
        }else{
            this.setState({createTicketInterestError:false})
        }

        if(errorMessage.length>0){
            alert(errorMessage.join("\n"))
            return
        }

        this.setState({loading:true})
        const payload = {
            loading:true,
            userID:this.state.currentMember.id,
            referrer:this.state.createTicketReferrer,
            pic:this.state.createTicketPIC,
            channel:this.state.createTicketChannel,
            interest:this.state.createTicketInterest,
        }
        axios
            .post(`sales/creatememberticket`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                alert("Member ticket successfully created")
                this.setState({
                    loading:false,
                    createTicketModal:false,

                    createTicketReferrer:null,
                    createTicketPIC:null,
                    createTicketChannel:null,
                    createTicketInterest:null,

                    createTicketReferrerError:false,
                    createTicketPICError:false,
                    createTicketChannleError:false,
                    createTicketInterestError:false,
                })
                this.loadSalesLogHistory(this.state.currentMember.username)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot find the PIC")
                        this.setState({
                            loading:false,
                            createTicketPICError:true,
                        })
                        return
                    }if(error.response.status==449){
                        alert("PIC is not Agent or Staff")
                        this.setState({
                            loading:false,
                            createTicketPICError:true,
                        })
                        return
                    }else if(error.response.status==448){
                        alert("Cannot find the Referrer")
                        this.setState({
                            loading:false,
                            createTicketReferrerError:true,
                        })
                        return
                    }else if(error.response.status==450){
                        alert("Ticket with same PIC already exists")
                        this.setState({
                            loading:false,
                            createTicketPICError:true,
                        })
                        return
                    }
                }
                alert("Create new member ticket error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }


    filterSalesLog=(findFilter)=>{
        if(this.state.excludeBadge){
            findFilter="!badge"
            this.setState({excludeBadge:false})
        }else if(this.state.excludeCertificate){
            findFilter="!certificate"
            this.setState({excludeCertificate:false})
        }

        let find=this.cleanFilter(findFilter)
        this.setState({
            findField:find,
            filterModal:false,
            filterMode:null,
        },()=>{
            this.searchMember()
        })
    }

    removeParam=(find,param)=>{
        // let findSplit=find.replace(/!/g,"").split(";")
        let findSplit = find.split(";")
        for (let i = 0; i < findSplit.length; i++) {
            if (!findSplit[i].startsWith("!badge") && !findSplit[i].startsWith("!certificate")) {
                findSplit[i] = findSplit[i].replace(/!/g, "")
            }
        }

        let indexToRemove=-1
        for(let [index,f] of findSplit.entries()){
            let fSplit=f.split(":")
            let thePar=fSplit[0]
            if(thePar==param){
                indexToRemove=index
            }
        }
        if(indexToRemove!=-1){
            findSplit.splice(indexToRemove,1)
            return findSplit.join(";")
        }else{
            return find
        }
    }

    cleanFilter=(findFilter)=>{
        let find=this.state.findField
        find=this.removeParam(find,findFilter)
        if(find.length>0){
            find+=";"
        }
        if(this.state.filterMode=="membership"){
            if(this.state.excludeMembership==true){
                this.setState({excludeMembership:false})
                findFilter="!membership"
                find+=findFilter+":"+this.state.filterMembership.join(",")
            }else{
                find+=findFilter+":"+this.state.filterMembership.join(",")
            }
        }else if(this.state.filterMode=="job"){
            if(this.state.excludeJob==true){
                this.setState({excludeJob:false})
                findFilter="!job"
                find+=findFilter+":"+this.state.filterJob.join(",")
            }else{
                find+=findFilter+":"+this.state.filterJob.join(",")
            }
        }else if(this.state.filterMode=="company"){
            find+=findFilter+":"+this.state.filterCompany
        }else if(this.state.filterMode=="PIC"){
            find+=findFilter+":"+this.state.filterPIC
        }else if(this.state.filterMode=="tag"){
            find+=findFilter+":"+this.state.filterTag
        }else if(this.state.filterMode=="joinDate"){
            let gotFrom=false
            if(this.state.filterJoinDateFrom!=""){
                find+=findFilter+":"+this.state.filterJoinDateFrom
                gotFrom=true
            }
            if(this.state.filterJoinDateTo!=""){
                if(gotFrom==true){
                    find+=">"+this.state.filterJoinDateTo
                }
            }
        }else if(this.state.filterMode=="badge"){
            find+=findFilter+":"+this.state.filterBadge
        }else if(this.state.filterMode=="credit"){
            find+=findFilter+":"+this.state.filterCredit
        }else if(this.state.filterMode=="installmentWallet"){
            find+=findFilter+":"+this.state.filterInstallment
        }else if(this.state.filterMode=="voucher"){
            find+=findFilter+":"+this.state.filterVoucher
        }else if(this.state.filterMode=="archivedSession"){
            find+=findFilter+":"+this.state.filterArchivedSession
        }else if(this.state.filterMode=="ticket"){
            find+=findFilter+":"+this.state.filterTicket
        }else if(this.state.filterMode=="courseCredit"){
            find+=findFilter+":"+this.state.filterCourseCredit
        }else if(this.state.filterMode=="certificate"){
            find+=findFilter+":"+this.state.filterCertificate
        }else if(this.state.filterMode=="bookedSession"){
            find+=findFilter+":"+this.state.filterBookedSession
        }else if(this.state.filterMode=="referCode"){
            find+=findFilter+":"+this.state.filterReferCode
        }else if(this.state.filterMode=="referRank"){
            if(this.state.excludeReferRank==true){
                this.setState({excludeReferRank:false})
                findFilter="!referRank"
                find+=findFilter+":"+this.state.filterReferRank.join(",")
            }else{
                find+=findFilter+":"+this.state.filterReferRank.join(",")
            }
        }else if(this.state.filterMode=="referrer"){
            if(this.state.excludeReferrer==true){
                this.setState({excludeReferrer:false})
                findFilter="!referrer"
                find+=findFilter+":"+this.state.filterReferrer
            }else{
                find+=findFilter+":"+this.state.filterReferrer
            }
        }else if(this.state.filterMode=="state"){
            const stateName = this.state.filterState.map(state => state.name || state).join(",")
            find+=findFilter+":"+stateName
        }

        return find
    }

    addSelectedMembership=(memberShip)=>{
        let foundSame=-1
        let listClone=this.state.filterMembership
        for(let [index,c] of listClone.entries()){
            if(c==memberShip){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(memberShip)
        }
        this.setState({filterMembership:listClone})
    }

    addSelectedJob=(job)=>{
        let foundSame=-1
        let listClone=this.state.filterJob
        for(let [index,c] of listClone.entries()){
            if(c==job){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(job)
        }
        this.setState({filterJob:listClone})
    }

    addSelectedSession =(booked)=>{
        let foundSame=-1
        let listClone=this.state.filterBookedSession
        for(let [index,c] of listClone.entries()){
            if(c==booked){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(booked)
        }
        this.setState({filterBookedSession:listClone})
    }

    addSelectedReferRank=(referRank)=>{
        let foundSame=-1
        let listClone=this.state.filterReferRank
        for(let [index,c] of listClone.entries()){
            if(c==referRank){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(referRank)
        }
        this.setState({filterReferRank:listClone})
    }

    addSelectedTicket =(ticket)=>{
        let foundSame=-1
        let listClone=this.state.filterTicket
        for(let [index,c] of listClone.entries()){
            if(c==ticket){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(ticket)
        }
        this.setState({filterTicket:listClone})
    }

    addSelectedCourse =(course)=>{
        let foundSame=-1
        let listClone=this.state.filterCourseCredit
        for(let [index,c] of listClone.entries()){
            if(c==course){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(course)
        }
        this.setState({filterCourseCredit:listClone})
    }

    addSelectedBadge=(badge)=>{
        var searchString = ''
        for(var i=0;i<badge.length;i++){
            searchString = searchString+''+badge[i].value
            if(i < (badge.length -1)){
                searchString = searchString+','
            }
        }

        this.setState({filterBadge:searchString})
    }

    addSelectedTag=(tag)=>{
        var searchString = ''
        for(var i=0;i<tag.length;i++){
            searchString = searchString+''+tag[i].value
            if(i < (tag.length -1)){
                searchString = searchString+','
            }
        }

        this.setState({filterTag:searchString})
    }

    addSelectedArchived=(archived)=>{
        var searchString = ''
        for(var i=0;i<archived.length;i++){
            searchString = searchString+''+archived[i].value
            if(i < (archived.length -1)){
                searchString = searchString+','
            }
        }

        this.setState({filterArchivedSession:searchString})
    }

    addSelectedCertificate=(certificate)=>{
        var searchString = ''
        for(var i=0;i<certificate.length;i++){
            searchString = searchString+''+certificate[i].value
            if(i < (certificate.length -1)){
                searchString = searchString+','
            }
        }

        this.setState({filterCertificate:searchString})
    }

    addSelectedVoucher=(voucher)=>{
        let foundSame=-1
        let listClone=this.state.filterVoucher
        for(let [index,c] of listClone.entries()){
            if(c==voucher){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(voucher)
        }
        this.setState({filterVoucher:listClone})
    }

    addSelectedState=(state)=>{
        let foundSame=-1
        let listClone=this.state.filterState
        for(let [index,s] of listClone.entries()){
            if(s==state){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(state)
        }
        this.setState({filterState:listClone})
    }

    filterOccurrenceBooked = (booked) => {
        let occurrences =[];
        for(var i=0;i<booked.length;i++){
            if(occurrences.indexOf(booked[i].courseSessionTitle) === -1){
                occurrences.push(booked[i].courseSessionTitle);
            }
        }
        return occurrences
      };

    filterOccurrenceVoucher = (voucher) => {
        let occurrences =[];
        for(var i=0;i<voucher.length;i++){
            if(occurrences.indexOf(voucher[i].title) === -1){
                occurrences.push(voucher[i].title);
            }
        }
        return occurrences
      };

    filterOccurrenceTicket = (ticket) => {
        let occurrences =[];
        for(var i=0;i<ticket.length;i++){
            if(occurrences.indexOf(ticket[i].courseName) === -1){
                occurrences.push(ticket[i].courseName);
            }
        }
        return occurrences
      };

    filterOccurrenceCourseCredit = (course) => {
        let occurrences =[];
        for(var i=0;i<course.length;i++){
            if(occurrences.indexOf(course[i].courseString) === -1){
                occurrences.push(course[i].courseString);
            }
        }
        return occurrences
      };

    loadStaffList=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/loadstafflist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    staffList:response.data,
                    staffListModal:true,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load staff list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadAgentCompanyList=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/loadagentcompanylist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentCompanyList:response.data,
                    agentList:[],   //clear
                    agentListModal:true,
                    agentListMode:"agentCompany",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load agent company list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadAgentList=(agentCompanyID)=>{
        this.setState({loading:true})
        const payload = {
            companyID:agentCompanyID,
        }
        axios
            .post(`sales/loadagentlist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentList:response.data,
                    agentListMode:"agent",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load agent list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    imageUploadCallback = (file) => {
        return new Promise((resolve, reject) => {
            const imageObject = {
                file: file,
                localSrc: URL.createObjectURL(file),
            }
    
            let re = /(?:\.([^.]+))?$/
            let extension = re.exec(file.name)[1]
            this.extensionList.push([imageObject.localSrc, extension])
    
            const img = new Image()
            img.src = imageObject.localSrc
            img.onload = () => {
                let width = img.width
                let height = img.height
    
                // Check if width is greater than 1000 pixels
                if (width > 1000) {
                    height *= 1000 / width
                    width = 1000
    
                    // Create a canvas element for resizing
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.width = width
                    canvas.height = height
                    ctx.drawImage(img, 0, 0, width, height)
    
                    // Convert the canvas back to a blob
                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        })
    
                        // Resolve with resized image data
                        resolve({
                            data: {
                                link: URL.createObjectURL(resizedFile),
                                width: width,
                                height: height,
                            },
                        })
                    }, file.type)
                } else {
                    // If image doesn't need resizing, resolve with original data
                    resolve({
                        data: {
                            link: imageObject.localSrc,
                            width: width,
                            height: height,
                            style: {
                                objectFit: 'cover',
                                width: '100%',  // Optional: Ensures image takes full width of its container
                                height: '100%', // Optional: Ensures image takes full height of its container
                            },
                        },
                    })
                }
            }
            img.onerror = () => {
                reject(new Error("Image loading failed"))
            }
        })
    }

    handlePastedFiles = (files) => {
        const file = files[0];
        if (file.type.startsWith('image/')) {
            this.imageUploadCallback(file).then((response) => {
                const contentState = this.state.editorState.getCurrentContent()
                const contentStateWithEntity = contentState.createEntity(
                    'IMAGE',
                    'IMMUTABLE',
                    { src: response.data.link }
                )
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
                const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithEntity })
                const newState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
                this.onEditorStateChange(newState)
            })
            return 'handled'
        }
        return 'not-handled'
    }

    handleEditModePastedFiles = (files) => {
        const file = files[0]
        if (file.type.startsWith('image/')) {
            this.imageUploadCallback(file).then((response) => {
                const contentState = this.state.editModeEditorState.getCurrentContent()
                const contentStateWithEntity = contentState.createEntity(
                    'IMAGE',
                    'IMMUTABLE',
                    { src: response.data.link }
                )
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
                const newEditorState = EditorState.set(this.state.editModeEditorState, { currentContent: contentStateWithEntity })
                const newState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
                this.editModeOnEditorStateChange(newState)
            })
            return 'handled'
        }
        return 'not-handled'
    }

    resizableImage = ({ block }) => {
        const contentState = this.state.editorState.getCurrentContent()
        const entity = contentState.getEntity(block.getEntityAt(0))
        const { src } = entity.getData()
        return <img src={src} style={{ maxWidth: '100%' }} />
    }

    editModeOnEditorStateChange=(editModeEditorState)=>{
        this.setState({editModeEditorState})
    }
    onEditorStateChange=(editorState) => {
        this.setState({
            editorState,
        })
    }

    post=async ()=>{
        let theJSON=convertToRaw(this.state.editorState.getCurrentContent())
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink.includes("blob:")){
                //add to the upload queue
                imagesToUpload.push(srcLink)
            }
        }
        this.setState({loading:true})
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
            .post(`sales/uploadsaleslogimage`, formData, {headers: {"Content-type": "multipart/form-data"}},{timeout:40000})
            .then(async response => {
                //replace the blobs with returned server image links {serverLink:,imageName:}
                let imageLinks=response.data.processedLinks
                for(const image of imageLinks){
                    for(const key of theKeys){
                        let srcLink=theImages[key].data.src
                        if(srcLink==image.originalName){
                            theJSON.entityMap[key].data.src=image.serverLink
                        }
                    }
                }
                var tagIdList=[]
                for(var t=0;t<this.state.selectedTag.length;t++){
                    tagIdList.push(this.state.selectedTag[t].id)
                }
                var fileName=[]
                for(var i=0;i<response.data.fileName.length;i++){
                    fileName.push(response.data.fileName[i])
                }
                //save the html as raw JSON to server
                const payload = {
                    message:JSON.stringify(theJSON),
                    tag:JSON.stringify(tagIdList),
                    clientUsername:this.state.currentMember.username,
                    fileName:JSON.stringify(fileName),
                }

                axios
                    .post(`sales/postsaleslog`, qs.stringify(payload),{timeout:40000})
                    .then(async response => {
                        this.loadSalesLogHistory()
                        this.setState({
                            editorState:EditorState.createEmpty(),
                            newSalesLog:"",
                            selectedTag:[],
                            loading:false,
                         })
                    })
                    .catch(error =>{
                        alert("Post sales log error\n"+error)
                        this.setState({loading:false})
                    })
            })
            .catch(error =>{
                alert("Upload image error\n"+error)
                this.setState({loading:false,})
            })
    }

    getSalesLog=(theLog)=>{
        this.setState({loading:true})
        const payload={
            logID:theLog.id,
        }
        axios 
            .post('sales/getsaleslog',qs.stringify(payload))
            .then(async response=>{
                let content = convertFromRaw(JSON.parse(response.data.message))
                this.setState({
                    editModeEditorState:EditorState.createWithContent(content),
                    loading:false,
                    editedLogID:theLog.id,
                })
            })
            .catch(error=>{
                this.setState({loading:false})
            })
    }

    editSalesLog=async ()=>{
        let theJSON=convertToRaw(this.state.editModeEditorState.getCurrentContent())
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink.includes("blob:")){
                //add to the upload queue
                imagesToUpload.push(srcLink)
            }
        }
        this.setState({loading:true})
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
            .post(`sales/uploadsaleslogimage`, formData, {headers: {"Content-type": "multipart/form-data"}},{timeout:40000})
            .then(async response => {
                //replace the blobs with returned server image links {serverLink:,imageName:}
                let imageLinks=response.data.processedLinks
                for(const image of imageLinks){
                    for(const key of theKeys){
                        let srcLink=theImages[key].data.src
                        if(srcLink==image.originalName){
                            theJSON.entityMap[key].data.src=image.serverLink
                        }
                    }
                }
                let tagNameList=[]
                for(let t=0;t<this.state.selectedTag.length;t++){
                    tagNameList.push(this.state.selectedTag[t].name)
                }
                let fileName=[]
                for(let i=0;i<response.data.fileName.length;i++){
                    fileName.push(response.data.fileName[i])
                }
                //save the html as raw JSON to server
                const payload = {
                    message:JSON.stringify(theJSON),
                    tag:JSON.stringify(tagNameList),
                    logID:this.state.editedLogID,
                    fileName:JSON.stringify(fileName),
                }

                axios
                    .post(`sales/editsaleslog`, qs.stringify(payload),{timeout:40000})
                    .then(async response => {
                        this.loadSalesLogHistory()
                        this.setState({
                            editModeEditorState:EditorState.createEmpty(),
                            newSalesLog:"",
                            selectedTag:[],
                            loading:false,
                            editSalesLogMode:false,
                            })
                    })
                    .catch(error =>{
                        alert("Edit sales log error\n"+error)
                        this.setState({loading:false})
                    })
            })
            .catch(error =>{
                alert("Upload image error\n"+error)
                this.setState({loading:false,})
            })
    }

    getJobList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getjoblist`, qs.stringify(payload),{timeout:15000})
            .then(async response => {
                this.setState({
                    loading:false,
                    jobList:response.data,
                    filterModal:true,
                    filterMode:"job",
                    filterJob:[]
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get job list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getClientStateList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getclientstatelist`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    clientStateList:response.data,
                    filterModal:true,
                    filterMode:"state",
                    filterState:[],
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get client state list error\n"+error)
                this.setState({loading:false})
            })
    }

    saveMemberBackground=()=>{
        this.setState({loading:true})
        const payload = {
            memberID:this.state.currentMember.id,
            editMemberBackground:this.state.editMemberBackground,
        }
        axios
            .post(`sales/savememberbackground`, qs.stringify(payload),{timeout:15000})
            .then(async response => {
                this.setState({
                    editBackgroundModal:false,
                })
                this.searchMemberByUsername(this.state.currentMember.username)
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Edit member background info error\n"+error)
                this.setState({loading:false})
            })
    }
    
    render(){
        return(
            <div style={{overflow:"hidden"}}>
                {
                    this.state.loading || this.state.loadingFromChild ?
                        <LoadingScreen />
                        : null
                }

                <Modal isOpen={this.state.editBackgroundModal} toggle={()=>this.setState({editBackgroundModal:false})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Edit Background Info</div>
                        <textarea style={{padding:10,width:"100%",border:"1px solid lightgrey",borderRadius:5}} rows={7} value={this.state.editMemberBackground} onChange={(e)=>{
                            this.setState({editMemberBackground:e.target.value})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1,}} onClick={()=>{
                                this.saveMemberBackground()
                            }}>
                                Save
                            </Button>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({editBackgroundModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.staffListModal} toggle={()=>this.setState({staffListModal:false})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Staff List</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.staffList.map((staff)=>{
                                return(
                                    <div style={{padding:5,fontSize:14,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                        if(this.state.addContactModal==true){
                                            this.setState({contactPIC:staff.username})
                                        }else if(this.state.createTicketModal==true){
                                            this.setState({createTicketPIC:staff.username})
                                        }
                                        this.setState({                                            
                                            staffListModal:false,
                                        })
                                    }}>
                                        {
                                        staff.username==staff.name?
                                            staff.name
                                            :
                                            staff.username+" "+staff.name
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({staffListModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.agentListModal} toggle={()=>this.setState({agentListModal:false})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Agent List</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.agentListMode=="agent"?
                                <Button color="light" style={{border:"1px solid lightgrey",marginBottom:10}} onClick={()=>{
                                    this.loadAgentCompanyList()
                                }}>
                                    Back
                                </Button>
                                :
                                null
                            }
                            
                            {/*
                            <Input value={this.state.changeReferrerPhone} onChange={(e)=>{
                                this.setState({changeReferrerPhone:e.target.value.replace(/\D/g,"")})
                            }} />
                            */}
                        </div>
                        {
                        this.state.agentListMode=="agentCompany"?
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                {
                                this.state.agentCompanyList.map((agentCompany)=>{
                                    return(
                                        <div style={{padding:10,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                            this.loadAgentList(agentCompany.id)
                                        }}>
                                            {agentCompany.name}
                                        </div>
                                    )
                                })
                                }
                            </div>
                            :
                        this.state.agentListMode=="agent"?
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                {
                                this.state.agentList.map((agent)=>{
                                    return(
                                        <div style={{padding:5,fontSize:14,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                            if(this.state.addContactModal==true){
                                                this.setState({contactPIC:agent.username,})
                                            }else if(this.state.createTicketModal==true){
                                                this.setState({createTicketPIC:agent.username})
                                            }
                                            this.setState({
                                                agentListModal:false
                                            })
                                        }}>
                                            {
                                            agent.username==agent.name?
                                                agent.name+" ("+agent.tierName+")"
                                                :
                                                agent.username+" "+agent.name+" ("+agent.tierName+")"
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                            :
                            null
                        }
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({agentListModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.createTicketModal} toggle={()=>this.setState({createTicketModal:false})} style={{width:600,maxWidth:2000}}>
                    {
                    this.state.currentMember!=null?
                        <div style={{padding:25,borderRadius:10}}>
                            <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Create Ticket</div>
                            <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{padding:5}}>Username</td>
                                        <td style={{padding:5}}>{this.state.currentMember.username}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:5}}>Name</td>
                                        <td style={{padding:5}}>{this.state.currentMember.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:5}}>Email</td>
                                        <td style={{padding:5}}>{this.state.currentMember.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{height:20}}></td>
                                    </tr>
                                    {/*
                                    <tr style={{}}>
                                        <td style={{padding:5,whiteSpace:"nowrap"}}>Referrer Phone</td>
                                        <td style={{padding:5}}>
                                            <Input style={{borderColor:this.state.createTicketReferrerError?"salmon":"lightgrey"}} 
                                                value={this.state.createTicketReferrer} 
                                                onChange={(e)=>{
                                                    this.setState({createTicketReferrer:e.target.value.replace(/\D/g,"")})
                                            }} />
                                        </td>
                                    </tr>
                                    */}
                                    <tr>
                                        <td style={{padding:5}}>*PIC Phone</td>
                                        <td style={{padding:5,display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                            <Input 
                                                style={{borderColor:this.state.createTicketPICError?"salmon":"lightgrey"}}
                                                value={this.state.createTicketPIC} 
                                                onChange={(e)=>{
                                                    this.setState({createTicketPIC:e.target.value.replace(/\D/g,"")})    
                                            }} />
                                            <div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.loadAgentCompanyList()
                                                }}>
                                                    Agent
                                                </Button>
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.loadStaffList()
                                                }}>
                                                    Staff
                                                </Button>
                                                <Button color="light" style={{border:"1px solid lightgrey",whiteSpace:"nowrap"}} onClick={()=>{
                                                    this.setState({
                                                        createTicketPIC:"Lead Pool"
                                                    })
                                                }}>
                                                    Lead Pool
                                                </Button>
                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:5}}>*Channel</td>
                                        <td style={{padding:5}}>
                                            <div style={{display:"flex",flexDirection:"row",gap:10,
                                                border:this.state.createTicketChannelError?"1px solid salmon":"none"}}>
                                                {
                                                GLOBAL.channelList
                                                .filter(channel=>channel!=="Contact Form")
                                                .map((channel)=>{
                                                    return(
                                                        <div style={{padding:"5px 10px",backgroundColor:GLOBAL.getChannelColor(channel),borderRadius:5,color:"white",whiteSpace:"nowrap",
                                                            cursor:"pointer",boxShadow:this.state.createTicketChannel==channel?"0px 0px 0px 5px orange inset":"none"}} onClick={()=>{
                                                                this.setState({createTicketChannel:channel})
                                                        }}>
                                                            {channel}
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:5}}>*Interest</td>
                                        <td style={{padding:5}}>
                                            <textarea rows={3} style={{width:"100%",borderColor:this.state.createTicketInterestError?"salmon":"lightgrey"}} 
                                                value={this.state.createTicketInterest} 
                                                onChange={(e)=>{
                                                    this.setState({createTicketInterest:e.target.value})
                                            }} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.createTicket()
                                }}>
                                    Create
                                </Button>
                                <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                    this.setState({createTicketModal:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>
               
                <Modal isOpen={this.state.earningRecordModal} toggle={()=>this.setState({earningRecordModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Earning Record</div>
                        <div style={{display:"flex",flexDirection:"column",marginBottom:10,gap:5}}>
                            {
                            this.state.earningRecordList.map((earning)=>{
                                return(
                                    <div style={{padding:10,border:"1px solid lightgrey",borderRadius:10}}>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                            <div>{earning.createDate}</div>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{}}>{earning.description}</div>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{marginTop:5,fontSize:12,color:"grey"}}>
                                                <table>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>Tier: </td>
                                                        <td style={styles.tableCellStyle}>{earning.tier}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>Total Sales: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.totalSales.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>课程奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.agentCommission.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>个人业绩: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.personalSales.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>个人业绩奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.personalGoalCommission.toLocaleFixed(2)}</td>
                                                    </tr>                                                  
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>其他奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.otherCommission.toLocaleFixed(2)}</td>
                                                    </tr>
                                                </table>                                                        
                                                {/*
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                    wastedCommission: RM{earning.wastedCommission.toLocaleFixed(2)}
                                                </div>
                                                */}
                                            </div>
                                            <div style={{marginTop:5,fontSize:12,color:"grey",paddingLeft:30}}>
                                                <table>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>  </td>
                                                        <td style={styles.tableCellStyle}>  </td>                                                 
                                                    </tr>                                                    
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>组织业绩: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.groupSales.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>组织业绩奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.groupGoalCommission.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>差额奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.overridingCommission.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>领导奖金: </td>
                                                        <td style={styles.tableCellStyle}>RM{earning.leadershipBonus.toLocaleFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={styles.tableCellStyle}>流量奖金: </td>
                                                        <td style={styles.tableCellStyle}>
                                                            RM{earning.referralCommission.toLocaleFixed(2)}
                                                            <BsInfoCircleFill style={{marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                                                alert("流量奖金 was deposited into P Wallet immediately right after payment.")
                                                            }} />
                                                        </td>
                                                    </tr>
                                                </table>                                                        
                                                {/*
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                    wastedCommission: RM{earning.wastedCommission.toLocaleFixed(2)}
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                        
                                        <div style={{marginTop:10,fontSize:18,fontWeight:"bold"}}>
                                            <div style={{}}>Total Commission: RM{earning.totalCommission.toLocaleFixed(2)}</div>
                                        </div>
                                    </div>                                    
                                )
                            })
                            }
                        </div>
                        <div style={{padding:10, display :`flex` , justifyContent : `center`}}>
                             <ReactPaginate
                                         pageCount={this.state.earningRecordPage}
                                         previousLabel={'<'}
                                         nextLabel={'>'}
                                         pageRangeDisplayed={3}
                                         marginPagesDisplayed={1}
                                         containerClassName={"pagination justify-content-center"}
                                         pageClassName={"page-item"}
                                         pageLinkClassName={"page-link"}
                                         breakLabel={"..."}
                                         breakClassName={"page-item"}
                                         breakLinkClassName={"page-link"}
                                         previousClassName={"page-item"}
                                         previousLinkClassName={"page-link"}
                                         nextClassName={"page-item"}
                                         nextLinkClassName={"page-link"}
                                         activeClassName={"active"}
                                         disabledClassName={"disabled"}
                                         forcePage={this.state.page}
                                         onPageChange={async ({selected}) => {
                                             this.getEarningRecords(selected);
                                         }}
                             />
                            <div style={{ paddingLeft:10}}>
                                 <Button color="danger" style={{ flex: 1, backgroundColor: "salmon", border: "none"}} onClick={() => {
                                     this.setState({ earningRecordModal: false })
                                 }}>
                                     Close
                                 </Button>
                            </div>
 
                         </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.checkExistModal} toggle={()=>this.setState({checkExistModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column"}}>
                        <div style={{textAlign:"center",fontSize:18,fontWeight:"bold",marginBottom:10}}>Check Member Exist</div>
                        <div>Phone Number</div>
                        <Input placeHolder="0123333333" value={this.state.checkExistPhone} onChange={(e)=>{
                            this.setState({checkExistPhone:e.target.value.replace(/\D/g,"")})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,justifyContent:"center",width:"100%"}}>
                            <Button color="success" style={{ flex: 1, }} onClick={() => {
                                this.checkPhoneExist()
                            }}>Check</Button>
                            <Button color="secondary" style={{marginLeft:10,flex:1}} onClick={()=>{
                                this.setState({checkExistModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.qrModal} toggle={()=>this.setState({qrModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{textAlign:"center"}}>Participant QR Code<br/>Please send to participant for entry</div>
                        {
                        this.state.qrCode&&
                            <img id="QR" src={this.state.qrCode} style={{maxWidth:"100%"}} />
                        }
                        {
                        this.state.ticketNumber&&
                            <div style={{marginTop:5,fontSize:22}}>{this.state.ticketNumber}</div>
                        }
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,justifyContent:"center",width:"100%"}}>
                            <Button color="success" style={{ flex: 1, }} onClick={() => {
                                let svg = document.getElementById("QR")
                                let a = document.createElement("a")
                                a.setAttribute("href", svg.src)
                                a.setAttribute("download", this.state.ticketNumber+"_qrcode.svg")
                                a.dispatchEvent(new MouseEvent('click', { view: window, bubbles: false, cancelable: true }))
                            }}>Download</Button>
                            <Button color="secondary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({qrModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.createTagModal} toggle={()=>this.setState({createTagModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:15,fontWeight:"bold"}}>
                            <div style={{marginLeft:10}}>Create New Tag</div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <Input value={this.state.createTagName} onChange={(e)=>{
                                this.setState({createTagName:e.target.value})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center",marginTop:15,width:"100%"}}>
                            <Button color="primary" onClick={()=>{
                                this.createNewClientTag()
                            }}>
                                Create
                            </Button>
                            <Button color="danger" style={{marginLeft:10}} onClick={()=>{this.setState({createTagModal:false})}}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.editTagModal} toggle={()=>this.setState({editTagModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:15,fontWeight:"bold"}}>
                            <div style={{marginLeft:10}}>Edit Tag</div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <Input value={this.state.createTagName} onChange={(e)=>{
                                this.setState({createTagName:e.target.value})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center",marginTop:15,width:"100%"}}>
                            <Button color="primary" onClick={()=>{
                                this.saveEditTag()
                            }}>
                                Save
                            </Button>
                            <Button color="danger" style={{marginLeft:10}} onClick={()=>{this.setState({editTagModal:false})}}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.addTagModal} toggle={()=>this.setState({addTagModal:false})} style={{width:"90%",maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input type="search" style={{width:"auto",flex:1}} value={this.state.clientTagSearch} onChange={(e)=>{
                                //search for existing related tag
                                var relatedClientTag=[]
                                for(var tag of this.state.tagList){
                                    if(tag.title.toLowerCase().includes(e.target.value.toLowerCase())){
                                        relatedClientTag.push(tag)
                                    }
                                }
                                this.setState({clientTagSearch:e.target.value,relatedClientTag:relatedClientTag})
                            }} />
                            {
                            reactLocalStorage.get("job")=="Course Advisor"?
                                null
                                :
                                <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                    this.setState({createTagModal:true})
                                }}>
                                    Create new tag
                                </Button>
                            }
                        </div>
                        <div style={{marginTop:10}}>
                            {
                            this.state.relatedClientTag.map((item)=>{
                                return(
                                    <Button color="primary" style={{margin:2}} onClick={()=>{
                                        this.addTagToClient(item)
                                    }}>
                                        <div>{item.title}</div>
                                        <div style={{fontSize:10}}>Times used: {item.timesUsed}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{marginTop:10,display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {
                            this.state.tagList.map((item)=>{
                                return(
                                    <div style={{margin:2,display:"flex",flexDirection:"row",alignItems:"center",
                                        backgroundColor:"#f7f7f7",border:"solid 1px lightgrey",padding:5,borderRadius:5}}>
                                        <div style={{display:"flex",flexDirection:"column",cursor:"pointer"}} onClick={()=>{
                                            this.addTagToClient(item)
                                        }}>
                                            <div>{item.title}</div>
                                            <div style={{fontSize:10}}>Times used: {item.timesUsed}</div>
                                        </div>
                                        <MdEdit style={{marginLeft:5,width:20,height:20,cursor:"pointer"}} onClick={()=>{
                                            this.setState({
                                                editTagModal:true,
                                                selectedEditTag:item,
                                                createTagName:item.title,
                                            })
                                        }}/>
                                        <MdDelete style={{marginLeft:5,width:20,height:20,cursor:"pointer"}} onClick={()=>{
                                            this.deleteTag(item)
                                        }}/>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.quickSearchModal} toggle={()=>this.setState({quickSearchModal:false})} style={{width:"90%",maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code"
                                style={{marginRight:10}}
                                value={this.state.quickFindField}
                                onChange={event => this.setState({quickFindField: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.quickSearchMember(this.state.quickFindField)
                                    }
                                }}
                                />
                            <Button color="primary" onClick={()=>{this.quickSearchMember(this.state.quickFindField)}}>Search</Button>
                        </div>
                        <div style={{marginBottom:20,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                            {this.state.quickSearchResult.length} results found.
                        </div>
                        <div style={{paddingLeft:10,paddingRight:10}}>
                            <Row style={{paddingBottom:20,borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,
                                borderStyle:"solid",borderColor:"lightgrey"}}>
                                <Col style={styles.columnTitleStyle} xs={1}>Phone</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Name</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Email</Col>
                                <Col style={styles.columnTitleStyle} xs={1}>Membership</Col>
                                <Col style={styles.columnTitleStyle} xs={1}>Job</Col>
                                <Col style={styles.columnTitleStyle} xs={1}>Join Date</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Company</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>PIC</Col>
                            </Row>
                            {
                            this.state.quickSearchResult.length>0?
                                this.state.quickSearchResult.map((item)=>{
                                    return(
                                        <Row style={styles.searchResultRow}>
                                            <Col xs={1}>{item.username}</Col>
                                            <Col xs={2}>{item.name}</Col>
                                            <Col xs={2}>{item.email}</Col>
                                            <Col xs={1}>{item.membership_type}({item.referRank})</Col>
                                            <Col xs={1}>{item.job}</Col>
                                            <Col xs={1}>{item.date_joined}</Col>
                                            <Col xs={2}>{item.company}</Col>
                                            <Col xs={2}>{item.personInCharge?item.personInCharge.name:"-"}</Col>
                                            <Col xs={1}>
                                                <Button color="primary" onClick={()=>{this.selectMember(item)}}>
                                                    Select
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                                :
                                <div> ------- </div>
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.relationshipModal} toggle={()=>this.setState({relationshipModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        {
                        this.state.createRelationship?
                            <div>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:15,fontWeight:"bold"}}>
                                    <GoLink />
                                    <div style={{marginLeft:10}}>Create New Relationship</div>
                                </div>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                                        <div>User 1:</div>
                                        <Input type="text" placeholder="username 1"
                                            style={{marginLeft:10,width:"auto"}}
                                            value={this.state.userOneUsername}
                                            onChange={(event) => {
                                                    this.setState({
                                                        userOneUsername: event.target.value,
                                                    })
                                                }}
                                            />
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                                        <div>User 2:</div>
                                        <Input type="text" placeholder="username 2"
                                            style={{marginLeft:10,width:"auto"}}
                                            value={this.state.userTwoUsername}
                                            onChange={(event) => {
                                                    this.setState({
                                                        userTwoUsername: event.target.value,
                                                    })
                                                }}
                                            />
                                        <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginLeft:10}} onClick={()=>{
                                            if(this.state.userTwoUsername==""){
                                                alert("Enter name/username in user 2")
                                                return
                                            }
                                            this.setState({quickFindField:this.state.userTwoUsername,quickSearchModal:true})
                                            this.quickSearchMember(this.state.userTwoUsername)
                                        }}>
                                            Search
                                        </Button>
                                    </div>
                                    {
                                    this.state.userTwoName==""?
                                        null
                                        :
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                                            {this.state.userTwoName}
                                        </div>
                                    }
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                                        <div>Relationship 1:</div>
                                        <select style={{marginLeft:10,padding:5}} onChange={(e)=>{
                                            this.setState({relationshipOne:e.target.value})
                                        }}>
                                            {
                                            this.relationshipChoices.map((item)=>{
                                                return(
                                                    <option value={item} selected={this.state.relationshipOne==item?true:false}>{item}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                                        <div>Relationship 2:</div>
                                        <select style={{marginLeft:10,padding:5}} onChange={(e)=>{
                                            this.setState({relationshipTwo:e.target.value})
                                        }}>
                                            {
                                            this.relationshipChoices.map((item)=>{
                                                return(
                                                    <option value={item} selected={this.state.relationshipTwo==item?true:false}>{item}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div style={{display:"flex",flexDirection:"column",marginTop:5,marginBottom:30}}>
                                    <div>Interpretation:</div>
                                    {
                                    this.state.userOneUsername.length>0&&this.state.userTwoUsername.length>0&&this.state.relationshipOne!="Select a relationship"?
                                        <div stlye={{display:"flex",flexDirection:"row",width:"100%"}}>
                                            <a style={{color:"green"}}>{this.state.userOneUsername}</a>
                                            <a style={{}}>{" is "}</a>
                                            <a style={{color:"red"}}>{this.state.relationshipOne}</a>
                                            <a style={{}}>{" of "}</a>
                                            <a style={{color:"blue"}}>{this.state.userTwoName}</a>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                    this.state.userOneUsername.length>0&&this.state.userTwoUsername.length>0&&this.state.relationshipTwo!="Select a relationship"?
                                        <div stlye={{display:"flex",flexDirection:"row",width:"100%"}}>
                                            <a style={{color:"blue"}}>{this.state.userTwoName}</a>
                                            <a style={{}}>{" is "}</a>
                                            <a style={{color:"red"}}>{this.state.relationshipTwo}</a>
                                            <a style={{}}>{" of "}</a>
                                            <a style={{color:"green"}}>{this.state.userOneUsername}</a>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center"}}>
                                    <Button color="danger" style={{marginRight:20}} onClick={()=>{this.setState({relationshipModal:false})}}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" onClick={()=>{
                                        this.createNewRelationship()
                                    }}>
                                        Create
                                    </Button>
                                </div>
                            </div>
                            :
                            <div>Edit Relationship</div>
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.tagModal} toggle={()=>this.setState({tagModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <Input type="text" placeholder="Tag name"
                            style={{marginBottom:10}}
                            value={this.state.tagSearch}
                            onChange={(event) => {
                                    //show only relevent
                                    var newTagsToShow=[]
                                    var tagGroup=[]
                                    //nothing typed = show all
                                    if(event.target.value.length==0){
                                        tagGroup=this.state.allTags
                                    }else{
                                        for(var y=0;y<this.state.allTags.length;y++){
                                            for(var theTag of this.state.allTags[y]){
                                                if(theTag.name.includes(event.target.value)){   //case insensitive
                                                    newTagsToShow.push(theTag)
                                                }
                                            }
                                        }
                                    }
                                    //group the tags [[tags],[tags]]
                                    for(const tag of newTagsToShow){
                                        var foundGroup=false
                                        for(var tagArray of tagGroup){
                                            if(tag.group==tagArray[0].group){
                                                tagArray.push(tag)
                                                foundGroup=true
                                                break
                                            }
                                        }
                                        if(!foundGroup){
                                            tagGroup.push([tag])
                                        }
                                    }

                                    this.setState({
                                        tagSearch: event.target.value,
                                        tagsToShow:tagGroup,
                                    })
                                }}
                            />
                        {
                            this.state.tagsToShow.map((group)=>{
                                return(
                                    <div>
                                        <div style={{textAlign:"left",fontWeight:"bold"}}>{group[0].group}</div>
                                        {
                                        group.map((item)=>{
                                            return(
                                                <Button style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 5px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,margin:5}}
                                                    onClick={()=>{this.addTag(item)}}>
                                                    {item.name}
                                                </Button>
                                            )
                                        })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.promoVoucherModal} toggle={()=>this.setState({promoVoucherModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        {
                        this.state.currentPromoVoucher?
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                    <div style={{fontWeight:"bold",fontSize:14}}>{this.state.currentPromoVoucher.title}</div>
                                    <div style={{color:"grey",fontSize:12}}>发出时间: {this.state.currentPromoVoucher.dateTimeCreated}</div>
                                    <div style={{color:"grey",fontSize:12}}>截止日期: {this.state.currentPromoVoucher.expiryDate}</div>
                                    <div style={{color:"grey",fontSize:12}}>发送人: {this.state.currentPromoVoucher.sender?this.state.currentPromoVoucher.sender:"System"}</div>
                                </div>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                                    <div>Amount:</div>
                                    <Button color="warning" style={{marginLeft:5,marginRight:5}}
                                        onClick={()=>{
                                            //do not allow less than 1
                                            if(this.state.currentPromoVoucher.amount<=1){
                                                return
                                            }

                                            var change=this.state.currentPromoVoucher
                                            change.amount-=1
                                            this.setState({currentPromoVoucher:change})
                                        }}>
                                        -
                                    </Button>
                                    <div>{this.state.currentPromoVoucher.amount}</div>
                                    <Button color="warning" style={{marginLeft:5,marginRight:5}}
                                        onClick={()=>{
                                            var change=this.state.currentPromoVoucher
                                            change.amount+=1
                                            this.setState({currentPromoVoucher:change})
                                        }}>
                                        +
                                    </Button>
                                </div>
                                <Button color="primary" style={{marginTop:10}} onClick={()=>{this.savePromoVoucher()}}>Save</Button>
                                <Button color="danger" style={{marginTop:10}} onClick={()=>{this.deletePromoVoucher()}}>Delete</Button>
                            </div>
                            :
                            null
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.filterModal} toggle={()=>this.setState({filterModal:false,filterMode:null})} style={{maxWidth:500}}>
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Filter by: {this.state.filterMode}</div>
                        {
                        this.state.filterMode=="membership"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:5}}>
                                {
                                    GLOBAL.memberShipList.map((membership)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                            boxShadow:this.state.filterMembership.includes(membership)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}}
                                            onClick={()=>{
                                                this.addSelectedMembership(membership)
                                            }}>
                                                {membership}
                                            </Button>
                                        )
                                    })
                                }
                                <span style={{alignSelf:"center",fontSize:12}}>exclude</span>
                                <input type="checkbox" style={{alignSelf:"center"}} onChange={()=>{
                                    this.setState({excludeMembership:true})
                                }}></input>
                            </div>
                            :
                        this.state.filterMode=="job"?
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5}}>
                                {
                                this.state.jobList.map((job)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                                boxShadow:this.state.filterJob.includes(job.jobName)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey",padding:"2px"}}
                                                onClick={()=>{
                                                    this.addSelectedJob(job.jobName)
                                            }}>
                                                {job.jobName}
                                            </Button>
                                        )
                                    })
                                }
                                <span style={{alignSelf:"center",fontSize:12}}>exclude</span>
                                <input type="checkbox" style={{alignSelf:"center"}} onChange={()=>{
                                    this.setState({excludeJob:true})
                                }}></input>
                            </div>
                            :
                        this.state.filterMode=="company"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterCompany} 
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault() 
                                            this.filterSalesLog(this.state.filterMode)
                                            }
                                        }}
                                    onChange={(e)=>{
                                        this.setState({filterCompany:e.target.value})
                                    }}/>
                            </div> 
                            :
                        this.state.filterMode=="PIC"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterPIC} 
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault() 
                                            this.filterSalesLog(this.state.filterMode)
                                            }
                                        }}
                                    onChange={(e)=>{
                                        this.setState({filterPIC:e.target.value})
                                    }}/>
                            </div>
                            :
                        this.state.filterMode=="tag"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Select isMulti options={this.state.clientTagTitle.map((tag)=>({value:tag.title,label:tag.title}))} 
                                    onChange={(e)=>this.addSelectedTag(e)} />
                            </div>
                            :
                        this.state.filterMode=="joinDate"?
                            <div>
                                <div>Join Date From</div>
                                <Input type="date" value={this.state.filterJoinDateFrom}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterSalesLog(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterJoinDateFrom:e.target.value})
                                    }}/>
                                <div>Join Date To</div>    
                                <Input type="date" value={this.state.filterJoinDateTo}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterSalesLog(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterJoinDateTo:e.target.value})
                                    }}/>
                            </div>
                            :
                        this.state.filterMode=="badge"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Select isMulti options={this.state.badgeTitle.map((badge)=>({value:badge.title,label:badge.title}))} 
                                    onChange={(e)=>this.addSelectedBadge(e)}/>
                                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                    <span style={{alignSelf:"center",fontSize:12}}>exclude</span>
                                    <input type="checkbox" style={{alignSelf:"center"}} onChange={()=>{
                                        this.setState({excludeBadge:true})
                                    }}></input>
                                </div>
                            </div>
                            :
                        this.state.filterMode=="credit"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterCredit} 
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault() 
                                            this.filterSalesLog(this.state.filterMode)
                                            }
                                        }}
                                    onChange={(e)=>{
                                        this.setState({filterCredit:e.target.value})
                                    }}
                                    placeholder='<300 or >300'/>
                            </div>
                            :
                        this.state.filterMode=="installmentWallet"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterInstallment} 
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault() 
                                            this.filterSalesLog(this.state.filterMode)
                                            }
                                        }}
                                    onChange={(e)=>{
                                        this.setState({filterInstallment:e.target.value})
                                    }}
                                    placeholder='<300 or >300'/>
                            </div>
                            :
                        this.state.filterMode=="voucher"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                {
                                    this.filterOccurrenceVoucher(this.state.voucherTitle).map((voucher)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                                boxShadow:this.state.filterVoucher.includes(voucher)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}} 
                                                onClick={()=>{
                                                    this.addSelectedVoucher(voucher)
                                            }}>
                                                {voucher}
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                            :
                        this.state.filterMode=="archivedSession"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Select isMulti options={this.state.courseHistoryTitle.map((history)=>({value:history.courseTitle,label:history.courseTitle}))} 
                                    onChange={(e)=>this.addSelectedArchived(e)}/>
                            </div>
                            :
                        this.state.filterMode=="ticket"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                {
                                    this.filterOccurrenceTicket(this.state.ticketCourse).map((ticket)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                                boxShadow:this.state.filterTicket.includes(ticket)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}} 
                                                onClick={()=>{
                                                    this.addSelectedTicket(ticket)
                                            }}>
                                                {ticket}
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                            :
                        this.state.filterMode=="courseCredit"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                {
                                    this.filterOccurrenceCourseCredit(this.state.courseCredit).map((course)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                                boxShadow:this.state.filterCourseCredit.includes(course)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}}
                                                onClick={()=>{
                                                    this.addSelectedCourse(course)
                                            }}>
                                                {course}
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                            :
                        this.state.filterMode=="certificate"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Select isMulti options={this.state.certificateTitle.map((certificate)=>({value:certificate.title,label:certificate.title}))} 
                                    onChange={(e)=>this.addSelectedCertificate(e)}/>
                                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                    <span style={{alignSelf:"center",fontSize:12}}>exclude</span>
                                    <input type="checkbox" style={{alignSelf:"center"}} onChange={()=>{
                                        this.setState({excludeCertificate:true})
                                    }}></input>
                                </div>
                            </div>
                            :
                        this.state.filterMode=="bookedSession"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                {
                                    this.filterOccurrenceBooked(this.state.bookedSession).map((booked)=>{
                                        return(
                                            <Button color="light" style={{color:"black",
                                                boxShadow:this.state.filterBookedSession.includes(booked)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}} 
                                                onClick={()=>{
                                                    this.addSelectedSession(booked)
                                            }}>
                                                {booked}
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                            :
                        this.state.filterMode=="referCode"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterReferCode}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterSalesLog(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterReferCode:e.target.value})
                                    }}/>
                            </div>
                            :
                        this.state.filterMode=="referRank"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:5}}>
                                {
                                    GLOBAL.referRankList.map((referRank)=>{
                                        return(
                                            <Button color="light" style={{backgroundColor:GLOBAL.getReferRankColor(referRank),color:"black",
                                                boxShadow:this.state.filterReferRank.includes(referRank)?"0px 0px 0px 5px orange inset":"none",border:"1px solid grey"}} 
                                                onClick={()=>{
                                                    this.addSelectedReferRank(referRank)
                                            }}>
                                                {referRank}
                                            </Button>
                                        )
                                    })
                                }
                                <span style={{alignSelf:"center"}}>exclude</span>
                                <input type="checkbox" style={{alignSelf:"center"}} onChange={()=>{
                                    this.setState({excludeReferRank:true})
                                }}></input>
                            </div>
                            :
                        this.state.filterMode=="referrer"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input value={this.state.filterReferrer}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterSalesLog(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterReferrer:e.target.value})
                                    }}/>

                                <div style={{display:"flex"}}>
                                    <span style={{fontSize:12}}>exclude</span>
                                    <input type="checkbox" style={{margin:"revert"}} onChange={()=>{
                                        this.setState({excludeReferrer:true})
                                    }}/>
                                </div>

                            </div>
                            :
                        this.state.filterMode=="state"?
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5,justifyContent:"center"}}>
                                {
                                this.state.clientStateList.map((state)=>{
                                    return(
                                        <Button color="primary" style={{boxShadow:this.state.filterState.includes(state)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={()=>{
                                                this.addSelectedState(state)
                                        }}>
                                            {state.name}
                                        </Button>
                                    )
                                })
                                }
                                <Button color="primary" style={{boxShadow:this.state.filterState.includes("No State")?"0px 0px 0px 5px orange inset":"none"}}
                                    onClick={()=>{
                                        this.addSelectedState("No State")
                                }}>
                                    No State
                                </Button>
                            </div>
                            :                               
                            null
                        }
                        {
                        this.state.filterMode!="Member"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                                    <Button color="primary" style={{flex:1}} onClick={()=>{
                                        this.filterSalesLog(this.state.filterMode)
                                    }}>
                                        Filter
                                    </Button>
                                    <Button color="light" style={{flex:1,backgroundColor:"salmon",color:"white"}} onClick={()=>{
                                        this.setState({filterModal:false})
                                    }}>
                                        Close
                                    </Button>
                            </div>
                            :
                            null
                        }
                    </div>
                </Modal>
                
                <Modal isOpen={this.state.editSalesLogMode} toggle={()=>this.setState({editSalesLogMode:false})} style={{maxWidth:500}}>
                        <div style={{padding:25,borderRadius:10}}>
                            <div style={{width:"100%",padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                <Editor
                                    editorState={this.state.editModeEditorState}
                                    onEditorStateChange={this.editModeOnEditorStateChange}
                                    handlePastedFiles={this.handleEditModePastedFiles}
                                    blockRendererFn={(block) => {
                                        if (block.getType() === 'atomic') {
                                            return {
                                                component: this.resizableImage,
                                                editable: false,
                                                props: {
                                                    alignment: 'left',
                                                }
                                            };
                                        }
                                    }}
                                    toolbar={{
                                        image:{
                                            uploadEnabled:true,
                                            uploadCallback:this.imageUploadCallback,
                                            previewImage: true,
                                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            alignmentEnabled: false,
                                        }
                                    }}
                                />
                            </div>
                            <div style={{paddingTop:10}}>
                                <Button onClick={()=>this.editSalesLog()} color="primary" style={{marginRight:10}}>Post</Button>
                                <Button onClick={()=>{this.setState({editSalesLogMode:false})}} color="danger">Cancel</Button>
                            </div>
                        </div>
                </Modal>

                <NavigationBar currentPage="saleslog" />
                {
                window.innerWidth <= 600 ? //for mobile version
                    <div style={{padding:20}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code / PIC (filter function click ? on right)"
                                style={{marginRight:10,padding:"5px 10px"}}
                                value={this.state.findField}
                                onChange={event => this.setState({findField: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchMember()
                                    }
                                }}/>
                            <Button color="danger" style={{whiteSpace:"nowrap",marginRight:5,fontSize:14}} onClick={()=>{this.setState({findField:""})}}>Clear Query</Button>
                            <Button color="primary" style={{padding:"5px 10px",fontSize:14}} onClick={()=>{this.searchMember()}}>Search</Button>
                        </div>
                        <div style={{paddingTop:5,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row",fontSize:10}}>
                            {this.state.totalMemberCount} results found.
                            <div style={{marginLeft:10,color:"blue",cursor:"pointer"}}
                                onClick={()=>{
                                    this.setState({loading:true})
                                    //remove the member username in url
                                    let here = new URL(window.location.href)
                                    if(here.searchParams.get('username')!=null){
                                        here.searchParams.delete('username')
                                        window.history.pushState({},"",here)
                                    }

                                    this.setState({viewMode:"search",currentMember:{},loading:false})
                                }}>
                                Search result page
                            </div>
                            <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                    this.copyList()
                                }}>
                                <FaFileExcel style={{width:20,height:20,padding:4,color:"green"}}/>
                                <div style={{fontSize:12}}>Download as Excel</div>
                            </Button>
                            <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,fontSize:12}} onClick={()=>{GLOBAL.copyDiv(this.divRef)}}>
                                <FaCopy style={{width:20,height:20,padding:4}}/>
                                Copy List
                            </Button>
                            <Button color="light" style={{marginLeft:10,padding:"0px 5px",fontSize:12,border:"1px solid skyblue"}} onClick={()=>{
                                this.setState({checkExistModal:true,checkExistPhone:""})
                            }}>
                                Check exist
                            </Button>
                        </div>
                        {
                        this.state.viewMode=="detail" && this.state.totalMemberCount > 1? (
                            <div style={{ display:"flex", flexDirection:"row", alignItems:"center", marginBottom:10, justifyContent:"center"}}>
                            <button color="light" style={{ borderStyle:"solid", borderWidth:1, borderColor: "lightgrey", padding:2, paddingRight:8, paddingLeft:8, fontSize:14, marginRight:10, marginLeft:10 }} 
                                onClick={this.handlePreviousPage} disabled={this.state.displayedPageMemberIndex<=1}>
                                <div>{"<"}</div>
                            </button>
                            <input style={{width:"8%", height:"100%", borderStyle:"solid", borderWidth:1, borderColor:"lightgrey", padding:2, fontSize:16}} 
                                type="number" value={this.state.displayedPageMemberIndex} min="1" max={this.state.totalMemberCount}
                                onChange={(e)=>{
                                    const inputValue=parseInt(e.target.value);
                                    const displayedValue=isNaN(inputValue)?'':inputValue;
                                    this.setState({
                                        pageMemberIndex:(inputValue-1)%this.state.itemPerPage,
                                        displayedPageMemberIndex:displayedValue,
                                    })
                                }}
                                onKeyDown={(e)=>{
                                    const pageNumber=parseInt((this.state.displayedPageMemberIndex-1)/this.state.itemPerPage);
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchMember(pageNumber, true)
                                    }
                                }}
                            />
                            <div style={{marginLeft:5, fontSize:16}}>/{this.state.totalMemberCount}</div>
                            <button color="light" style={{borderStyle:"solid", borderWidth:1, borderColor:"lightgrey", padding:2, paddingLeft:8, paddingRight:10, fontSize:14, marginLeft:10}} 
                                onClick={this.handleNextPage} disabled={this.state.displayedPageMemberIndex>=this.state.totalMemberCount}>
                                <div>{">"}</div>
                            </button>
                            </div>
                        ) : null
                        }
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,width:"100%",
                            flexWrap:"wrap",marginBottom:"5px",padding:"10px 0px 5px 10px"}}>
                            <div style={{fontSize:11}}>Filter by:</div>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"membership",filterMembership:[]})
                            }}>
                                Membership
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.getJobList()
                            }}>
                                Job
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"company",filterCompany:""})
                            }}>
                                Company
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"PIC",filterPIC:""})
                            }}>
                                PIC
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getClientTagTitle()}}>
                                Tag
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"joinDate",filterJoinDateFrom:"",filterJoinDateTo:""})
                            }}>
                                JoinDate
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getBadgeTitle()}}>
                                Badge
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"credit",filterCredit:""})
                            }}>
                                Credit
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"installmentWallet",filterInstallment:""})
                            }}>
                                InstallmentWallet
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getVoucherTitle()}}>
                                Voucher
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getCourseHistoryTitle()}}>
                                ArchivedSess
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getTicketCourse()}}>
                                Ticket
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getCourseCredit()}}>
                                CourseCredit
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getCertificateTitle()}}>
                                Certificate
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getBookedSession()}}>
                                BookedSession
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referCode",filterReferCode:""})
                            }}>
                                ReferCode
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referRank",filterReferRank:[]})
                            }}>
                                ReferRank
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referrer",filterReferrer:""})
                            }}>
                                Referrer
                            </Button>
                            <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{this.getClientStateList()}}>
                                State
                            </Button>
                        </div>
                        {
                        this.state.viewMode=="search"?
                            <div style={{width:"100%"}}>
                                {
                                this.state.memberList.length>0?
                                    this.state.memberList.map((item,index)=>{
                                        return(
                                            <div style={{display:"flex",flexDirection:"column",borderRadius:"15px",padding:5,marginBottom:10,border:"1px solid",borderColor:"lightgrey"}}
                                            onClick={()=>{
                                                this.setState({
                                                    pageMemberIndex:index,
                                                    displayedPageMemberIndex:(index+1)+(this.state.itemPerPage*this.state.page),
                                                })
                                                this.viewMember(item).then(()=>{this.loadSalesLogHistory(this.state.currentMember.username)
                                                })
                                            }}>
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                    <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>
                                                        <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:14}}>
                                                            {item.username} {item.name}
                                                        </div>
                                                        <div style={{fontSize:10,color:"grey"}}>{item.description?item.description:item.referCode}</div>
                                                        <div style={{fontWeight:"bold",fontSize:9,alignSelf:"flex-start"}}>
                                                            {item.email}
                                                        </div>
                                                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:5,marginBottom:5,width:"100%",color:"lightgrey"}}/>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <div style={{fontSize:12}}>
                                                                    Membership: {item.membership_type}({item.referRank})
                                                                </div>
                                                                <div style={{fontSize:12}}>
                                                                    Company: {item.company?item.company:"-"}
                                                                </div>
                                                                <div style={{fontSize:12}}>
                                                                    Referrer: {
                                                                        item.referrerName?
                                                                            item.referrerUsername==item.referrerName?
                                                                                item.referrerName
                                                                                :
                                                                                item.referrerUsername+" "+item.referrerName
                                                                            :
                                                                            "-"
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <div style={{fontSize:12}}>
                                                                    Join Date: {item.date_joined}
                                                                </div>
                                                                <div style={{fontSize:12}}>
                                                                    Job: {item.job}
                                                                </div>
                                                                <div style={{fontSize:12}}>
                                                                    PIC: {
                                                                        item.personInCharge?
                                                                            item.personInCharge.username==item.personInCharge.name?
                                                                                item.personInCharge.name
                                                                                :
                                                                                item.personInCharge.username+" "+item.personInCharge.name
                                                                            :
                                                                            "-"
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex",alignSelf:"baseline",paddingTop:15}}>
                                                        <AiOutlineRight color='grey' size={19} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div> ------- </div>
                                }
                                <div style={{marginTop:10}}/>
                                <ReactPaginate
                                    pageCount={this.state.memberPages}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.searchMember(selected);
                                    }}
                                />
                            </div>
                            :
                            this.state.currentMember.name?
                            <Row style={{display:"flex",flexDirection:"row",justifyContent:"center",paddingLeft:10,paddingRight:10}}>
                                <Col xs={12} sm={6} md={4}>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",borderStyle:"solid",borderWidth:1,padding:10,borderRadius:10}}>
                                    <div style={{maxWidth:600}}>
                                        <div colSpan={2} style={{textAlign:"center"}}>
                                            <img style={{width:120,height:120,borderRadius:"50%",backgroundColor:"grey"}} src={this.state.currentMember.profileImage} />
                                        </div>
                                        <div style={{textAlign:"center",marginTop:5,marginBottom:5}}>
                                            {
                                            reactLocalStorage.get("job")=="Course Advisor"||reactLocalStorage.get("job")=="Agent"||reactLocalStorage.get("job")=="Agent Leader"?
                                                null
                                                :
                                            this.state.editProfileMode?
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                                                    <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{this.setState({editProfileMode:false})}}>
                                                        Cancel
                                                    </Button>
                                                    <Button color="danger" style={{paddingTop:3,paddingBottom:3,fontSize:10,marginLeft:10}} onClick={()=>{
                                                        this.saveProfile()
                                                    }}>
                                                        Save
                                                    </Button>
                                                </div>
                                                :
                                                <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{
                                                    this.setState({
                                                        editProfileMode:true,
                                                        toChangeCurrentMember:JSON.parse(JSON.stringify(this.state.currentMember)), //shallow quick clone of object
                                                        newReferrerUsername:"",
                                                    })
                                                }}>
                                                    Edit Profile
                                                </Button>
                                            }
                                        </div>

                                        {
                                        this.state.editProfileMode?
                                            <table>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Username: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.username} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            e.target.value=e.target.value.replace(/\D/g,"")
                                                            newDetail.username=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Name: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.name} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.name=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Description if Any: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.description} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.description=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Email: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.email} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.email=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Date of Birth: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input type="date" value={this.state.toChangeCurrentMember.dateOfBirth} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.dateOfBirth=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Membership: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <select onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.membership_type=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}>
                                                            <option value="Free" selected={this.state.toChangeCurrentMember.membership_type=="Free"?true:false}>Free</option>
                                                            <option value="CM" selected={this.state.toChangeCurrentMember.membership_type=="CM"?true:false}>CM</option>
                                                            <option value="CP" selected={this.state.toChangeCurrentMember.membership_type=="CP"?true:false}>CP</option>
                                                            <option value="GP" selected={this.state.toChangeCurrentMember.membership_type=="GP"?true:false}>GP</option>
                                                            <option value="SP" selected={this.state.toChangeCurrentMember.membership_type=="SP"?true:false}>SP</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Job: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        {this.state.toChangeCurrentMember.job}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Company: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.company} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.company=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Corporate Account: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"wrap"}}>
                                                        {
                                                        this.state.currentMember.corporateAccountList.length>0?
                                                            this.state.currentMember.corporateAccountList.map((corpAcc)=>{
                                                                return(
                                                                    <div style={{padding:"5px 10px",backgroundColor:"azure",border:"1px solid grey",borderRadius:5}}>
                                                                        {corpAcc.companyName+" ["+corpAcc.job+"]"}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            "-"
                                                        }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Position Title: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.jobTitle} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.jobTitle=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Refer rank: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        {this.state.toChangeCurrentMember.referRank}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>R Wallet: </td>
                                                    <td style={styles.memberDetailCell}>RM{this.state.currentMember.topupWallet}</td>
                                                </tr>
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>P Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.pWallet}</div>
                                                            <Button 
                                                                color="primary" 
                                                                style={{ fontSize: 12, padding: "2px 5px", lineHeight: 1, marginRight: 5 }} 
                                                                onClick={() => {
                                                                    this.setState({ walletLogModal: true });
                                                                }}
                                                            >
                                                                Wallet Log
                                                            </Button>

                                                            {/*
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                this.getEarningRecords()
                                                            }}>
                                                                Earning Record
                                                            </Button>
                                                            */}
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>C Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.wWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>Installment Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.currentMember.partialWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>充值 Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.currentMember.cashWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {/*
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Point: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.point}</td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Certificate: </td>
                                                    <td style={styles.memberDetailCell}>{this.getCertificateString(this.state.currentMember.certificate)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Badge: </td>
                                                    <td style={styles.memberDetailCell}>{this.getBadgeString(this.state.currentMember.badge)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Refer code: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.referCode}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Referrer: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <div>Current:{this.state.toChangeCurrentMember.referrerName?this.state.toChangeCurrentMember.referrerName:"-"}</div>
                                                        <div>New:<Input value={this.state.newReferrerUsername} placeholder="phone number of new referrer"
                                                            onChange={(e)=>{this.setState({newReferrerUsername:e.target.value})}} /></div>
                                                        <Button color="secondary" style={{marginTop:2,padding:2,fontSize:10}} onClick={()=>{
                                                            this.setState({newReferrerUsername:"No Referrer"})
                                                        }}>No Referrer</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Person in Charge: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.personInCharge?this.state.currentMember.personInCharge.name:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Date joined: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.date_joined?this.state.currentMember.date_joined:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Address: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <textarea value={this.state.toChangeCurrentMember.billingAddress}
                                                            onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.billingAddress=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Lead from company: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.leadFromCompany?"Yes":"No"}</td>
                                                </tr>
                                            </table>
                                            :
                                            <table>
                                                <tr>
                                                    <td>Username: </td>
                                                    <td>{this.state.currentMember.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name: </td>
                                                    <td>{this.state.currentMember.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Description:</td>
                                                    <td>{this.state.currentMember.description?this.state.currentMember.description:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email: </td>
                                                    <td>{this.state.currentMember.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Birth: </td>
                                                    <td>{this.state.currentMember.dateOfBirth?this.state.currentMember.dateOfBirth:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Membership: </td>
                                                    <td>{this.state.currentMember.membership_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Job: </td>
                                                    <td>{this.state.currentMember.job}</td>
                                                </tr>
                                                <tr>
                                                    <td>Company: </td>
                                                    <td>{this.state.currentMember.company?this.state.currentMember.company:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Corporate Account: </td>
                                                    <td>
                                                        <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"wrap"}}>
                                                        {
                                                        this.state.currentMember.corporateAccountList.length>0?
                                                            this.state.currentMember.corporateAccountList.map((corpAcc)=>{
                                                                return(
                                                                    <div style={{padding:"5px 10px",backgroundColor:"azure",border:"1px solid grey",borderRadius:5}}>
                                                                        {corpAcc.companyName+" ["+corpAcc.job+"]"}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            "-"
                                                        }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Position Title: </td>
                                                    <td>{this.state.currentMember.jobTitle?this.state.currentMember.jobTitle:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Refer rank: </td>
                                                    <td>{this.state.currentMember.referRank?this.state.currentMember.referRank:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>R Wallet: </td>
                                                    <td>RM{this.state.currentMember.topupWallet}</td>
                                                </tr>
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>P Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.pWallet}</div>
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                this.setState({walletLogModal: true})//edit here
                                                            }}>
                                                                Wallet Log
                                                            </Button>
                                                            {/*
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                this.getEarningRecords()
                                                            }}>
                                                                Earning Record
                                                            </Button>
                                                            */}
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>C Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.wWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>Installment Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.currentMember.partialWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>充值 Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.currentMember.cashWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {/*
                                                <tr>
                                                    <td>Point: </td>
                                                    <td>{this.state.currentMember.point}</td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td style={{padding:5,verticalAlign:"top"}}>Certificate: </td>
                                                    <td style={{padding:5,}}>{this.getCertificateString(this.state.currentMember.certificate)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:5,verticalAlign:"top"}}>Badge: </td>
                                                    <td style={{padding:5,}}>{this.getBadgeString(this.state.currentMember.badge)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Refer code: </td>
                                                    <td>{this.state.currentMember.referCode}</td>
                                                </tr>
                                                <tr>
                                                    <td>Referrer: </td>
                                                    <td>{this.state.currentMember.referrerName?this.state.currentMember.referrerUsername+" "+this.state.currentMember.referrerName:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Person in Charge: </td>
                                                    <td>
                                                        {
                                                        this.state.currentMember.personInCharge?
                                                            this.state.currentMember.personInCharge.username==this.state.currentMember.personInCharge.name?
                                                                this.state.currentMember.personInCharge.name
                                                                :
                                                                this.state.currentMember.personInCharge.username+" "+this.state.currentMember.personInCharge.name
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date joined: </td>
                                                    <td>{this.state.currentMember.date_joined?this.state.currentMember.date_joined:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Address: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.billingAddress?this.state.currentMember.billingAddress:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Lead from company: </td>
                                                    <td>{this.state.currentMember.leadFromCompany?"Yes":"No"}</td>
                                                </tr>
                                            </table>
                                        }
                                    </div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={6} md={8} style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
                                    <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,marginTop:10}}>
                                            <div>Background Info</div>
                                            {
                                            GLOBAL.checkPermission("4~C$$mtS")?
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                    this.setState({editBackgroundModal:true,editMemberBackground:this.state.currentMember.background})
                                                }}>
                                                    Edit
                                                </Button>
                                                :
                                                null
                                            }
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:50,border:"1px solid lightgrey",borderRadius:5,padding:10}}>
                                            {
                                            this.state.currentMember.background!=null?
                                                this.state.currentMember.background.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                :
                                                <div style={{color:"lightgrey",width:"100%"}}>No Background Info</div>
                                            }
                                        </div>
                                    </div>

                                    {
                                    GLOBAL.checkPermission("=oj$ppRa")?
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,marginTop:10}}>
                                                <div>Tickets</div>
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                    this.setState({createTicketModal:true})
                                                    if(this.state.currentMember.personInCharge!=null){
                                                        this.setState({createTicketPIC:this.state.currentMember.personInCharge.username})
                                                    }
                                                }}>
                                                    Create Ticket
                                                </Button>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
                                                {
                                                this.state.memberTicketList.length==0?
                                                    <div style={{padding:5,color:"lightgrey",width:"100%",border:"1px solid lightgrey"}}>
                                                        Empty
                                                    </div>
                                                    :
                                                    <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                                        {
                                                        this.state.memberTicketList.map((ticket)=>{
                                                            return(
                                                                <Button color="light" style={{padding:0,border:"1px solid lightgrey",display:"flex",flexDirection:"column",alignItems:"flex-start",fontSize:13}} 
                                                                    onClick={()=>{
                                                                        history.push("/ticket?id="+ticket.id)
                                                                }}>
                                                                    <div style={{fontWeight:"bold",width:"100%",fontSize:15,backgroundColor:"#6A6A68",
                                                                        padding:"5px 20px",color:"#B8DBDA"}}>
                                                                        Ticket {ticket.id}
                                                                    </div>
                                                                    <div style={{padding:"5px 20px 10px 20px",textAlign:"left"}}>
                                                                        <div>Referrer: {ticket.referrerString}</div>
                                                                        <div>PIC: {ticket.picString}</div>
                                                                        <div>Channel: {ticket.channel}</div>
                                                                        <div>Interest: {ticket.interest}</div>
                                                                        <div>Expiry: {ticket.expiryDate}</div>
                                                                        <div>Last Update: {ticket.lastUpdate}</div>
                                                                        <div>Create Date: {ticket.createDate}</div>
                                                                    </div>
                                                                </Button>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10}}>
                                                <div>Tags</div>
                                                <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",padding:2,paddingLeft:8,paddingRight:10,fontSize:12,marginLeft:10}} onClick={()=>{
                                                    this.loadAllTagList()
                                                }}>
                                                    <div>Add</div>
                                                </Button>
                                            </div>
                                            <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap",padding:5,marginTop:5}}>
                                                {
                                                this.state.clientTagList.length>0?
                                                    this.state.clientTagList.map((item)=>{
                                                        return(
                                                            <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey",marginRight:5}}>
                                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                    <div>{item.title}</div>
                                                                    <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                        this.deleteClientTag(item)
                                                                    }}>x</Button>
                                                                </div>
                                                                <div style={{fontSize:10}}>
                                                                    Times used: {item.timesUsed}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :<div style={{color:"lightgrey"}}>Empty</div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{marginTop:10,display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <div>Relationship</div>
                                        <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",padding:2,paddingLeft:8,paddingRight:10,fontSize:12,marginLeft:10}} onClick={()=>{
                                            this.setState({
                                                relationshipModal:true,
                                                createRelationship:true,
                                                userOneUsername:this.state.currentMember.username,
                                            })
                                        }}>
                                            <div>Add</div>
                                        </Button>
                                    </div>
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap",padding:5,marginTop:5}}>
                                        {
                                        this.state.relationshipList.length>0?
                                            this.state.relationshipList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey",marginRight:5}}>
                                                        {
                                                        this.state.currentMember?
                                                            this.state.currentMember.username==item.userOne.username?
                                                                <div style={{display:"flex",flexDirection:"column",fontSize:12}}>
                                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                                        <div style={{display:"flex",flexDirection:"row",fontSize:16}}>
                                                                            <div style={{fontWeight:"bold"}}>{item.relationshipOne}</div>
                                                                            <div style={{marginLeft:7}}>of</div>
                                                                        </div>
                                                                        <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                            this.deleteRelationship(item)
                                                                        }}>x</Button>
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                                        <a style={{color:"skyblue",textDecoration:"underline",cursor:"pointer"}} href={"?username="+item.userTwo.username} target="_blank">
                                                                            {item.userTwo.username}
                                                                        </a>
                                                                        <div style={{marginLeft:7}}>{item.userTwo.name}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div style={{display:"flex",flexDirection:"column",fontSize:12}}>
                                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                                        <div style={{display:"flex",flexDirection:"row",fontSize:16}}>
                                                                            <div style={{fontWeight:"bold"}}>{item.relationshipTwo}</div>
                                                                            <div style={{marginLeft:7}}>of</div>
                                                                        </div>
                                                                        <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                            this.deleteRelationship(item)
                                                                        }}>x</Button>
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                                        <a style={{color:"skyblue",textDecoration:"underline",cursor:"pointer"}} href={"?username="+item.userOne.username} target="_blank">
                                                                            {item.userOne.username}
                                                                        </a>
                                                                        <div style={{marginLeft:7}}>{item.userOne.name}</div>
                                                                    </div>
                                                                </div>
                                                            :null
                                                        }
                                                    </div>
                                                )
                                            })
                                            :<div style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    <div style={{marginTop:10}}>Course Credit</div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                        {
                                        this.state.courseCreditList.length>0?
                                            this.state.courseCreditList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey"}}>
                                                        <div style={{fontSize:14}}>{item.courseString}</div>
                                                        <div style={{color:"grey",fontSize:12}}>Signup Date: {item.signupDate}</div>
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.ticketList.length>0?
                                            this.state.ticketList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,width:160,border:"1px solid #fff700",backgroundColor:"#fffdcf"}}>
                                                        <div>{item.courseName}</div>
                                                        <div style={{color:"grey",fontSize:12,marginRight:5}}>购买日期:{item.createDate}</div>
                                                        {
                                                        item.targetEntreCourseDate?
                                                            <div style={{color:"grey",fontSize:12}}>课程日期:{item.targetEntreCourseDate}</div>
                                                            :null
                                                        }
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.offlineEventTicketList.length>0?
                                            this.state.offlineEventTicketList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,width:160,border:"1px solid #fff700",backgroundColor:"#fffdcf"}}>
                                                        <div>{item.offlineEventTypeName}</div>
                                                        <div style={{fontSize:12}}>赠送目标:{item.receiver!=null?item.receiver.username+" "+item.receiver.name:"-"}</div>
                                                        <div style={{color:"grey",fontSize:12}}>得到日期:{item.createDate}</div>
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.conferenceBookingList.map((item)=>{
                                            return(
                                                <div style={{padding:5,borderRadius:5,width:200,border:"1px solid #87edd6",backgroundColor:"#f2fffc",display:"flex",flexDirection:"row"}}>
                                                    <div style={{displlay:"flex",flexDirection:"column",flex:1}}>
                                                        <div>{item.conferenceName}</div>
                                                        <div style={{color:"grey",fontSize:12,marginRight:5}}>购买日期:{item.bookDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>活动日期:{item.conferenceStartTime}</div>
                                                    </div>
                                                    <Button color="light" style={{borderColor:"lighgrey"}} onClick={()=>{
                                                        this.setState({
                                                            qrModal:true,
                                                            qrCode:item.qrImage,
                                                            ticketNumber:item.ticketNumber,
                                                        })
                                                    }}>
                                                        <AiOutlineQrcode />
                                                    </Button>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.courseCreditList.length==0&&this.state.ticketList.length==0&&this.state.conferenceBookingList.length==0&&this.state.offlineEventTicketList.length==0?
                                            <div style={{color:"lightgrey"}}>Empty</div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:10}}>Booked Coming Course Session</div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.bookedCourseSession.length==0&&this.state.bookedOfflineEvent.length==0?
                                            <div style={{color:"lightgrey"}}>Empty</div>
                                            :
                                            null
                                        }
                                        {
                                        this.state.bookedCourseSession.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.courseSessionTitle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.courseSessionDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.courseSessionVenue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{color:"white",backgroundColor:"#21618c",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.bookedOfflineEvent.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.offlineEvent.title}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.offlineEvent.calendarDate}<br/>{item.offlineEvent.time} ~ {item.offlineEvent.timeUntil}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.offlineEvent.venue}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div style={{marginTop:10}}>Previous Attendance</div>
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.activityLog.length==0&&this.state.offlineEventHistory.length===0&this.state.archivedConferenceBooking.length===0?
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                            :null
                                        }
                                        {
                                        this.state.activityLog.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.courseSessionTitle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.courseSessionDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.courseSessionVenue}</div>                                                       
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{color:"white",backgroundColor:"#21618c",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                        <div style={{fontSize:12,color: item.attendance ? "green" : "red"}}>{item.attendance?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {                                      
                                        this.state.archivedConferenceBooking.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.archivedConferenceTittle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.archivedConferenceDateTime}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.archivedConferenceVenue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                    <div style={{color:"white",backgroundColor:"#FFA500",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                        <div style={{fontSize:12,color: item.attend ? "green" : "red"}}>{item.attend?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }                                       
                                        {
                                        this.state.offlineEventHistory.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.archivedOfflineEvent.title}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.archivedOfflineEvent.calendarDate}<br/>{item.archivedOfflineEvent.time} ~ {item.archivedOfflineEvent.timeUntil}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.archivedOfflineEvent.venue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{fontSize:12,color: item.attend ? "green" : "red"}}>{item.attend?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div style={{marginTop:10}}>Expiry Dates</div>
                                    <div style={{maxHeight:250,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.expiryRecord.length>0?
                                            this.state.expiryRecord.map((item)=>{
                                                return(
                                                    <div style={{fontSize:10,padding:3,display:"flex",flexDirection:"column",borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,margin:2}}>
                                                        <div style={{flex:0.2}}>{item.badge.title}</div>
                                                        <div style={{flex:0.6}}>Expiry: {item.expiryDate}</div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>

                                    <div style={{marginTop:10}}>Transaction Record</div>
                                    <div style={{maxHeight:250,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.transactionHistoryList.length>0?
                                            this.state.transactionHistoryList.map((item)=>{
                                                return(
                                                    <div style={{width:"100%",fontSize:10,padding:3,display:"flex",flexDirection:"row",borderStyle:"solid",borderColor:"lightgrey",borderTopWidth:1,borderBottomWidth:0,borderLeftWidth:0,borderRightWidth:0}}>
                                                        <div style={{flex:0.2}}>{item.dateTime}</div>
                                                        <div style={{flex:0.6}}>{item.agenda}</div>
                                                        <div style={
                                                            item.value>=0?
                                                                item.agenda.startsWith("购买")||item.agenda.startsWith("Purchase course")?
                                                                    styles.purchaseText
                                                                    :
                                                                    styles.profitText
                                                                :
                                                                styles.lossText
                                                            }>
                                                            {item.value<0?"-":""}RM{Math.abs(item.value)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    {
                                    this.state.transactionHistoryPages > 0 ?
                                        <div style={{marginTop:10}}>
                                            <ReactPaginate
                                                pageCount={this.state.transactionHistoryPages}
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={1}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledClassName={"disabled"}
                                                forcePage={this.state.pageTransactionHistory}
                                                onPageChange={async ({selected}) => {
                                                    this.loadTransactionHistoryOnPageChange(this.state.currentMember.username, selected);
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{marginTop:10}}>Voucher</div>
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.promoList.length>0?
                                            this.state.promoList.map((item)=>{
                                                return(
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                        <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                            <div style={{fontWeight:"bold",fontSize:14}}>{item.title}</div>
                                                            <div style={{color:"grey",fontSize:12}}>发出时间: {item.dateTimeCreated}</div>
                                                            <div style={{color:"grey",fontSize:12}}>截止日期: {item.expiryDate}</div>
                                                            <div style={{color:"grey",fontSize:12}}>发送人: {item.sender?item.sender:"System"}</div>
                                                        </div>
                                                        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                                                            <div style={{cursor:"pointer",color:"white",backgroundColor:"#32611f",padding:2,borderRadius:5,fontSize:12,display:"flex",justifyContent:"center",alignItems:"center"}}
                                                                onClick={()=>{
                                                                    //verify auth
                                                                    let theUsername=reactLocalStorage.get("username")
                                                                    if(reactLocalStorage.get("job")!="Sales Supervisor"){
                                                                        return
                                                                    }
                                                                    this.setState({
                                                                        promoVoucherModal:true,
                                                                        currentPromoVoucher:JSON.parse(JSON.stringify(item)),   //shallow clone object
                                                                    })
                                                                }}>
                                                                {item.amount}
                                                            </div>
                                                            <div style={{fontSize:12}}>{item.type}:{item.value}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    <div style={{marginTop:10}}>
                                        Referred member ({this.state.referredMember.length}):
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",overflowY:"scroll",maxHeight:280,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,padding:5}}>
                                    {
                                    this.state.referredMember.length>0?
                                        this.state.referredMember.map((item)=>{
                                            return(
                                                <div style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,borderRadius:5,padding:5}}>
                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                        <a style={{fontSize:12,marginRight:10,color:"skyblue",textDecoration:"underline",cursor:"pointer"}}
                                                            href={"?username="+item.username} target="_blank">
                                                            {item.username}
                                                        </a>
                                                        <div style={{fontSize:12,fontWeight:"bold"}}>
                                                            {item.name} (joined: {item.date_joined})
                                                        </div>
                                                    </div>
                                                    <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                                        <img src={this.checkBadge(item.badge,"战略三力 系统班")?Badge12:DarkBadge12} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"营销三力 系统班")?Badge13:DarkBadge13} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"组织三力 系统班")?Badge14:DarkBadge14} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"模式三力")?Badge15:DarkBadge15} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"总裁三力")?Badge16:DarkBadge16} style={{width:"auto",height:42,margin:2}} />
                                                    </div>
                                                    <div style={{fontSize:10,color:"grey"}}>Joined: {item.date_joined.split("+")[0].split("T")}</div>
                                                </div>
                                            )
                                        })
                                        :
                                        null
                                    }
                                    </div>
                                    <div style={{marginTop:10}}>
                                        Sales Log History
                                        <MdRefresh style={{fontSize:23}} onClick={()=>{
                                            this.loadSalesLogHistory(this.state.currentMember.username)
                                        }}/>
                                    </div>
                                    <div style={{marginTop:10}}>New Sales Log</div>
                                    <div style={{width:"100%",marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                        <Editor style={{height:100}}
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.onEditorStateChange}
                                            handlePastedFiles={this.handlePastedFiles}
                                            blockRendererFn={(block) => {
                                                if (block.getType() === 'atomic') {
                                                    return {
                                                        component: this.resizableImage,
                                                        editable: false,
                                                        props: {
                                                            alignment: 'left',
                                                        }
                                                    };
                                                }
                                            }}
                                            toolbar={{
                                                options:['inline','image','fontSize','emoji','history'],
                                                inline:{
                                                    options: ['bold', 'italic', 'underline'],
                                                },
                                                image:{
                                                    uploadEnabled:true,
                                                    uploadCallback:this.imageUploadCallback,
                                                    previewImage: true,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    alignmentEnabled: false,
                                                },
                                            }}
                                            />
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                            {this.state.selectedTag.map((item)=>{
                                                return(
                                                    <Button style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 5px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5}}
                                                        onClick={()=>{this.removeTag(item)}}>
                                                        {item.name}<TiDelete style={{marginLeft:5,fontSize:25}}/>
                                                    </Button>
                                                )
                                            })}
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",marginTop:5}}>
                                            <Button color="info" style={{marginRight:10,width:100,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{this.getAllTags()}}>
                                                <div>Tag</div>
                                            </Button>
                                            <Button color="primary" style={{width:100}} onClick={()=>{this.post()}}>Post</Button>
                                        </div>
                                    </div>


                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5, marginTop:13}}>
                                        <Input type="text" name="SalesLog" placeholder="Search example:系统商学"
                                            style={{marginRight:10}}
                                            value={this.state.findField2}
                                            onChange={event => this.setState({findField2: event.target.value})}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.searchSalesLog()
                                                }
                                            }}/>
                                        <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{this.searchSalesLog()}}>Search</Button>

                                    </div>
                                        <div style={{marginBottom:20,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                                        {this.state.theLogsCount} results found.
                                        <Button color="danger" style={{fontSize: '13px',marginLeft:5,width:60,height:20,textAlign: 'center',padding: 0}} onClick={()=>{this.loadSalesLogHistory()}}>Cancel</Button>
                                        </div>


                                    {
                                    this.state.salesLogHistory.length>0?
                                        <div>
                                        {
                                        this.state.salesLogHistory.map((item,index)=>{
                                            return(
                                                <div style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginBottom:5,padding:5,borderRadius:5}} key={item.dateTime}>
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                        <div style={{display:"flex",flexDirection:"row"}}>
                                                            <div style={{fontWeight:"bold"}}>{item.loggerName?item.loggerName:"By deleted user"}</div>
                                                            <div style={{color:"grey",fontSize:14,marginLeft:20}}>{item.dateTime}</div>
                                                        </div>
                                                        <div style={{display:"flex",flexDirection:"row"}}>
                                                            {
                                                            item.loggerName!="System"&&(reactLocalStorage.get("job")=="Sales Supervisor"||item.loggerUsername==reactLocalStorage.get("username"))?
                                                                <div style={{color:"red",textDecoration:"underline",fontSize:12,cursor:"pointer",margin:5}} onClick={()=>{
                                                                    if(window.confirm("Delete the sales log\nAre you sure?")){
                                                                        this.deleteSalesLog(item)
                                                                    }
                                                                }}>Delete</div>
                                                                :null
                                                            }
                                                            {
                                                            item.loggerName!="System"&&(reactLocalStorage.get("job")=="Sales Supervisor"||item.loggerUsername==reactLocalStorage.get("username"))?
                                                                <div style={{color:"blue",textDecoration:"underline",fontSize:12,cursor:"pointer",margin:5}} onClick={()=>{
                                                                    this.setState({editSalesLogMode:true},()=>{this.getSalesLog(item)})
                                                                }}>Edit</div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                    typeof item.message !='string'?
                                                        <Editor
                                                            editorState={item.message}
                                                            readOnly={true}
                                                            toolbarHidden={true}
                                                            blockRendererFn={(block) => {
                                                                if (block.getType() === 'atomic') {
                                                                    return {
                                                                        component: this.resizableImage,
                                                                        editable: false,
                                                                        props: {
                                                                            alignment: 'left',
                                                                        }
                                                                    };
                                                                }
                                                            }}
                                                            />
                                                    :
                                                        <div>{item.message.split("\n").map((line)=>{
                                                            return(
                                                                <div style={{width:"100%"}}>
                                                                    {line}
                                                                </div>
                                                            )
                                                        })}
                                                        </div>
                                                    }
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {
                                                        item.tag.map((item)=>{
                                                            return(
                                                                <div style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 4px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5,paddingLeft:5,paddingRight:5,borderRadius:5}}>
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.salesLogHistoryPages > 0 ?
                                            <div>
                                                <ReactPaginate
                                                    pageCount={this.state.salesLogHistoryPages}
                                                    previousLabel={'<'}
                                                    nextLabel={'>'}
                                                    pageRangeDisplayed={3}
                                                    marginPagesDisplayed={1}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLabel={"..."}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    disabledClassName={"disabled"}
                                                    forcePage={this.state.pageSalesLogHistory}
                                                    onPageChange={async ({selected}) => {
                                                        this.loadSalesLogHistoryOnPageChange(this.state.currentMember.username, selected);
                                                    }}
                                                />
                                            </div>
                                            : null
                                        }
                                        </div>
                                    :
                                    <div style={{color:"lightgrey"}}>Empty</div>
                                    }
                                </Col>
                            </Row>
                            
                            :
                            <div>No data</div>
                        }
                    </div>
                    :
                    //desktop
                    <div style={{padding:20,height:"100%",overflowY:"scroll"}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code / PIC (filter function click ? on right)"
                                style={{marginRight:10}}
                                value={this.state.findField}
                                onChange={event => this.setState({findField: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchMember()
                                    }
                                }}
                                />
                            <Button color="danger" style={{paddingLeft:20,paddingRight:20,whiteSpace:"nowrap",marginRight:5}} onClick={()=>{this.setState({findField:""})}}>Clear Query</Button>
                            <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{this.searchMember()}}>Search</Button>
                        </div>
                        <div style={{paddingTop:5,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                            {this.state.totalMemberCount} results found.
                            <div style={{marginLeft:10,color:"blue",cursor:"pointer"}}
                                onClick={()=>{
                                    this.setState({loading:true})
                                    //remove the member username in url
                                    let here = new URL(window.location.href)
                                    if(here.searchParams.get('username')!=null){
                                        here.searchParams.delete('username')
                                        window.history.pushState({},"",here)
                                    }

                                    this.setState({viewMode:"search",currentMember:{},loading:false})
                                }}>
                                Search result page
                            </div>
                            <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                    this.copyList()
                                }}>
                                <FaFileExcel style={{width:20,height:20,padding:4,color:"green"}}/>
                                <div style={{fontSize:12}}>Download as Excel</div>
                            </Button>
                            <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,fontSize:12}} onClick={()=>{GLOBAL.copyDiv(this.divRef)}}>
                                <FaCopy style={{width:20,height:20,padding:4}}/>
                                Copy List
                            </Button>
                            <Button color="light" style={{marginLeft:10,padding:"0px 5px",fontSize:12,border:"1px solid skyblue"}} onClick={()=>{
                                this.setState({checkExistModal:true,checkExistPhone:""})
                            }}>
                                Check exist
                            </Button>
                            {
                            this.state.viewMode=="detail" && this.state.totalMemberCount > 1? (
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom:5 }}>
                                    <Button color="light" style={{ borderStyle: "solid", borderWidth: 1, borderColor: "lightgrey", padding: 2, paddingRight: 8, paddingLeft: 10, fontSize: 12, marginRight: 10, marginLeft:10 }} onClick={this.handlePreviousPage} disabled={this.state.displayedPageMemberIndex<=1}>
                                        <div>{"<"}</div>
                                    </Button>
                                    <Input style={{ width: '30%', height: '100%', borderStyle: "solid", borderWidth: 1, borderColor: "lightgrey", padding: 2,fontSize: 14,}} type='number' value={this.state.displayedPageMemberIndex} min="1" max={this.state.totalMemberCount}
                                        onChange={(e) => {
                                            const inputValue = parseInt(e.target.value);
                                            const displayedValue = isNaN(inputValue) ? '':inputValue;
                                            this.setState({
                                                pageMemberIndex:(inputValue-1)%this.state.itemPerPage,
                                                displayedPageMemberIndex:displayedValue,
                                            })
                                        }}
                                        onKeyDown={(e)=>{
                                            const pageNumber=parseInt((this.state.displayedPageMemberIndex-1)/this.state.itemPerPage);
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.searchMember(pageNumber, true)
                                            }
                                        }}
                                    />
                                    <div style={{ marginLeft: 5, fontSize: 12 }}>/{this.state.totalMemberCount}</div>
                                    <Button color="light" style={{ borderStyle: "solid", borderWidth: 1, borderColor: "lightgrey", padding: 2, paddingLeft: 8, paddingRight: 10, fontSize: 12, marginLeft: 10 }} onClick={this.handleNextPage} disabled={this.state.displayedPageMemberIndex >= this.state.totalMemberCount}>
                                        <div>{">"}</div>
                                    </Button>
                                </div>
                            ) : null
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap",marginBottom:"5px",
                            padding:"10px 0px 5px 10px",width:"100%"}}>
                            <div style={{alignSelf:"flex-start"}}>Filter by:</div>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"membership",filterMembership:[]})
                            }}>
                                Membership
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.getJobList()
                            }}>
                                Job
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"company",filterCompany:""})
                            }}>
                                Company
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"PIC",filterPIC:""})
                            }}>
                                PIC
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getClientTagTitle()}}>
                                Tag
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"joinDate",filterJoinDateFrom:"",filterJoinDateTo:""})
                            }}>
                                JoinDate
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getBadgeTitle()}}>
                                Badge
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"credit",filterCredit:""})
                            }}>
                                Credit
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"installmentWallet",filterInstallment:""})
                            }}>
                                InstallmentWallet
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getVoucherTitle()}}>
                                Voucher
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getCourseHistoryTitle()}}>
                                ArchivedSess
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getTicketCourse()}}>
                                Ticket
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getCourseCredit()}}>
                                CourseCredit
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getCertificateTitle()}}>
                                Certificate
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getBookedSession()}}>
                                BookedSession
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referCode",filterReferCode:""})
                            }}>
                                ReferCode
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referRank",filterReferRank:[]})
                            }}>
                                ReferRank
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                this.setState({filterModal:true,filterMode:"referrer",filterReferrer:""})
                            }}>
                                Referrer
                            </Button>
                            <Button color="light" style={styles.filterButtonStyle} onClick={()=>{this.getClientStateList()}}>
                                State
                            </Button>
                        </div>
                        {
                        this.state.viewMode=="search"?
                            <div ref={this.divRef} style={{paddingLeft:10,paddingRight:10}}>
                                <Row style={{paddingBottom:20,borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,
                                    borderStyle:"solid",borderColor:"lightgrey"}}>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Phone")
                                    }}>
                                        <div>Phone</div>
                                        {
                                        this.state.sortPhone=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortPhone=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Name")
                                    }}>
                                        <div>Name</div>
                                        {
                                        this.state.sortName=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortName=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("code")
                                    }}>
                                        <div>Referal code</div>
                                        {
                                        this.state.sortCode=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortCode=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={2} onClick={()=>{
                                        this.sortColumn("Email")
                                    }}>
                                        <div>Email</div>
                                        {
                                        this.state.sortEmail=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortEmail=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Membership")
                                    }}>
                                        <div>Membership</div>
                                        {
                                        this.state.sortMembership=="Up"?
                                            <AiFillCaretUp/>
                                            :
                                        this.state.sortMembership=="Down"?
                                            <AiFillCaretDown/>
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Job")
                                    }}>
                                        <div>Job</div>
                                        {
                                        this.state.sortJob=="Up"?
                                            <AiFillCaretUp/>
                                            :
                                        this.state.sortJob=="Down"?
                                            <AiFillCaretDown/>
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Join Date")
                                    }}>
                                        <div>Join Date</div>
                                        {
                                        this.state.sortJoinDate=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortJoinDate=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Company")
                                    }}>
                                        <div>Company</div>
                                        {
                                        this.state.sortCompany=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortCompany=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("Referrer")
                                    }}>
                                        <div>Referrer</div>
                                        {
                                        this.state.sortReferrer=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortReferrer=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                        this.sortColumn("PIC")
                                    }}>
                                        <div>PIC</div>
                                        {
                                        this.state.sortPIC=="Up"?
                                            <AiFillCaretUp />
                                            :
                                        this.state.sortPIC=="Down"?
                                            <AiFillCaretDown />
                                            :null
                                        }
                                    </Col>
                                    <Col xs={1}></Col>
                                </Row>
                                {
                                this.state.memberList.length>0?
                                    this.state.memberList.map((item,index)=>{
                                        return(
                                            <Row style={styles.searchResultRow} key={item.username}>
                                                <Col xs={1}>{item.username}</Col>
                                                <Col xs={1} style={{fontSize:13}}>{item.name}</Col>
                                                <Col xs={1} style={{fontSize:10,color:"grey"}} >{item.description?item.description:item.referCode}</Col>
                                                <Col xs={2} style={{fontSize:14}}>{item.email}</Col>
                                                <Col xs={1}>{item.membership_type}({item.referRank})</Col>
                                                <Col xs={1}>{item.job}</Col>
                                                <Col xs={1}>{item.date_joined}</Col>
                                                <Col xs={1} style={{fontSize:12}}>{item.company}</Col>
                                                <Col xs={1}>
                                                    {
                                                    item.referrerName!=null?
                                                        item.referrerUsername==item.referrerName?
                                                            item.referrerName
                                                            :
                                                            item.referrerUsername+" "+item.referrerName
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col xs={1}>
                                                    {
                                                    item.personInCharge!=null?
                                                        item.personInCharge.username==item.personInCharge.name?
                                                            item.personInCharge.name
                                                            :
                                                            item.personInCharge.username+" "+item.personInCharge.name
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col xs={1}>
                                                    <Button color="primary" onClick={()=>{
                                                        this.setState({
                                                            pageMemberIndex:index,
                                                            displayedPageMemberIndex:(index+1)+(this.state.itemPerPage*this.state.page),
                                                        })
                                                        this.viewMember(item).then(()=>{
                                                            this.loadSalesLogHistory(item.username)
                                                        })
                                                    }}>
                                                        View
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    :
                                    <div> ------- </div>
                                }
                                <div style={{marginTop:10}}/>
                                <ReactPaginate
                                    pageCount={this.state.memberPages}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.searchMember(selected);
                                    }}/>
                            </div>
                            :
                            this.state.currentMember && this.state.currentMember.username?
                            <Row style={{display:"flex",flexDirection:"row",justifyContent:"center",paddingLeft:10,paddingRight:10}}>
                                <Col xs={12} sm={6} md={4}>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",borderStyle:"solid",borderWidth:1,padding:10,borderRadius:10}}>
                                    <div style={{maxWidth:600}}>
                                        <div colSpan={2} style={{textAlign:"center"}}>
                                            <img style={{width:120,height:120,borderRadius:"50%",backgroundColor:"grey"}} src={this.state.currentMember.profileImage} />
                                        </div>
                                        <div style={{textAlign:"center",marginTop:5,marginBottom:5}}>
                                            {
                                            reactLocalStorage.get("job")=="Course Advisor"||reactLocalStorage.get("job")=="Agent"||reactLocalStorage.get("job")=="Agent Leader"?
                                                null
                                                :
                                            this.state.editProfileMode?
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                                                    <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{this.setState({editProfileMode:false})}}>
                                                        Cancel
                                                    </Button>
                                                    <Button color="danger" style={{paddingTop:3,paddingBottom:3,fontSize:10,marginLeft:10}} onClick={()=>{
                                                        this.saveProfile()
                                                    }}>
                                                        Save
                                                    </Button>
                                                </div>
                                                :
                                                <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{
                                                    this.setState({
                                                        editProfileMode:true,
                                                        toChangeCurrentMember:JSON.parse(JSON.stringify(this.state.currentMember)), //shallow quick clone of object
                                                        newReferrerUsername:"",
                                                    })
                                                }}>
                                                    Edit Profile
                                                </Button>
                                            }
                                        </div>

                                        {
                                        this.state.editProfileMode?
                                            <table>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Username: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.username} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            e.target.value=e.target.value.replace(/\D/g,"")
                                                            newDetail.username=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Name: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.name} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.name=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Description if Any: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.description} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.description=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Email: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.email} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.email=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Date of Birth: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input type="date" value={this.state.toChangeCurrentMember.dateOfBirth} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.dateOfBirth=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Membership: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <select onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.membership_type=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}>
                                                            <option value="Free" selected={this.state.toChangeCurrentMember.membership_type=="Free"?true:false}>Free</option>
                                                            <option value="CM" selected={this.state.toChangeCurrentMember.membership_type=="CM"?true:false}>CM</option>
                                                            <option value="CP" selected={this.state.toChangeCurrentMember.membership_type=="CP"?true:false}>CP</option>
                                                            <option value="GP" selected={this.state.toChangeCurrentMember.membership_type=="GP"?true:false}>GP</option>
                                                            <option value="SP" selected={this.state.toChangeCurrentMember.membership_type=="SP"?true:false}>SP</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Job: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        {this.state.toChangeCurrentMember.job}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Company: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.company} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.company=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Corporate Account: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"wrap"}}>
                                                        {
                                                        this.state.currentMember.corporateAccountList.length>0?
                                                            this.state.currentMember.corporateAccountList.map((corpAcc)=>{
                                                                return(
                                                                    <div style={{padding:"5px 10px",backgroundColor:"azure",border:"1px solid grey",borderRadius:5}}>
                                                                        {corpAcc.companyName+" ["+corpAcc.job+"]"}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            "-"
                                                        }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Position Title: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <Input value={this.state.toChangeCurrentMember.jobTitle} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.jobTitle=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Refer rank: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        {this.state.toChangeCurrentMember.referRank}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>R Wallet: </td>
                                                    <td style={styles.memberDetailCell}>RM{this.state.currentMember.topupWallet}</td>
                                                </tr>
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>P Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.pWallet}</div>
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1,marginRight:5}} onClick={()=>{
                                                                 this.setState({walletLogModal: true})//edit here
                                                            }}>
                                                                Wallet Log
                                                            </Button>
                                                            {/*
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                this.getEarningRecords()
                                                            }}>
                                                                Earning Record
                                                            </Button>
                                                            */}
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>C Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.wWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>Installment Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.currentMember.partialWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td style={styles.memberDetailCell}>充值 Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,padding:3}}>
                                                            <div>RM{this.state.currentMember.cashWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {/*
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Point: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.point}</td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Certificate: </td>
                                                    <td style={styles.memberDetailCell}>{this.getCertificateString(this.state.currentMember.certificate)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Badge: </td>
                                                    <td style={styles.memberDetailCell}>{this.getBadgeString(this.state.currentMember.badge)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Refer code: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.referCode}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Referrer: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <div>Current:{this.state.toChangeCurrentMember.referrerName?this.state.toChangeCurrentMember.referrerName:"-"}</div>
                                                        <div>New:<Input value={this.state.newReferrerUsername} placeholder="phone number of new referrer"
                                                            onChange={(e)=>{this.setState({newReferrerUsername:e.target.value})}} /></div>
                                                        <Button color="secondary" style={{marginTop:2,padding:2,fontSize:10}} onClick={()=>{
                                                            this.setState({newReferrerUsername:"No Referrer"})
                                                        }}>No Referrer</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Person in Charge: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.personInCharge?this.state.currentMember.personInCharge.name:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Date joined: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.date_joined?this.state.currentMember.date_joined:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Address: </td>
                                                    <td style={styles.memberDetailCell}>
                                                        <textarea value={this.state.toChangeCurrentMember.billingAddress}
                                                            onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentMember
                                                            newDetail.billingAddress=e.target.value
                                                            this.setState({toChangeCurrentMember:newDetail})
                                                        }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Lead from company: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.leadFromCompany?"Yes":"No"}</td>
                                                </tr>
                                            </table>
                                            :
                                            <table>
                                                <tr>
                                                    <td>Username: </td>
                                                    <td>{this.state.currentMember.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name: </td>
                                                    <td>{this.state.currentMember.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Description:</td>
                                                    <td>{this.state.currentMember.description?this.state.currentMember.description:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email: </td>
                                                    <td>{this.state.currentMember.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Birth: </td>
                                                    <td>{this.state.currentMember.dateOfBirth?this.state.currentMember.dateOfBirth:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Membership: </td>
                                                    <td>{this.state.currentMember.membership_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Job: </td>
                                                    <td>{this.state.currentMember.job}</td>
                                                </tr>
                                                <tr>
                                                    <td>Company: </td>
                                                    <td>{this.state.currentMember.company?this.state.currentMember.company:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Corporate Account: </td>
                                                    <td>
                                                        <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"wrap"}}>
                                                        {
                                                        this.state.currentMember.corporateAccountList.length>0?
                                                            this.state.currentMember.corporateAccountList.map((corpAcc)=>{
                                                                return(
                                                                    <div style={{padding:"5px 10px",backgroundColor:"azure",border:"1px solid grey",borderRadius:5}}>
                                                                        {corpAcc.companyName+" ["+corpAcc.job+"]"}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            "-"
                                                        }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Position Title: </td>
                                                    <td>{this.state.currentMember.jobTitle?this.state.currentMember.jobTitle:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Refer rank: </td>
                                                    <td>{this.state.currentMember.referRank?this.state.currentMember.referRank:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>R Wallet: </td>
                                                    <td>RM{this.state.currentMember.topupWallet}</td>
                                                </tr>
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>P Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.pWallet}</div>
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                  this.setState({walletLogModal: true})//edit here
                                                            }}>
                                                                Wallet Log
                                                            </Button>
                                                            {/*
                                                            <Button color="primary" style={{fontSize:12,padding:"2px 5px",lineHeight:1}} onClick={()=>{
                                                                this.getEarningRecords()
                                                            }}>
                                                                Earning Record
                                                            </Button>
                                                            */}
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>C Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.wWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>Installment Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.currentMember.partialWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                    <tr>
                                                        <td>充值 Wallet: </td>
                                                        <td style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10}}>
                                                            <div>RM{this.state.currentMember.cashWallet}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {/*
                                                <tr>
                                                    <td>Point: </td>
                                                    <td>{this.state.currentMember.point}</td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td style={{padding:5,verticalAlign:"top"}}>Certificate: </td>
                                                    <td style={{padding:5,}}>{this.getCertificateString(this.state.currentMember.certificate)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:5,verticalAlign:"top"}}>Badge: </td>
                                                    <td style={{padding:5,}}>{this.getBadgeString(this.state.currentMember.badge)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Refer code: </td>
                                                    <td>{this.state.currentMember.referCode}</td>
                                                </tr>
                                                <tr>
                                                    <td>Referrer: </td>
                                                    <td>{this.state.currentMember.referrerName?this.state.currentMember.referrerUsername+" "+this.state.currentMember.referrerName:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Person in Charge: </td>
                                                    <td>
                                                        {
                                                        this.state.currentMember.personInCharge?
                                                            this.state.currentMember.personInCharge.username==this.state.currentMember.personInCharge.name?
                                                                this.state.currentMember.personInCharge.name
                                                                :
                                                                this.state.currentMember.personInCharge.username+" "+this.state.currentMember.personInCharge.name
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date joined: </td>
                                                    <td>{this.state.currentMember.date_joined?this.state.currentMember.date_joined:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.memberDetailCell}>Address: </td>
                                                    <td style={styles.memberDetailCell}>{this.state.currentMember.billingAddress?this.state.currentMember.billingAddress:"-"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Lead from company: </td>
                                                    <td>{this.state.currentMember.leadFromCompany?"Yes":"No"}</td>
                                                </tr>
                                            </table>
                                        }
                                    </div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={6} md={8} style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start",gap:5}}>
                                    <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                            <div>Background Info</div>
                                            {
                                            GLOBAL.checkPermission("4~C$$mtS")?
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                    this.setState({editBackgroundModal:true,editMemberBackground:this.state.currentMember.background})
                                                }}>
                                                    Edit
                                                </Button>
                                                :
                                                null
                                            }
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:50,border:"1px solid lightgrey",borderRadius:5,padding:10}}>
                                            {
                                            this.state.currentMember.background!=null?
                                                this.state.currentMember.background.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                :
                                                <div style={{color:"lightgrey",width:"100%"}}>No Background Info</div>
                                            }
                                        </div>
                                    </div>
                                    
                                    {
                                    GLOBAL.checkPermission("=oj$ppRa")?
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                                <div>Tickets</div>
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                    this.setState({createTicketModal:true})
                                                    if(this.state.currentMember.personInCharge!=null){
                                                        this.setState({createTicketPIC:this.state.currentMember.personInCharge.username})
                                                    }
                                                }}>
                                                    Create Ticket
                                                </Button>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap"}}>
                                                {
                                                this.state.memberTicketList.length==0?
                                                    <div style={{padding:5,color:"lightgrey",width:"100%",border:"1px solid lightgrey"}}>
                                                        Empty
                                                    </div>
                                                    :
                                                    <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                                        {
                                                        this.state.memberTicketList.map((ticket)=>{
                                                            return(
                                                                <Button color="light" style={{padding:0,border:"1px solid lightgrey",display:"flex",flexDirection:"column",alignItems:"flex-start",fontSize:13}} 
                                                                    onClick={()=>{
                                                                        window.open("/ticket?id="+ticket.id,"_blank")
                                                                }}>
                                                                    <div style={{fontWeight:"bold",width:"100%",fontSize:15,backgroundColor:"#6A6A68",
                                                                        padding:"5px 20px",color:"#B8DBDA"}}>
                                                                        Ticket {ticket.id}
                                                                    </div>
                                                                    <div style={{padding:"5px 20px 10px 20px",textAlign:"left"}}>
                                                                        <div>Referrer: {ticket.referrerString}</div>
                                                                        <div>PIC: {ticket.picString}</div>
                                                                        <div>Channel: {ticket.channel}</div>
                                                                        <div>Interest: {ticket.interest}</div>
                                                                        <div>Expiry: {ticket.expiryDate}</div>
                                                                        <div>Last Update: {ticket.lastUpdate}</div>
                                                                        <div>Create Date: {ticket.createDate}</div>
                                                                    </div>
                                                                </Button>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                <div>Tags</div>
                                                <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",padding:2,paddingLeft:8,paddingRight:10,fontSize:12,marginLeft:10}} onClick={()=>{
                                                    this.loadAllTagList()
                                                }}>
                                                    <div>Add</div>
                                                </Button>
                                            </div>
                                            <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap",padding:5}}>
                                                {
                                                this.state.clientTagList.length>0?
                                                    this.state.clientTagList.map((item)=>{
                                                        return(
                                                            <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey",marginRight:5}}>
                                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                    <div>{item.title}</div>
                                                                    <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                        this.deleteClientTag(item)
                                                                    }}>x</Button>
                                                                </div>
                                                                <div style={{fontSize:10}}>
                                                                    Times used: {item.timesUsed}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :<div style={{color:"lightgrey"}}>Empty</div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <div>Relationship</div>
                                        <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",padding:2,paddingLeft:8,paddingRight:10,fontSize:12,marginLeft:10}} onClick={()=>{
                                            this.setState({
                                                relationshipModal:true,
                                                createRelationship:true,
                                                userOneUsername:this.state.currentMember.username,
                                            })
                                        }}>
                                            <div>Add</div>
                                        </Button>
                                    </div>
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap",padding:5}}>
                                        {
                                        this.state.relationshipList.length>0?
                                            this.state.relationshipList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey",marginRight:5}}>
                                                        {
                                                        this.state.currentMember?
                                                            this.state.currentMember.username==item.userOne.username?
                                                                <div style={{display:"flex",flexDirection:"column",fontSize:12}}>
                                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                                        <div style={{display:"flex",flexDirection:"row",fontSize:16}}>
                                                                            <div style={{fontWeight:"bold"}}>{item.relationshipOne}</div>
                                                                            <div style={{marginLeft:7}}>of</div>
                                                                        </div>
                                                                        <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                            this.deleteRelationship(item)
                                                                        }}>x</Button>
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                                        <a style={{color:"skyblue",textDecoration:"underline",cursor:"pointer"}} href={"?username="+item.userTwo.username} target="_blank">
                                                                            {item.userTwo.username}
                                                                        </a>
                                                                        <div style={{marginLeft:7}}>{item.userTwo.name}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div style={{display:"flex",flexDirection:"column",fontSize:12}}>
                                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                                        <div style={{display:"flex",flexDirection:"row",fontSize:16}}>
                                                                            <div style={{fontWeight:"bold"}}>{item.relationshipTwo}</div>
                                                                            <div style={{marginLeft:7}}>of</div>
                                                                        </div>
                                                                        <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                                            this.deleteRelationship(item)
                                                                        }}>x</Button>
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                                        <a style={{color:"skyblue",textDecoration:"underline",cursor:"pointer"}} href={"?username="+item.userOne.username} target="_blank">
                                                                            {item.userOne.username}
                                                                        </a>
                                                                        <div style={{marginLeft:7}}>{item.userOne.name}</div>
                                                                    </div>
                                                                </div>
                                                            :null
                                                        }
                                                    </div>
                                                )
                                            })
                                            :<div style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    <div style={{}}>Course Credit</div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                        {
                                        this.state.courseCreditList.length>0?
                                            this.state.courseCreditList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey"}}>
                                                        <div style={{fontSize:14}}>{item.courseString}</div>
                                                        <div style={{color:"grey",fontSize:12}}>Signup Date: {item.signupDate}</div>
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.ticketList.length>0?
                                            this.state.ticketList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,width:160,border:"1px solid #fff700",backgroundColor:"#fffdcf"}}>
                                                        <div>{item.courseName}</div>
                                                        <div style={{color:"grey",fontSize:12,marginRight:5}}>购买日期:{item.createDate}</div>
                                                        {
                                                        item.targetEntreCourseDate?
                                                            <div style={{color:"grey",fontSize:12}}>课程日期:{item.targetEntreCourseDate}</div>
                                                            :null
                                                        }
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.offlineEventTicketList.length>0?
                                            this.state.offlineEventTicketList.map((item)=>{
                                                return(
                                                    <div style={{padding:5,borderRadius:5,width:160,border:"1px solid #fff700",backgroundColor:"#fffdcf"}}>
                                                        <div>{item.offlineEventTypeName}</div>
                                                        <div style={{fontSize:12}}>赠送目标:{item.receiver!=null?item.receiver.username+" "+item.receiver.name:"-"}</div>
                                                        <div style={{color:"grey",fontSize:12}}>得到日期:{item.createDate}</div>
                                                    </div>
                                                )
                                            })
                                            :null
                                        }
                                        {
                                        this.state.conferenceBookingList.map((item)=>{
                                            return(
                                                <div style={{padding:5,borderRadius:5,width:200,border:"1px solid #87edd6",backgroundColor:"#f2fffc",display:"flex",flexDirection:"row"}}>
                                                    <div style={{displlay:"flex",flexDirection:"column",flex:1}}>
                                                        <div>{item.conferenceName}</div>
                                                        <div style={{color:"grey",fontSize:12,marginRight:5}}>购买日期:{item.bookDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>活动日期:{item.conferenceStartTime}</div>
                                                    </div>
                                                    <Button color="light" style={{borderColor:"lighgrey"}} onClick={()=>{
                                                        this.setState({
                                                            qrModal:true,
                                                            qrCode:item.qrImage,
                                                            ticketNumber:item.ticketNumber,
                                                        })
                                                    }}>
                                                        <AiOutlineQrcode />
                                                    </Button>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.courseCreditList.length==0&&this.state.ticketList.length==0&&this.state.conferenceBookingList.length==0&&this.state.offlineEventTicketList.length==0?
                                            <div style={{color:"lightgrey"}}>Empty</div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{}}>Booked Coming Course Session</div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.bookedCourseSession.length==0&&this.state.bookedOfflineEvent.length==0?
                                            <div style={{color:"lightgrey"}}>Empty</div>
                                            :
                                            null
                                        }
                                        {
                                        this.state.bookedCourseSession.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.courseSessionTitle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.courseSessionDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.courseSessionVenue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{color:"white",backgroundColor:"#21618c",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.bookedOfflineEvent.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.offlineEvent.title}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.offlineEvent.calendarDate}<br/>{item.offlineEvent.time} ~ {item.offlineEvent.timeUntil}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.offlineEvent.venue}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div style={{}}>Previous Attendance</div>
                                
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.activityLog.length==0&&this.state.offlineEventHistory.length==0&&this.state.archivedConferenceBooking.length==0?
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                            :null
                                        }
                                        {
                                        this.state.activityLog.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.courseSessionTitle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.courseSessionDate}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.courseSessionVenue}</div>                                                    
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{color:"white",backgroundColor:"#21618c",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                        <div style={{fontSize:12,color: item.attendance ? "green" : "red"}}>{item.attendance?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {                                        
                                        this.state.archivedConferenceBooking.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.archivedConferenceTittle}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.archivedConferenceDateTime}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.archivedConferenceVenue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                    <div style={{color:"white",backgroundColor:"#FFA500",padding:2,borderRadius:5,fontSize:12}}>{item.ticketNumber}</div>
                                                        <div style={{fontSize:12 ,color: item.attend ? "green" : "red"}}>{item.attend?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                        this.state.offlineEventHistory.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                        <div style={{fontWeight:"bold",fontSize:14}}>{item.archivedOfflineEvent.title}</div>
                                                        <div style={{color:"grey",fontSize:12}}>时间: {item.archivedOfflineEvent.calendarDate}<br/>{item.archivedOfflineEvent.time} ~ {item.archivedOfflineEvent.timeUntil}</div>
                                                        <div style={{color:"grey",fontSize:12}}>地点: {item.archivedOfflineEvent.venue}</div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <div style={{fontSize:12,color: item.attend ? "green" : "red"}}>{item.attend?"确认出席":"缺席"}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div style={{}}>Expiry Dates</div>
                                    <div style={{maxHeight:250,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.expiryRecord.length>0?
                                            this.state.expiryRecord.map((item)=>{
                                                return(
                                                    <div style={{fontSize:10,padding:3,display:"flex",flexDirection:"column",borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,margin:2}}>
                                                        <div style={{flex:0.2}}>{item.badge.title}</div>
                                                        <div style={{flex:0.6}}>Expiry: {item.expiryDate}</div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>

                                    <div style={{}}>Transaction Record</div>
                                    <div style={{maxHeight:250,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.transactionHistoryList.length>0?
                                            this.state.transactionHistoryList.map((item)=>{
                                                return(
                                                    <div style={{width:"100%",fontSize:10,padding:3,display:"flex",flexDirection:"row",borderStyle:"solid",borderColor:"lightgrey",borderTopWidth:1,borderBottomWidth:0,borderLeftWidth:0,borderRightWidth:0}}>
                                                        <div style={{flex:0.2}}>{item.dateTime}</div>
                                                        <div style={{flex:0.6}}>{item.agenda}</div>
                                                        <div style={
                                                            item.value>=0?
                                                                item.agenda.startsWith("购买")||item.agenda.startsWith("Purchase course")?
                                                                    styles.purchaseText
                                                                    :
                                                                    styles.profitText
                                                                :
                                                                styles.lossText
                                                            }>
                                                            {item.value<0?"-":""}RM{Math.abs(item.value)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    {
                                    this.state.transactionHistoryPages > 0 ?
                                        <div style={{}}>
                                            <ReactPaginate
                                                pageCount={this.state.transactionHistoryPages}
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={1}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledClassName={"disabled"}
                                                forcePage={this.state.pageTransactionHistory}
                                                onPageChange={async ({selected}) => {
                                                    this.loadTransactionHistoryOnPageChange(this.state.currentMember.username, selected);
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{}}>Voucher</div>
                                    <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                        {
                                        this.state.promoList.length>0?
                                            this.state.promoList.map((item)=>{
                                                return(
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingLeft:10,paddingRight:10,paddingTop:2,paddingBottom:2,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}}>
                                                        <div style={{display:"flex",flexDirection:"column",paddingRight:10}}>
                                                            <div style={{fontWeight:"bold",fontSize:14}}>{item.title}</div>
                                                            <div style={{color:"grey",fontSize:12}}>发出时间: {item.dateTimeCreated}</div>
                                                            <div style={{color:"grey",fontSize:12}}>截止日期: {item.expiryDate}</div>
                                                            <div style={{color:"grey",fontSize:12}}>发送人: {item.sender?item.sender:"System"}</div>
                                                        </div>
                                                        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                                                            <div style={{cursor:"pointer",color:"white",backgroundColor:"#32611f",padding:2,borderRadius:5,fontSize:12,display:"flex",justifyContent:"center",alignItems:"center"}}
                                                                onClick={()=>{
                                                                    //verify auth
                                                                    let theUsername=reactLocalStorage.get("username")
                                                                    if(reactLocalStorage.get("job")!="Sales Supervisor"){
                                                                        return
                                                                    }
                                                                    this.setState({
                                                                        promoVoucherModal:true,
                                                                        currentPromoVoucher:JSON.parse(JSON.stringify(item)),   //shallow clone object
                                                                    })
                                                                }}>
                                                                {item.amount}
                                                            </div>
                                                            <div style={{fontSize:12}}>{item.type}:{item.value}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div  style={{color:"lightgrey"}}>Empty</div>
                                        }
                                    </div>
                                    <div style={{}}>
                                        Referred member ({this.state.referredMember.length}):
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",overflowY:"scroll",maxHeight:280,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,padding:5}}>
                                    {
                                    this.state.referredMember.length>0?
                                        this.state.referredMember.map((item)=>{
                                            return(
                                                <div style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,borderRadius:5,padding:5}}>
                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                        <a style={{fontSize:12,marginRight:10,color:"skyblue",textDecoration:"underline",cursor:"pointer"}}
                                                            href={"?username="+item.username} target="_blank">
                                                            {item.username}
                                                        </a>
                                                        <div style={{fontSize:12,fontWeight:"bold"}}>
                                                            {item.name} (joined: {item.date_joined})
                                                        </div>
                                                    </div>
                                                    <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                                        <img src={this.checkBadge(item.badge,"战略三力 系统班")?Badge12:DarkBadge12} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"营销三力 系统班")?Badge13:DarkBadge13} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"组织三力 系统班")?Badge14:DarkBadge14} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"模式三力")?Badge15:DarkBadge15} style={{width:"auto",height:42,margin:2}} />
                                                        <img src={this.checkBadge(item.badge,"总裁三力")?Badge16:DarkBadge16} style={{width:"auto",height:42,margin:2}} />

                                                    </div>
                                                    <div style={{fontSize:10,color:"grey"}}>Joined: {item.date_joined.split("+")[0].split("T")}</div>
                                                </div>
                                            )
                                        })
                                        :
                                        null
                                    }
                                    </div>
                                    <div style={{}}>
                                        Sales Log History
                                        <MdRefresh style={{fontSize:23}} onClick={()=>{
                                                this.loadSalesLogHistory(this.state.currentMember.username)
                                            }}
                                        />
                                    </div>
                                    <div style={{}}>New Sales Log</div>
                                    <div style={{width:"100%",padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                        <Editor style={{}}
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.onEditorStateChange}
                                            handlePastedFiles={this.handlePastedFiles}
                                            blockRendererFn={(block) => {
                                                if (block.getType() === 'atomic') {
                                                    return {
                                                        component: this.resizableImage,
                                                        editable: false,
                                                        props: {
                                                            alignment: 'left',
                                                        }
                                                    };
                                                }
                                            }}
                                            toolbar={{
                                                image:{
                                                    uploadEnabled:true,
                                                    uploadCallback:this.imageUploadCallback,
                                                    previewImage: true,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    alignmentEnabled: false,
                                                }
                                            }}
                                            // customBlockRenderFunc={(block)=>{
                                            //     if(block.getType!=='atomic'){
                                            //         return{
                                            //             component:this.resizableImage,
                                            //             editable:false,
                                            //             onResize:(width,height)=>{
                                            //                 console.log(width,height)
                                            //             }
                                            //         }
                                            //     }
                                            // }}
                                            />
                                    </div>
                                    {/* <Input onChange={(e)=>this.setState({newSalesLog:e.target.value})}
                                            onKeyDown={(e)=>{
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.postSalesLog()
                                            }}
                                            }/> */}
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                            {this.state.selectedTag.map((item)=>{
                                                return(
                                                    <Button style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 5px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5}}
                                                        onClick={()=>{this.removeTag(item)}}>
                                                        {item.name}<TiDelete style={{marginLeft:5,fontSize:25}}/>
                                                    </Button>
                                                )
                                            })}
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
                                            <Button color="info" style={{marginRight:10,width:100,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{this.getAllTags()}}>
                                                <div>Tag</div>
                                            </Button>
                                            <Button color="primary" style={{width:100}} onClick={()=>{this.post()}}>Post</Button>
                                        </div>
                                    </div>


                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                                        <Input type="text" name="SalesLog" placeholder="Search example:系统商学"
                                            style={{marginRight:10}}
                                            value={this.state.findField2}
                                            onChange={event => this.setState({findField2: event.target.value})}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.searchSalesLog()
                                                }
                                            }}/>
                                        <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{this.searchSalesLog()}}>Search</Button>

                                    </div>
                                        <div style={{marginBottom:20,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                                        {this.state.theLogsCount} results found.
                                        <Button color="danger" style={{fontSize: '13px',marginLeft:5,width:60,height:20,textAlign: 'center',padding: 0}} onClick={()=>{this.loadSalesLogHistory(this.state.currentMember.username)}}>Cancel</Button>
                                        </div>


                                    {
                                    this.state.salesLogHistory.length>0?
                                        <div>
                                        {
                                        this.state.salesLogHistory.map((item,index)=>{
                                            return(
                                                <div style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginBottom:5,padding:5,borderRadius:5}}>
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                        <div style={{display:"flex",flexDirection:"row"}}>
                                                            <div style={{fontWeight:"bold"}}>{item.loggerName?item.loggerName:"By deleted user"}</div>
                                                            <div style={{color:"grey",fontSize:14,marginLeft:20}}>{item.dateTime}</div>
                                                        </div>
                                                        <div style={{display:"flex",flexDirection:"row"}}>
                                                            {
                                                            item.loggerName!="System"&&(reactLocalStorage.get("job")=="Sales Supervisor"||item.loggerUsername==reactLocalStorage.get("username"))?
                                                                <div style={{color:"red",textDecoration:"underline",fontSize:12,cursor:"pointer",margin:5}} onClick={()=>{
                                                                    if(window.confirm("Delete the sales log\nAre you sure?")){
                                                                        this.deleteSalesLog(item)
                                                                    }
                                                                }}>Delete</div>
                                                                :null
                                                            }
                                                            {
                                                            item.loggerName!="System"&&(reactLocalStorage.get("job")=="Sales Supervisor"||item.loggerUsername==reactLocalStorage.get("username"))?
                                                                <div style={{color:"blue",textDecoration:"underline",fontSize:12,cursor:"pointer",margin:5}} onClick={()=>{
                                                                    this.setState({editSalesLogMode:true},()=>{this.getSalesLog(item)})
                                                                }}>Edit</div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                    typeof item.message != 'string' ?
                                                        <Editor
                                                            editorState={item.message}
                                                            readOnly={true}
                                                            toolbarHidden={true}
                                                            blockRendererFn={(block) => {
                                                                if (block.getType() === 'atomic') {
                                                                    return {
                                                                        component: this.resizableImage,
                                                                        editable: false,
                                                                        props: {
                                                                            alignment: 'left',
                                                                        }
                                                                    };
                                                                }
                                                            }}
                                                        />
                                                    :
                                                        <div>{item.message.split("\n").map((line)=>{
                                                            return(
                                                                <div style={{width:"100%"}}>
                                                                    {line}
                                                                </div>
                                                            )
                                                        })}
                                                        </div>
                                                    }
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {
                                                        item.tag.map((item)=>{
                                                            return(
                                                                <div style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 4px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5,paddingLeft:5,paddingRight:5,borderRadius:5}}>
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            )})
                                        }
                                        {
                                        this.state.salesLogHistoryPages > 0 ?
                                            <div>
                                                <ReactPaginate
                                                    pageCount={this.state.salesLogHistoryPages}
                                                    previousLabel={'<'}
                                                    nextLabel={'>'}
                                                    pageRangeDisplayed={3}
                                                    marginPagesDisplayed={1}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLabel={"..."}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    disabledClassName={"disabled"}
                                                    forcePage={this.state.pageSalesLogHistory}
                                                    onPageChange={async ({selected}) => {
                                                        this.loadSalesLogHistoryOnPageChange(this.state.currentMember.username, selected);
                                                    }}
                                                />
                                            </div>
                                            : null
                                        }
                                        </div>
                                    :
                                    <div style={{color:"lightgrey"}}>Empty</div>
                                    }
                                </Col>
                            </Row>
                            :
                            <div>No data</div>
                        }
                    </div>
                    
                }
                { 
                    this.state.walletLogModal && (
                        <>                                                 
                            <WalletLog
                                sendLoadingToParent={this.handleChildLoading}
                                currentMember={this.state.currentMember.id}
                                isOpen={this.state.walletLogModal}
                                toggle={() => this.setState({ walletLogModal: false, loading: false })}
                            />
                        </>
                    )
                }




                
                </div>
        )
    }
}

let styles={
    columnTitleStyle:{
        fontWeight:"bold",
        cursor:"pointer",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
    },
    searchResultRow:{
        paddingTop:10,
        paddingBottom:10,
        borderBottomWidth:1,
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderStyle:"solid",
        borderColor:"#e6e6e6",
        ':hover':{
            backgroundColor:"grey",
            color:"white",
        },
    },
    WalletRowPadding:{
        paddingTop:10,
        paddingBottom:10,
        paddingLeft:10,
        paddingRight:10,
    },
    memberDetailCell:{
        padding:3
    },
    purchaseText:{
        color:"grey",
        flex:0.2,
        textAlign:"right",
    },
    profitText:{
        color:"green",
        flex:0.2,
        textAlign:"right",
    },
    lossText:{
        color:"salmon",
        flex:0.2,
        textAlign:"right",
    },
    tableCellStyle:{
        verticalAlign:"center",
    },
    filterButtonStyle:{
        fontSize:13,
        padding:"2px 10px",
        border:"1px solid grey"
    },
    filterButtonStyleMobile:{
        fontSize:10,
        padding:"1px 5px",
        border:"1px solid grey"
    },
}