import React from 'react';
import Radium from 'radium';
import {Input,Button,Modal,Collapse} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

import defaultProfile from '../assets/images/profileImage.png'

export default class ClientBackground extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            participantList:[],
            searchedList:[],

            editBackgroundModal:false,
            editMemberBackground:null,
            editingMember:null,
            focusingElement:null,

            findField:"",
            searching:false,


            viewBackrgound:false,
            collapseStates: {},
            focusStates:{},
            currentOfflineEvent:null,
           
        }
        this.classType=null
        this.classID=null
        this.toggle = this.toggle.bind(this);
        
        this.currentDivCollapseRefs = {};
        this.searchDivCollapseRefs = {};
           
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(let input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="type"){
                this.classType=value
            }else if(param=="class"){
                this.classID=value
            }
        }

        this.loadClassBackgroundInfo()
    }

    handleEditClick = (e, item) => {
        // Stop the click event from bubbling up to the collapse button
        e.stopPropagation();
        
        this.setState({
            editBackgroundModal: true,
            editMemberBackground:(item.user?item.user.background:item.nonMember.background),
            editingMember: (item.user ? item.user.username : item.nonMember.phone),
            focusingElement:(item.id)
        });
        }

    handleModalClose = () => {
        // Focus back on the button when the modal closes
        if (this.currentDivCollapseRefs[this.state.focusingElement]) {
            this.currentDivCollapseRefs[this.state.focusingElement].focus();
            this.currentDivCollapseRefs[this.state.focusingElement].scrollIntoView({ behavior: 'smooth', block: 'center' });

        }
        
    }

    closeCollapse = (itemId) => {
        this.setState((prevState) => ({
          focusStates: {
            ...prevState.focusStates,
            [itemId]: false,
          },
          collapseStates: {
            ...prevState.collapseStates,
            [itemId]: false, // Ensure collapseStates also updates to false on blur
          },
        }));
      };

    toggle = (itemId) => {
        this.setState((prevState) => ({
            collapseStates: {
            ...prevState.collapseStates,
            [itemId]: !prevState.collapseStates[itemId],
            },
            focusStates: {
                ...prevState.focusStates,
                [itemId]: !prevState.collapseStates[itemId],
            },
        }));
    };

    setDivRef = (itemId, element) => {
        
        this.currentDivCollapseRefs[itemId] = element;
       
    };
      
    saveRemark=(ticketNumber,remark)=>{
        this.setState({loading:true})
        const payLoad = {
            ticketNumber:ticketNumber,
            remark:remark,
        }
        axios
            .post(`sales/saveofflineeventbookingremark`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({loading:false})
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Save "+this.eventName+" booking remark error\n" + error)
                this.setState({loading: false,})
            });
    }
  
    searchMember = () => {
        this.setState({ loading: true, searching: true });
        
        // Get and clean up the search term from the input field
        let searchTerm = this.state.findField
            .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
            .trim()                  // Trim leading and trailing whitespace
            .toLowerCase();          // Convert to lowercase for case-insensitive search
    
        let filteredMembers = [];
        for (let person of this.state.participantList) {            
            // Check if any field matches the search term
            if (
                (person.user? person.user.name?.toLowerCase().includes(searchTerm):person.nonMember.name?.toLowerCase().includes(searchTerm) ) ||
                (person.user? person.user.company?.toLowerCase().includes(searchTerm): person.nonMember.company?.toLowerCase().includes(searchTerm)) ||
                (person.user? person.user.email===searchTerm: person.nonMember.email === searchTerm) ||
                (person.user? person.user.username === searchTerm: person.nonMember.phone === searchTerm) 
               
              
            ) {
                filteredMembers.push(person); // Add member to filtered list
            }
        }
        this.setState({ searchedList: filteredMembers, loading: false });
    };

    generateAttendanceUI=(item)=>{
        let sDate=new Date(this.state.currentOfflineEvent.startDate)
        let eDate=new Date(this.state.currentOfflineEvent.endDate)
        let difference = Math.floor((eDate.getTime()-sDate.getTime())/(1000*3600*24))+1
        let attendanceBadge=[]
        for(let i=0;i<difference;i++){
            let theDate=new Date(sDate)
            theDate.setDate(sDate.getDate()+i)
            attendanceBadge.push(
                <div style={{width:20,height:20,textAlign:"center",display:"flex",flexDirection:"row",alignItems:"center",
                    justifyContent:"center",borderRadius:"50%",
                    backgroundColor:
                        item.attendance!=null&&item.attendance[theDate.getDate()]!=undefined&&item.attendance[theDate.getDate()]==true?
                            "green"
                            :
                            "red"
                    ,
                }}>
                    <div style={{fontSize:13,color:"white"}}>{theDate.getDate()}</div>
                </div>
            )
        }
        return(
            <div style={{display:"flex",flexDirection:"row",gap:3,justifyContent:"center"}}>
                {attendanceBadge}
            </div>
        )
    }
    
            

    
    saveClientBackground=()=>{
        this.setState({loading:true})
        const payload = {
            memberID:this.state.editingMember,
            editMemberBackground:this.state.editMemberBackground,
        }
        axios
            .post(`sales/saveclientbackground`, qs.stringify(payload),{timeout:15000})
            .then(async response => {
                
                let updatedParticipantList = this.state.participantList.map(participant => {
                    if (participant.user) {
                        // Handle member case
                        if (participant.user.username === this.state.editingMember) {
                            return {
                                ...participant,
                                user: {
                                    ...participant.user,
                                    background: this.state.editMemberBackground
                                }
                            };
                        }
                    } else {
                        // Handle non-member case
                        if (participant.nonMember.phone === this.state.editingMember) {
                            return {
                                ...participant,
                                nonMember:{
                                        ...participant.nonMember,
                                        background: this.state.editMemberBackground
                                    }
                            };
                        }
                    }
                    return participant;
                });
                
                let updatedSearchedList = this.state.searching 
                    ? this.state.searchedList.map(participant => {
                        if (participant.user) {
                            // Handle member case
                            if (participant.user.username === this.state.editingMember) {
                                return {
                                    ...participant,
                                    user: {
                                        ...participant.user,
                                        background: this.state.editMemberBackground
                                    }
                                };
                            }
                        } else {
                            // Handle non-member case
                            if (participant.nonMember.phone === this.state.editingMember) {
                                return {
                                    ...participant,
                                    nonMember:{
                                        ...participant.nonMember,
                                        background: this.state.editMemberBackground
                                    }
                                };
                            }
                        }
                        return participant;
                    })
                    : [];

                this.setState({
                    editBackgroundModal: false,
                    participantList: updatedParticipantList,
                    searchedList: this.state.searching ? updatedSearchedList : [],
                    loading: false
                });
              
                alert("User background info saved")

                this.loadClassBackgroundInfo()
               
                
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Edit member background info error\n"+error)
                this.setState({loading:false})
            })
    }

    loadClassBackgroundInfo=()=>{
        this.setState({loading:true})

        const payLoad = {
            classType:this.classType,
            classID:this.classID,
        }

        axios
            .post(`sales/loadclassbackgroundinfo`, qs.stringify(payLoad),{timeout:30000})
            .then(async response => {
             
                
                for(let item of response.data.nameList){
                
                    // Parse signupDate
                    if(item.signupDate){
                        let rawDateSplit = item.signupDate.split("T");
                        let dateSplit = rawDateSplit[0].split("-");
                        let timeSplit = rawDateSplit[1].split(":");
                        item.signupDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0] + " " + timeSplit[0] + ":" + timeSplit[1];
                        }
                        if (item.nonMember && (item.nonMember.company == "null" || item.nonMember.company == "")) {
                            item.nonMember.company = null;
                        }
                        if (item.user && (item.user.company == "null" || item.user.company == "")) {
                            item.user.company = null;
                        }
                        if(item.nonMember && (item.nonMember.industry=="null"||item.nonMember.industry=="")){
                            item.nonMember.industry=null
                        }
                        if (item.user && (item.user.industry == "null" || item.user.industry == "")) {
                            item.user.industry = null;
                        }                
                           
                }
                let attendance={}
                let startDate=new Date(response.data.currentOfflineEvent.startDate)
                response.data.currentOfflineEvent.eventDateObj=startDate.setHours(0,0,0)
                let endDate=new Date(response.data.currentOfflineEvent.endDate)
                response.data.currentOfflineEvent.endDateObj=endDate.setHours(23,59,59)
                let currentDate=startDate
                while(currentDate<endDate){
                    attendance[""+currentDate.getDate()]=0
                    currentDate.setDate(currentDate.getDate() + 1)
                }
                response.data.currentOfflineEvent.attendance=attendance
                 
                this.setState({
                    loading:false,
                    participantList:response.data.nameList,
                    currentOfflineEvent:response.data.currentOfflineEvent,
                    
                })  
                
                
            })

            .catch(error => {
                if(error.response.status === 409){
                    alert("Not verified to view")
                    history.push("./")
                    return
                }
                alert("Load class background info error\n" + error)
                this.setState({loading: false,})
            })
    }


    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }
                <Modal isOpen={this.state.editBackgroundModal} toggle={()=>this.setState({editBackgroundModal:false})} style={{}} returnFocusAfterClose={false} onClosed={()=>this.handleModalClose()}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Edit Background Info</div>
                        <textarea style={{padding:10,width:"100%",border:"1px solid lightgrey",borderRadius:5}} rows={7} value={this.state.editMemberBackground} onChange={(e)=>{
                            this.setState({editMemberBackground:e.target.value})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1,}} onClick={()=>{
                                this.saveClientBackground()
                            }}>
                                Save
                            </Button>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({editBackgroundModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
              

                <NavigationBar currentPage="clientbackground"/>
                
                    {
                    window.innerWidth >= 600 ?
                        <div style={{width:"100%", justifyContent:"center", alignItems:"center",display:"flex",padding:"0px 2px 0px 2px",fontSize:"14px"}}>  {/*desktopView*/}
                            <div style={{width:"100%"}}>
                                <div style={{width:"100%",padding:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"column",gap:10}}>
                            
                                    <div style={{gap:5,display:"flex",flexDirection:"column",width:"100%", justifyContent:"center",alignItems:"center"}}>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",marginBottom:2, marginTop:2}}>
                                            <Input type="text" name="username" placeholder="name / email / phone number / company name "
                                                style={{marginRight:10}}
                                                value={this.state.findField}
                                                onChange={event => this.setState({findField: event.target.value})}
                                                onKeyDown={(e)=>{
                                                    if(e.keyCode==13){
                                                        e.preventDefault()
                                                        this.searchMember()
                                                    }
                                                }}
                                                />
                                            <Button color="danger" style={{paddingLeft:20,paddingRight:20,whiteSpace:"nowrap",marginRight:5}} onClick={()=>{this.setState({findField:"", searching:false})}}>Clear Query</Button>
                                            <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{this.searchMember()}}>Search</Button>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"row",flexShrink:0, flexFlow:"row",flexWrap:"wrap", gap:10,alignItems:"center", justifyContent:"center"}}>
                                            {!this.state.searching? //desktop full list view
                                                this.state.participantList.map((item)=>{
                                                    return(
                                                        <div style={{display:"flex",flexDirection:"column",width:"400px", border:"1px solid lightgrey",borderRadius:10,padding:5,gap:5}}>
                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                <div style={{display:"flex", flexDirection:"row", gap:5,width:"100%"}}>
                                                                    <div style={{width:"30%",display:"flex",flexDirection:"column",gap:10,alignItems:"center", justifyContent:"center"}}>
            
                                                                        <div style={{width:"85%"}}>
                                                                            <div>  
                                                                                <img style={{width: "95px",height:"95px", objectFit:"cover",borderRadius: "50%",backgroundColor: "grey"}} src={item.user!=null?(item.user.profileImage||defaultProfile):(defaultProfile)} alt=""/>
            
                                                                            </div>
                                                                        </div>
                                                                        
            
                                                                        <div style={{textAlign:"center",width:"85%"}}>
                                                                                {
                                                                                item.user?
                                                                                    <div style={{fontSize:10,color:"white",backgroundColor:"green",borderRadius:5,padding:"2px 15px"}}>Member</div>
                                                                                    :
                                                                                    <div style={{fontSize:10,color:"white",backgroundColor:"grey",borderRadius:5,padding:"2px 15px"}}>Non Member</div>
                                                                                }
                                                                        </div>

                                                                        <div style={{width:"85%",textAlign:"center"}}>
                                                                            <div><b>Attendance:</b></div>
                                                                            {this.generateAttendanceUI(item)}

                                                                        </div>
                                                                        
                                                                    </div>
            
                                                                    <div style={{width:"70%", wordWrap:"break-word",wordBreak:"break-word", overflowWrap:"break-word", flexFlow:"column", flexGrow:0,flexShrink:0,lineHeight:1.25,gap:2,display:"flex"}}>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div><b>Name:</b><br></br>{item.user?item.user.name:item.nonMember.name}</div>

                                                                            </div> 
            
                                                                        </div>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div><b>E-mail:</b><br></br>{item.user?item.user.email:item.nonMember.email}</div>
                                                                                                                                                        
                                                                            </div> 
            
                                                                        </div>
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div style={{width:"50%"}}><b>Company:</b><br></br>{item.user?(item.user.company?item.user.company:"-"):(item.nonMember.company?item.nonMember.company:"-")}</div>
                                                                                <div style={{width:"50%"}}><b>Industry:</b><br></br>{item.user?(item.user.industry?item.user.industry:"-"):(item.nonMember.industry?item.nonMember.industry:"-")}</div>
                                                                                                                                                        
                                                                            </div> 
            
                                                                        </div>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div style={{width:"50%"}}><b>Phone Num:</b><br></br>{item.user?item.user.username:item.nonMember.phone}</div>
                                                                                <div style={{width:"50%"}}><b>Sign Up Date:</b><br></br>{item.signupDate}</div>

            
                                                                            </div> 
            
                                                                        </div>
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"column",gap:5}}>
            
                                                                            <div style={{display:"flex",flexDirection:"row" ,alignItems:"center"}}>
                                                                                <div style={{width:"50%"}}><b>Referrer:</b><br></br> {item.user? 
                                                                                                                                            item.user.referrer 
                                                                                                                                               : 
                                                                                                                                               item.nonMemberReferrer? item.nonMemberReferrer 
                                                                                                                                               : 
                                                                                                                                               item.nonMemberAgentCompany? item.nonMemberAgentCompany 
                                                                                                                                               : 
                                                                                                                                               "-"}</div>
                                                                                <div style={{width:"50%"}}><b>PIC:</b><br></br>{item.user?item.user.personInCharge:"-"}</div>
            
                                                                            </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div onFocus={() => this.setState({ focusStates: { ...this.state.focusStates, [item.id]: true } })} onBlur={() => this.closeCollapse(item.id)}  ref={(element) => this.setDivRef(item.id, element)} >
                                                                <Button color="secondary" onClick={(event)=> this.toggle(item.id)} size="lg"style={{width:"100%", lineHeight:2,paddingLeft:"5px",paddingRight:"5px", fontSize:"14px",gap:5,display:"flex"}}  >
                                                                    <Collapse   isOpen={this.state.focusStates?.[item.id]||this.state.collapseStates[item.id] }style={{ width: "100%" }}>                                                               
                                                                        <div style={{display:"flex", gap:5, flexDirection:"column"}}>
                                                                            <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center",gap:5}}>

                                                                                <div style={{width:"25%",display:"flex",flexDirection:"column",textAlign:"end"}}>
                                                                                <b>Remark:</b>
                                                                                </div>

                                                                                <textarea type="text" style={{width:"75%",lineHeight:1,border:"1px solid lightgrey",borderRadius:5,padding:"5px 10px"}} value={item.remark!=null?item.remark:""} rows="2"
                                                                                    onChange={(e)=>{
                                                                                        e.stopPropagation();

                                                                                        let cloneCSD=this.state.participantList
                                                                                        for(let cs of cloneCSD){
                                                                                            if(cs.id==item.id){
                                                                                                cs.remark=e.target.value
                                                                                            }
                                                                                        }
                                                                                        this.setState({participantList:cloneCSD})
                                                                                    }}
                                                                                    onKeyDown={(e)=>{
                                                                                        if (e.key === ' ' || e.key === 'Space') {
                                                                                            // Only allow space and not more function of spacebar
                                                                                            e.preventDefault();
                                                                                            document.execCommand('insertText', false, ' ');
                                                                                            
                                                                                            return;
                                                                                          
                                                                                        }
                                                                                    
                                                                                        if(e.keyCode==13){
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            this.saveRemark(item.ticketNumber,e.target.value);
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e)=>{
                                                                                        if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                                            return
                                                                                        }

                                                                                        this.saveRemark(item.ticketNumber,e.target.value)

                                                                                    }}
                                                                                    onClick={(e)=>{
                                                                                        e.stopPropagation()

                                                                                    }}
                                                                                    readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                                                />
                                                                                </div>


                                                                                <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row",gap:5}}>
                                                                                                                                                                        

                                                                                    <div style={{width:"25%",display:"flex",flexDirection:"column",paddingTop:"1px",gap:2}}>
                                                                                        <div style={{alignSelf:"flex-end"}}><b>Background:</b></div>
                                                                                        {
                                                                                        GLOBAL.checkPermission("4~C$$mtS")?
                                                                                            <Button color="primary" style={{alignSelf:"flex-end",padding:"1px 10px"}} onClick={(e) => this.handleEditClick(e, item)}  onBlur={(e) => {
                                                                                                e.stopPropagation(); 
                                                                                            }} >
                                                                                                Edit
                                                                                            </Button>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>

                                                                                    <div style={{width:"75%",display:"flex",flexDirection:"column",border:"1px solid lightgrey",borderRadius:5,lineHeight:1, background:"white", color:"black",alignItems:"flex-start",padding:"5px 10px"}}>
                                                                                        {
                                                                                        item.user?(
                                                                                            item.user.background?item.user.background.split("\n").map((line)=>{return(<div>{line}</div>)}):null
                                                                                        ):(item.nonMember.background?item.nonMember.background.split("\n").map((line)=>{return(<div>{line}</div>)}):null)
                                                                                        

                                                                                    }
                                                                                </div>      
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>
                                                                </Button>                
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : //desktop search view
                                                this.state.searchedList.map((item)=>{
                                                    return(
                                                        <div style={{display:"flex",flexDirection:"column",width:"400px", border:"1px solid lightgrey",borderRadius:10,padding:5,gap:5}}>
                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                <div style={{display:"flex", flexDirection:"row", gap:5,width:"100%"}}>
                                                                    <div style={{width:"30%",display:"flex",flexDirection:"column",gap:10,alignItems:"center", justifyContent:"center"}}>
            
                                                                        <div style={{width:"85%"}}>
                                                                            <div>  
                                                                                <img style={{width: "95px",height:"95px", objectFit:"cover",borderRadius: "50%",backgroundColor: "grey"}} src={item.user!=null?(item.user.profileImage||defaultProfile):(defaultProfile)} alt=""/>
            
                                                                            </div>
                                                                        </div>
                                                                        
            
                                                                        <div style={{textAlign:"center",width:"85%"}}>
                                                                                {
                                                                                item.user?
                                                                                    <div style={{fontSize:10,color:"white",backgroundColor:"green",borderRadius:5,padding:"2px 15px"}}>Member</div>
                                                                                    :
                                                                                    <div style={{fontSize:10,color:"white",backgroundColor:"grey",borderRadius:5,padding:"2px 15px"}}>Non Member</div>
                                                                                }
                                                                        </div>

                                                                        <div style={{width:"85%",textAlign:"center"}}>
                                                                            <div><b>Attendance:</b></div>
                                                                            {this.generateAttendanceUI(item)}

                                                                        </div>
                                                                        
                                                                    </div>
            
                                                                    <div style={{width:"70%", wordWrap:"break-word",wordBreak:"break-word", overflowWrap:"break-word", flexFlow:"column", flexGrow:0,flexShrink:0,lineHeight:1.25,gap:2,display:"flex"}}>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div><b>Name:</b><br></br>{item.user?item.user.name:item.nonMember.name}</div>

                                                                            </div> 
            
                                                                        </div>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div><b>E-mail:</b><br></br>{item.user?item.user.email:item.nonMember.email}</div>
                                                                                                                                                        
                                                                            </div> 
            
                                                                        </div>
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div style={{width:"50%"}}><b>Company:</b><br></br>{item.user?(item.user.company?item.user.company:"-"):(item.nonMember.company?item.nonMember.company:"-")}</div>
                                                                                <div style={{width:"50%"}}><b>Industry:</b><br></br>{item.user?(item.user.industry?item.user.industry:"-"):(item.nonMember.industry?item.nonMember.industry:"-")}</div>
                                                       
                                                                            </div> 
            
                                                                        </div>
            
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
            
                                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                                <div style={{width:"50%"}}><b>Phone Num:</b><br></br>{item.user?item.user.username:item.nonMember.phone}</div>
                                                                                <div style={{width:"50%"}}><b>Sign Up Date:</b><br></br>{item.signupDate}</div>
            
                                                                            </div> 
            
                                                                        </div>
                                                                        <div style={{width:"100%",display:"flex",flexDirection:"column",gap:5}}>
            
                                                                            <div style={{display:"flex",flexDirection:"row" ,alignItems:"center"}}>
                                                                                    <div style={{width:"50%"}}><b>Referrer:</b><br></br> {item.user? 
                                                                                                                                                item.user.referrer 
                                                                                                                                                : 
                                                                                                                                                item.nonMemberReferrer? item.nonMemberReferrer 
                                                                                                                                                : 
                                                                                                                                                item.nonMemberAgentCompany? item.nonMemberAgentCompany 
                                                                                                                                                : 
                                                                                                                                                "-"}</div>
                                                                                    <div style={{width:"50%"}}><b>PIC:</b><br></br>{item.user?item.user.personInCharge:"-"}</div>
                
                                                                                </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div onFocus={() => this.setState({ focusStates: { ...this.state.focusStates, [item.id]: true } })} onBlur={() => this.closeCollapse(item.id)}  ref={(element) => this.setDivRef(item.id, element)}  >
                                                                <Button color="secondary" onClick={()=> this.toggle(item.id)} size="lg"style={{width:"100%", lineHeight:2,paddingLeft:"5px",paddingRight:"5px", fontSize:"14px",gap:5,display:"flex"}} >
                                                                    <Collapse   isOpen={this.state.focusStates?.[item.id]||this.state.collapseStates[item.id] }style={{ width: "100%" }}>                                                               
                                                                        <div style={{display:"flex", gap:5, flexDirection:"column"}}>
                                                                        <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center",gap:5}}>

                                                                            <div style={{width:"25%",display:"flex",flexDirection:"column",textAlign:"end"}}>
                                                                            <b>Remark:</b>
                                                                            </div>

                                                                            <textarea type="text" style={{width:"75%",lineHeight:1,border:"1px solid lightgrey",borderRadius:5,padding:"5px 10px"}} value={item.remark!=null?item.remark:""} rows="2"
                                                                                onChange={(e)=>{
                                                                                    e.stopPropagation()

                                                                                    let cloneCSD=this.state.participantList
                                                                                    for(let cs of cloneCSD){
                                                                                        if(cs.id==item.id){
                                                                                            cs.remark=e.target.value
                                                                                        }
                                                                                    }
                                                                                    this.setState({participantList:cloneCSD})
                                                                                }}
                                                                                onKeyDown={(e)=>{
                                                                                    if (e.key === ' ' || e.key === 'Space') {
                                                                                        // Only allow space and not more function of spacebar
                                                                                        e.preventDefault();
                                                                                        document.execCommand('insertText', false, ' ');
                                                                                        
                                                                                        return;
                                                                                      
                                                                                    }
                                                                                
                                                                                    if(e.keyCode==13){
                                                                                        e.preventDefault()
                                                                                        e.stopPropagation()
                                                                                        this.saveRemark(item.ticketNumber,e.target.value)
                                                                                    }
                                                                                }}
                                                                                onBlur={(e)=>{
                                                                                    if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                                        return
                                                                                    }

                                                                                    this.saveRemark(item.ticketNumber,e.target.value)

                                                                                }}
                                                                                onClick={(e)=>{
                                                                                    e.stopPropagation()

                                                                                }}

                                                                                readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                                            />
                                                                            </div>


                                                                            <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row",gap:5}}>
                                                                                                                                                                    

                                                                                <div style={{width:"25%",display:"flex",flexDirection:"column",paddingTop:"1px",gap:2}}>
                                                                                    <div style={{alignSelf:"flex-end"}}><b>Background:</b></div>
                                                                                    {
                                                                                    GLOBAL.checkPermission("4~C$$mtS")?
                                                                                        <Button color="primary" style={{alignSelf:"flex-end",padding:"1px 10px"}} onClick={(e) => this.handleEditClick(e, item)}  onBlur={(e) => {
                                                                                            e.stopPropagation(); 
                                                                                          }} >
                                                                                            Edit
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </div>

                                                                                <div style={{width:"75%",display:"flex",flexDirection:"column",border:"1px solid lightgrey",borderRadius:5,lineHeight:1, background:"white", color:"black",alignItems:"flex-start",padding:"6px 12px"}}>
                                                                                {
                                                                                        item.user?(
                                                                                            item.user.background?item.user.background.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                                                            :null
                                                                                        ):(item.nonMember.background?item.nonMember.background.split("\n").map((line)=>{return(<div>{line}</div>)}):null)

                                                                                    }
                                                                                </div>      
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>
                                                                </Button>                
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{width:"100%"}}> {/*mobileView*/}
                            <div style={{width:"100%",padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"column",gap:5,fontSize:"15px"}}>
                                        <div style={{width:"100%",display:"flex",flexDirection:"column",padding:5,gap:10}}>
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                            <Input type="text" name="username" placeholder="name / email / phone number / company name "
                                                style={{}}
                                                value={this.state.findField}
                                                onChange={event => this.setState({findField: event.target.value})}
                                                onKeyDown={(e)=>{
                                                    if(e.keyCode==13){
                                                        e.preventDefault()
                                                        this.searchMember()
                                                    }
                                                }}
                                                />
                                                </div>
                                                <div style={{display:"flex", flexDirection:"row",gap:10}}>
                                                <Button color="danger" style={{paddingLeft:20,paddingRight:20,whiteSpace:"nowrap"}} onClick={()=>{this.setState({findField:"", searching:false})}}>Clear Query</Button>

                                                <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{this.searchMember()}}>Search</Button>
        


                                                </div>
                                            
                                        </div>
                                <div style={{gap:7,display:"flex",flexDirection:"column",width:"100%"}}>
                                    {!this.state.searching? //mobile full view
                                        this.state.participantList.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"column",width:"100%",border:"1px solid lightgrey",borderRadius:10, position:"relative",padding:5,gap:5}}>
                                                    
                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                        <div style={{width:"30%",display:"flex",flexDirection:"column",gap:10,alignItems:"center", justifyContent:"center"}}>

                                                            <div style={{width:"85%"}}>
                                                                <div>  
                                                                    <img style={{width: "100%",borderRadius: "50%",backgroundColor: "grey"}} src={item.user!=null?(item.user.profileImage||defaultProfile):(defaultProfile)} alt=""/>

                                                                </div>
                                                            </div>
                                                            

                                                            <div style={{textAlign:"center",width:"85%"}}>
                                                                    {
                                                                    item.user?
                                                                        <div style={{fontSize:9,color:"white",backgroundColor:"green",borderRadius:5,padding:"2px 15px"}}>Member</div>
                                                                        :
                                                                        <div style={{fontSize:9,color:"white",backgroundColor:"grey",borderRadius:5,padding:"2px 15px"}}>Non Member</div>
                                                                    }
                                                            </div>

                                                            <div style={{width:"85%",textAlign:"center"}}>
                                                                <div><b>Attendance:</b></div>
                                                                {this.generateAttendanceUI(item)}

                                                            </div>
                                                            
                                                        </div>

                                                        <div style={{width:"70%", wordWrap:"break-word",wordBreak:"break-word", overflowWrap:"break-word", flexFlow:"column", flexGrow:0,flexShrink:0,lineHeight:1.25,gap:2,display:"flex"}}>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div><b>Name:</b><br></br>{item.user?item.user.name:item.nonMember.name}</div>
                                                                </div> 

                                                            </div>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div><b>E-mail:</b><br></br>{item.user?item.user.email:item.nonMember.email}</div>
                                                                                                                                            
                                                                </div> 

                                                            </div>
                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Company:</b><br></br>{item.user?(item.user.company?item.user.company:"-"):(item.nonMember.company?item.nonMember.company:"-")}</div>
                                                                    <div style={{width:"50%"}}><b>Industry:</b><br></br>{item.user?(item.user.industry?item.user.industry:"-"):(item.nonMember.industry?item.nonMember.industry:"-")}</div>

                                                                                                                                            
                                                                </div> 

                                                            </div>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Phone Num:</b><br></br>{item.user?item.user.username:item.nonMember.phone}</div>
                                                                    <div style={{width:"50%"}}><b>Sign Up Date:</b><br></br>{item.signupDate}</div>

                                                                </div> 

                                                            </div>
                                                            <div style={{width:"100%",display:"flex",flexDirection:"column",gap:5}}>

                                                                <div style={{display:"flex",flexDirection:"row" ,alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Referrer:</b><br></br> {item.user? 
                                                                                                                                item.user.referrer 
                                                                                                                                : 
                                                                                                                                item.nonMemberReferrer? item.nonMemberReferrer 
                                                                                                                                : 
                                                                                                                                item.nonMemberAgentCompany? item.nonMemberAgentCompany 
                                                                                                                                : 
                                                                                                                                "-"}</div>
                                                                    <div style={{width:"50%"}}><b>PIC:</b><br></br>{item.user?item.user.personInCharge:"-"}</div>

                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div onFocus={() => this.setState({ focusStates: { ...this.state.focusStates, [item.id]: true } })} onBlur={() => this.closeCollapse(item.id)}  ref={(element) => this.setDivRef(item.id, element)}  >
                                                        <Button color="secondary" onClick={()=> this.toggle(item.id)} size="lg"style={{width:"100%", lineHeight:2,paddingLeft:"5px",paddingRight:"5px", fontSize:"14px",gap:5,display:"flex"}} >
                                                            <Collapse   isOpen={this.state.focusStates?.[item.id]||this.state.collapseStates[item.id] }style={{ width: "100%" }}>                                                               
                                                                <div style={{display:"flex", gap:5, flexDirection:"column"}}>
                                                                <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center",gap:5}}>

                                                                    <div style={{width:"30%",display:"flex",flexDirection:"column",textAlign:"end"}}>
                                                                    <b>Remark:</b>
                                                                    </div>

                                                                    <textarea type="text" style={{width:"70%",lineHeight:1,border:"1px solid lightgrey",borderRadius:5,padding:"5px 10px"}} value={item.remark!=null?item.remark:""} rows="2"
                                                                        onChange={(e)=>{
                                                                            e.stopPropagation()

                                                                            let cloneCSD=this.state.participantList
                                                                            for(let cs of cloneCSD){
                                                                                if(cs.id==item.id){
                                                                                    cs.remark=e.target.value
                                                                                }
                                                                            }
                                                                            this.setState({participantList:cloneCSD})
                                                                        }}
                                                                        onKeyDown={(e)=>{
                                                                            if (e.key === ' ' || e.key === 'Space') {
                                                                                // Only allow space and not more function of spacebar
                                                                                e.preventDefault();
                                                                                document.execCommand('insertText', false, ' ');
                                                                                
                                                                                return;
                                                                              
                                                                            }
                                                                        
                                                                            if(e.keyCode==13){
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                this.saveRemark(item.ticketNumber,e.target.value)
                                                                            }
                                                                        }}
                                                                        onBlur={(e)=>{
                                                                            if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                                return
                                                                            }

                                                                            this.saveRemark(item.ticketNumber,e.target.value)

                                                                        }}
                                                                        onClick={(e)=>{
                                                                            e.stopPropagation()

                                                                        }}

                                                                        readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                                    />
                                                                    </div>


                                                                    <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row",gap:5}}>
                                                                                                                                                            

                                                                        <div style={{width:"30%",display:"flex",flexDirection:"column",paddingTop:"1px",gap:2}}>
                                                                            <div style={{alignSelf:"flex-end"}}><b>Background:</b></div>
                                                                            {
                                                                            GLOBAL.checkPermission("4~C$$mtS")?
                                                                                <Button color="primary" style={{alignSelf:"flex-end",padding:"1px 10px"}} onClick={(e) => this.handleEditClick(e, item)}  onBlur={(e) => {
                                                                                    e.stopPropagation(); 
                                                                                    }} >
                                                                                    Edit
                                                                                </Button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>

                                                                        <div style={{width:"70%",display:"flex",flexDirection:"column",border:"1px solid lightgrey",borderRadius:5,lineHeight:1, background:"white", color:"black",alignItems:"flex-start",padding:"6px 12px"}}>
                                                                            {
                                                                            item.user?(
                                                                                item.user.background?item.user.background.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                                                :null
                                                                            ):(item.nonMember.background?item.nonMember.background.split("\n").map((line)=>{return(<div>{line}</div>)}):null)

                                                                            }
                                                                        </div>      
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </Button>                
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : //mobile search view
                                        this.state.searchedList.map((item)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"column",fontSize:"15px",width:"100%",border:"1px solid lightgrey",borderRadius:10, position:"relative",padding:5,gap:5}}>
                                                    
                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>

                                                        <div style={{width:"30%",display:"flex",flexDirection:"column",gap:10,alignItems:"center", justifyContent:"center"}}>

                                                            <div style={{width:"85%"}}>
                                                                <div>  
                                                                    <img style={{width: "100%",borderRadius: "50%",backgroundColor: "grey"}} src={item.user!=null?(item.user.profileImage||defaultProfile):(defaultProfile)} alt=""/>

                                                                </div>
                                                            </div>
                                                            

                                                            <div style={{textAlign:"center",width:"85%"}}>
                                                                    {
                                                                    item.user?
                                                                        <div style={{fontSize:9,color:"white",backgroundColor:"green",borderRadius:5,padding:"2px 15px"}}>Member</div>
                                                                        :
                                                                        <div style={{fontSize:9,color:"white",backgroundColor:"grey",borderRadius:5,padding:"2px 15px"}}>Non Member</div>
                                                                    }
                                                            </div>
                                                            
                                                            <div style={{width:"85%",textAlign:"center"}}>
                                                                <div><b>Attendance:</b></div>
                                                                {this.generateAttendanceUI(item)}

                                                            </div>
                                                            
                                                        </div>

                                                        <div style={{width:"70%", wordWrap:"break-word",wordBreak:"break-word", overflowWrap:"break-word", flexFlow:"column", flexGrow:0,flexShrink:0,lineHeight:1.25,gap:2,display:"flex"}}>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div><b>Name:</b><br></br>{item.user?item.user.name:item.nonMember.name}</div>
                                                                    
                                                                </div> 

                                                            </div>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div><b>E-mail:</b><br></br>{item.user?item.user.email:item.nonMember.email}</div>
                                                                                                                                            
                                                                </div> 

                                                            </div>
                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Company:</b><br></br>{item.user?(item.user.company?item.user.company:"-"):(item.nonMember.company?item.nonMember.company:"-")}</div>
                                                                    <div style={{width:"50%"}}><b>Industry:</b><br></br>{item.user?(item.user.industry?item.user.industry:"-"):(item.nonMember.industry?item.nonMember.industry:"-")}</div>
                                                             
                                                                </div> 

                                                            </div>

                                                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>

                                                                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Phone Num:</b><br></br>{item.user?item.user.username:item.nonMember.phone}</div>
                                                                    <div style={{width:"50%"}}><b>Sign Up Date:</b><br></br>{item.signupDate}</div>

                                                                </div> 

                                                            </div>
                                                            <div style={{width:"100%",display:"flex",flexDirection:"column",gap:5}}>

                                                                <div style={{display:"flex",flexDirection:"row" ,alignItems:"center"}}>
                                                                    <div style={{width:"50%"}}><b>Referrer:</b><br></br> {item.user? 
                                                                                                                                item.user.referrer 
                                                                                                                                    : 
                                                                                                                                    item.nonMemberReferrer? item.nonMemberReferrer 
                                                                                                                                    : 
                                                                                                                                    item.nonMemberAgentCompany? item.nonMemberAgentCompany 
                                                                                                                                    : 
                                                                                                                                    "-"}</div>
                                                                    <div style={{width:"50%"}}><b>PIC:</b><br></br>{item.user?item.user.personInCharge:"-"}</div>

                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div onFocus={() => this.setState({ focusStates: { ...this.state.focusStates, [item.id]: true } })} onBlur={() => this.closeCollapse(item.id)}  ref={(element) => this.setDivRef(item.id, element)}  >
                                                        <Button color="secondary" onClick={()=> this.toggle(item.id)} size="lg"style={{width:"100%", lineHeight:2,paddingLeft:"5px",paddingRight:"5px", fontSize:"14px",gap:5,display:"flex"}} >
                                                            <Collapse   isOpen={this.state.focusStates?.[item.id]||this.state.collapseStates[item.id] }style={{ width: "100%" }}>                                                               
                                                                <div style={{display:"flex", gap:5, flexDirection:"column"}}>
                                                                <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center",gap:5}}>

                                                                    <div style={{width:"30%",display:"flex",flexDirection:"column",textAlign:"end"}}>
                                                                    <b>Remark:</b>
                                                                    </div>

                                                                    <textarea type="text" style={{width:"70%",lineHeight:1,border:"1px solid lightgrey",borderRadius:5,padding:"5px 10px"}} value={item.remark!=null?item.remark:""} rows="2"
                                                                        onChange={(e)=>{
                                                                            e.stopPropagation()

                                                                            let cloneCSD=this.state.participantList
                                                                            for(let cs of cloneCSD){
                                                                                if(cs.id==item.id){
                                                                                    cs.remark=e.target.value
                                                                                }
                                                                            }
                                                                            this.setState({participantList:cloneCSD})
                                                                        }}
                                                                        onKeyDown={(e)=>{
                                                                            if (e.key === ' ' || e.key === 'Space') {
                                                                                // Only allow space and not more function of spacebar
                                                                                e.preventDefault();
                                                                                document.execCommand('insertText', false, ' ');
                                                                                
                                                                                return;
                                                                              
                                                                            }
                                                                        
                                                                            if(e.keyCode==13){
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                this.saveRemark(item.ticketNumber,e.target.value)
                                                                            }
                                                                        }}
                                                                        onBlur={(e)=>{
                                                                            if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                                return
                                                                            }

                                                                            this.saveRemark(item.ticketNumber,e.target.value)

                                                                        }}
                                                                        onClick={(e)=>{
                                                                            e.stopPropagation()

                                                                        }}

                                                                        readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                                    />
                                                                    </div>


                                                                    <div style={{width:"100%",flex:1,display:"flex",flexDirection:"row",gap:5}}>
                                                                                                                                                            

                                                                        <div style={{width:"30%",display:"flex",flexDirection:"column",paddingTop:"1px",gap:2}}>
                                                                            <div style={{alignSelf:"flex-end"}}><b>Background:</b></div>
                                                                            {
                                                                            GLOBAL.checkPermission("4~C$$mtS")?
                                                                                <Button color="primary" style={{alignSelf:"flex-end",padding:"1px 10px"}} onClick={(e) => this.handleEditClick(e, item)}  onBlur={(e) => {
                                                                                    e.stopPropagation(); 
                                                                                    }} >
                                                                                    Edit
                                                                                </Button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>

                                                                        <div style={{width:"70%",display:"flex",flexDirection:"column",border:"1px solid lightgrey",borderRadius:5,lineHeight:1, background:"white", color:"black",alignItems:"flex-start",padding:"6px 12px"}}>
                                                                            {
                                                                            item.user?(
                                                                                item.user.background?item.user.background.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                                                :null
                                                                            ):(item.nonMember.background?item.nonMember.background.split("\n").map((line)=>{return(<div>{line}</div>)}):null)

                                                                            }
                                                                        </div>      
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </Button>                
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
            </div>
        )
    }
}

let styles={

}
