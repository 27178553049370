import React from 'react'
import Radium from 'radium'
import {Input,Button,Alert,Row,Col,Table,Modal} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import * as GLOBAL from './global.js'
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'

export default class Execution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,

            modalFunction:"",
            infoMessage:"",
            contentMessage:"",
            inputField:[],
            inputValue:[],
            executionModal:false,
            invoice_detail:[],
            invoice_pdf: "",

            generateLinkWithTagModal:false,
            generateLinkWithTagLinkList:[
                "https://enlliance.com",
                "https://enlliance.com/system",
                "https://enlliance.com/conference",
                "https://enlliance.com/strategic",
                "https://enlliance.com/marketing",
                "https://enlliance.com/organization",
                "https://enlliance.com/signup",
            ],
            generateLinkWithTagTagList:[],
            selectedTagList:[],
            selectedLink:"https://enlliance.com",
            addTagModal:false,
            resultLink:"",

            agentWWalletListModal:false,
            agentWWalletList:[],
            downloadReleaseWWalletExcelModal:false,
            excelLink:null,

            confirmReleaseWWalletModal:false,
            uploadConfirmReleaseExcel:null,
            releaseWWalletLogList:[],
            agentWWalletReleasePage:1,
            selectedRWWL:null,

            checkedUser:null,
            checkedEmployee:null,
            buyCourseForClientModal:false,
            buyCourseInventoryList:[],
            buyCourseProductTypeList:[],
            buyCourseSelectedProduct:null,
            buyCourseItemPrice:null,
            buyCourseOverdraft:"No",
            selectProductToBuyModal:false,
            rWalletToUse:0,
            pWalletToUse:0,
            partialWalletToUse:0,
            cashToUse:0,
            predictedBeforeTax:0,
            predictedPromoValue:0,
            predictedTax:0,
            predictedAfterTax:0,
            paymentChecked:false,

            purchaseSignupModal:false,
            offlineEventTypeList:[],
            franchiseSessionList:[],
            franchisePurchaseSignupLink:null,
            selectedCourseTitle:null,
            selectedCourseSession:null,
            purchaseSignupPage:"phone",
            referCourseClientReferrer:null, //in the event the referred person is member and there is referrer already with the member
            referCourseClientName:null,
            referCourseClientPhone:null,
            referCourseClientEmail:null,
            referCourseClientBillingAddress:null,
            referCourseClientNameError:false,
            referCourseClientPhoneError:false,
            referCourseClientEmailError:false,

            editProductPriceModal:false,
            productPrice:null,

            agentCompanyList:[],
            askPhoneNum:"",
            askCompanyID:"",
            askTierID:"",
            combinedTierList:[],
            agentDetails:{},
            changeAgentCompanyTierModal:false,

            corporateAccountList:[],
            corporateAccountListModal:false,
            payerID:null,   //either checkeduser username or corporate account id
            targetID:null,  //if payer is corp, there will be corporate account staff id here
            payerName:null,
            payerType:"member",
            payerRWallet:null,
            payerPWallet:null,
            payerPrWallet:null,
            payerCWallet:null,
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }
    }

    validateAccess=()=>{
        //verify auth
        let theUsername=reactLocalStorage.get("username")
        if(theUsername!="01151772267"&&theUsername!="0168626883"&&theUsername!="0178100083"){  //khai, sherine, jaymie
            alert("Thou shall not pass")
            return false
        }
    }

    createAndSendRegistrationCode=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction:"createAndSendRegistrationCode",
            infoMessage:"Enter the new user's phone and email:",
            contentMessage:"",
            inputField:["Phone","Email"],
            inputValue:["",""],
            executionModal:true,
        })
    }

    createNewUser=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction:"createNewUser",
            infoMessage:"Create new user:",
            contentMessage:"",
            inputField:["*Username(phone number)","*Email","*Name","PIC/Referrer phone number"],
            inputValue:["","","",""],
            executionModal:true,
        })
    }

    setSPClaimReferral=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction:"setSPClaimReferral",
            infoMessage:"Set SP and Claim Referral:",
            contentMessage:"",
            inputField:["Username(phone number)"],
            inputValue:[""],
            executionModal:true,
        })
    }

    grantPromoCode=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction:"grantPromoCode",
            infoMessage:"Grant Promo Voucher",
            contentMessage:"",
            inputField:["$Promo&&入门课 开通券&&系统班 优惠券&&咨询班 代金券&&企业指南 减RM200&&战略52讲 减RM200","Username(Phone Number)","Value(RM)","Expiry Date e.g. DD/MM/YYYY"],
            inputValue:["入门课 开通券","","",""],
            executionModal:true,
        })
    }

    grantTicket=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction:"grantTicket",
            infoMessage:"Grant 系统管理 速成班 Ticket",
            contentMessage:"",
            inputField:["$Ticket&&系统管理 速成班","Username(Phone Number)"],
            inputValue:["系统管理 速成班",""],
            executionModal:true,
        })
    }

    grantBadge=()=>{
        if(!this.validateAccess){
            return
        }

        axios
            .get(`membership/v1/getbadgelist`,{timeout:15000})
            .then(async response => {
                let badgeString="$Badge"
                for(let badge of response.data){
                    badgeString+="&&"+badge.title
                }

                this.setState({
                    loading:false,
                    modalFunction:"grantBadge",
                    infoMessage:"Grant Badge",
                    contentMessage:"",
                    inputField:[badgeString,"Username(Phone Number)"],
                    inputValue:[response.data[0].title,""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Load badge list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    removeBadge=()=>{
        if(!this.validateAccess){
            return
        }

        axios
            .get(`membership/v1/getbadgelist`,{timeout:15000})
            .then(async response => {
                let badgeString="$Badge"
                for(let badge of response.data){
                    badgeString+="&&"+badge.title
                }

                this.setState({
                    loading:false,
                    modalFunction:"removeBadge",
                    infoMessage:"Remove Badge",
                    contentMessage:"",
                    inputField:[badgeString,"Username(Phone Number)"],
                    inputValue:[response.data[0].title,""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Load badge list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    grantCertificate=()=>{
        if(!this.validateAccess){
            return
        }

        axios
            .get(`membership/v1/getcertificatelist`,{timeout:15000})
            .then(async response => {
                let certString="$Certificate"
                for(let cert of response.data){
                    certString+="&&"+cert.title
                }

                this.setState({
                    loading:false,
                    modalFunction:"grantCertificate",
                    infoMessage:"Grant Certificate",
                    contentMessage:"",
                    inputField:[certString,"Username(Phone Number)"],
                    inputValue:[response.data[0].title,""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Load certificate list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    removeCertificate=()=>{
        if(!this.validateAccess){
            return
        }

        axios
            .get(`membership/v1/getcertificatelist`,{timeout:15000})
            .then(async response => {
                let certString="$Certificate"
                for(let cert of response.data){
                    certString+="&&"+cert.title
                }

                this.setState({
                    loading:false,
                    modalFunction:"removeCertificate",
                    infoMessage:"Remove Certificate",
                    contentMessage:"",
                    inputField:[certString,"Username(Phone Number)"],
                    inputValue:[response.data[0].title,""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Load certificate list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    //add money function
    addMoney = () => {
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "addMoney",
            infoMessage: "Add Money",
            contentMessage:"",
            inputField:["Referrer Phone Number", "Added Value", "$Agenda&Referral Bonus","$Items&VIP&战略52讲&企业指南&战略三力&营销三力&组织三力&模式三力", "Referred member phone number"],
            inputValue: ["", "", "Referral Bonus", "VIP",""],
            executionModal: true,
        })
    }

    addMoneyOnly = () => {
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "addMoneyOnly",
            infoMessage: "Add Money To R Wallet",
            contentMessage:"",
            inputField:["Username(Phone Number)", "Add Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    addMoneyToPWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "addMoneyToPWallet",
            infoMessage: "Add Money To P Wallet",
            contentMessage:"",
            inputField:["Agent Username(Phone Number)", "Add Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    addMoneyToPartialWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "addMoneyToPartialWallet",
            infoMessage: "Add Money To Partial Wallet (with Tax)\n**This action will distribute referral and commission**",
            contentMessage:"",
            inputField:["Username(Phone Number)", "Client paid amount,Tax inclusive,eg: RM106 for 100 wallet", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    addMoneyToCashWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "addMoneyToCashWallet",
            infoMessage: "Add Money To Cash Wallet",
            contentMessage:"",
            inputField:["Username(Phone Number)", "Add Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    //duduct money function
    deductMoney = () => {
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "deductMoney",
            infoMessage: "Deduct Money from R Wallet",
            contentMessage:"",
            inputField: ["Username(Phone Number)", "Deduct Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    deductMoneyFromPWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "deductMoneyFromPWallet",
            infoMessage: "Deduct Money from P Wallet",
            contentMessage:"",
            inputField: ["Username(Phone Number)", "Deduct Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    deductMoneyFromPartialWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "deductMoneyFromPartialWallet",
            infoMessage: "Deduct Money from Partial Wallet",
            contentMessage:"",
            inputField: ["Username(Phone Number)", "Deduct Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    deductMoneyFromCashWallet=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "deductMoneyFromCashWallet",
            infoMessage: "Deduct Money from Cash Wallet",
            contentMessage:"",
            inputField: ["Username(Phone Number)", "Deduct Value (eg: 50)", "Description"],
            inputValue: ["", "", ""],
            executionModal: true,
        })
    }

    generateLinkWithTag=()=>{
        if(!this.validateAccess){
            return
        }
        axios
            .get(`sales/gettaglist`,{timeout:15000})
            .then(async response => {
                this.setState({
                    generateLinkWithTagTagList:response.data,
                    generateLinkWithTagModal: true,
                })
            })
            .catch(error =>{
                alert("Generate link with tag error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    buyCourseForClientWithCredit1=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            loading:false,
            modalFunction:"buyCourseForClientWithCredit1",
            infoMessage:"Buy Course For Client",
            contentMessage:"",
            inputField:["Username(Phone Number)",],
            inputValue:["",],
            executionModal:true,
        })
    }
    getInventoryList=()=>{
        this.setState({loading:true})
        axios
            .get(`membership/v1/getinventorylist`,{timeout:15000})
            .then(async response => {
                this.setState({
                    loading:false,
                    selectProductToBuyModal:true,
                    buyCourseInventoryList:response.data.inventoryList,
                    buyCourseProductTypeList:response.data.productType,
                })
            })
            .catch(error =>{
                alert("Load inventory list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    checkCourseQuotation=()=>{
        this.setState({loading:true})
        let payload = {
            username: this.state.checkedUser.username,
            course: this.state.buyCourseSelectedProduct.sku,
        }
        if(this.state.payerType=="corp"){
            payload.corpID=this.state.payerID
            payload.targetID=this.state.targetID
        }
        axios
            .post(`sales/checkcoursequotation`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //combine the data
                response.data.onlineInvoice.appliedPromoVoucher=response.data.appliedPromoVoucher
                response.data.onlineInvoice.promoVoucherList=response.data.promoVoucherList
                this.setState({
                    loading: false,
                    theOnlineInvoice:response.data.onlineInvoice,
                    cashToUse:response.data.onlineInvoice.totalAfterTax,
                    productPrice:parseFloat(response.data.onlineInvoice.productList[0][2]).toFixed(2),
                    paymentChecked:true,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){ //already has the course
                        alert("Client already has the badge")
                        this.setState({loading: false,theOnlineInvoice:null,buyCourseSelectedProduct:null})
                        return
                    }else if(error.response.status==445){ //insufficient credit
                        let reportString="Insufficient credit only has R+Partial: "+error.response.data.pointRemain
                        if(error.response.data.commissionWalletRemain!=null&&error.response.data.overdraft=="Yes"){
                            reportString+=", P Wallet: "+error.response.data.commissionWalletRemain
                        }
                        reportString+=", require "+error.response.data.totalAfterTax
                        alert(reportString)
                        this.setState({loading: false})
                        return
                    }
                }
                alert("Check course quotation error\n" + error)
                this.setState({
                    loading: false,
                    executionModal: false
                })
            })
    }

    generateInvoice = () => {
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "generateInvoice",
            infoMessage: "Generate Invoice",
            contentMessage:"",
            inputField: ["Invoice Number"],
            inputValue: [""],
            executionModal: true
        })
    }

    validateAndGrantPendingInvoice=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "validateAndGrantPendingInvoice",
            infoMessage: "Validate and Grant Pending Invoice when user successfully made online payment but did not complete the process",
            contentMessage:"",
            inputField: ["Invoice Number"],
            inputValue: [""],
            executionModal: true
        })
    }

    validateEmail=(email)=>{
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    runModalFunction=()=>{
        if(this.state.modalFunction=="createNewUser"){
            this.setState({loading:true})
            //get the data from input fields
            let username=this.state.inputValue[0]
            let email=this.state.inputValue[1]
            //validate email
            if(!this.validateEmail(email)){
                alert("Invalid email address")
                this.setState({loading:false})
                return
            }
            let name=this.state.inputValue[2]
            let referrerPhone=this.state.inputValue[3]
            //send to server
            const payload = {
                username:username,
                email:email,
                name:name,
                referrerPhone:referrerPhone,
            }
            axios
                .post(`sales/createnewuserfromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Create new user success, email sent")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==501){
                            alert("Phone number already exists")
                        }else if(error.response.status==502){
                            alert("Email already exists")
                        }else if(error.response.status==503){
                            alert("Name already exists")
                        }else if(error.response.status==504){
                            alert("Referrer not found")
                        }else{
                            alert("Create new user error\n"+error)
                        }
                        this.setState({
                            loading:false,
                        })
                    }else{
                        alert("Create new user error\n"+error)
                        this.setState({
                            loading:false,
                        })
                    }
                })
        }else if(this.state.modalFunction=="releaseAgentCompanyCommission"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let paymentNumber=this.state.inputValue[1]
            let amount=this.state.inputValue[2]
            let description=this.state.inputValue[3]
            const payLoad = {
                companyName:companyName,
                paymentNumber:paymentNumber,
                amount:amount,
                description:description,
            }
            axios
                .post(`sales/releaseagentcompanycommission`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Release recorded")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==445){
                            alert("The company cannot be found")
                            this.setState({
                                loading:false,
                            })
                            return
                        }
                    }
                    alert("Release agent company commission error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="releaseAgentCompanyProfitSharing"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let paymentNumber=this.state.inputValue[1]
            let amount=this.state.inputValue[2]
            let description=this.state.inputValue[3]
            const payLoad = {
                companyName:companyName,
                paymentNumber:paymentNumber,
                amount:amount,
                description:description,
            }
            axios
                .post(`sales/releaseagentcompanyprofitsharing`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Release recorded")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==445){
                            alert("The company cannot be found")
                            this.setState({
                                loading:false,
                            })
                            return
                        }
                    }
                    alert("Release agent company profit sharing error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="setSPClaimReferral"){
            this.setState({loading:true})
            let username=this.state.inputValue[0]
            //send to server
            const payload = {
                username:username,
            }
            axios
                .post(`sales/setspclaimreferral`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Set SP and claim referral success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Set SP and claim referral error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="grantPromoCode"){
            this.setState({loading:true})
            //get phone and email
            let promo=this.state.inputValue[0]
            let username=this.state.inputValue[1]
            let value=this.state.inputValue[2]
            let expiryDate=this.state.inputValue[3]

            //send to server
            const payload = {
                promo:promo,
                username:username,
                value:value,
                expiryDate:expiryDate,
            }
            axios
                .post(`sales/grantpromocodefromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Grant Promo voucher success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Grant Promo voucher error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="grantTicket"){
            this.setState({loading:true})
            //get phone and email
            let ticket=this.state.inputValue[0]
            let username=this.state.inputValue[1]

            //send to server
            const payload = {
                ticket:ticket,
                username:username,
            }
            axios
                .post(`sales/grantticketfromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Grant 系统管理 速成班 Ticket success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Grant 系统管理 速成班 Ticket error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="grantBadge"){
            this.setState({loading:true})
            //get phone and email
            let badge=this.state.inputValue[0]
            let username=this.state.inputValue[1]
            //send to server
            const payload = {
                badge:badge,
                username:username,
            }
            axios
                .post(`sales/grantbadgefromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Grant badge success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Grant badge error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="removeBadge"){
            this.setState({loading:true})
            //get phone and email
            let badge=this.state.inputValue[0]
            let username=this.state.inputValue[1]
            //send to server
            const payload = {
                badge:badge,
                username:username,
            }
            axios
                .post(`sales/removebadgefromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Remove badge success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Remove badge error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="grantCertificate"){
            this.setState({loading:true})
            //get phone and email
            let certificate=this.state.inputValue[0]
            let username=this.state.inputValue[1]
            //send to server
            const payload = {
                certificate:certificate,
                username:username,
            }
            axios
                .post(`sales/grantcertificatefromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Grant certificate success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Grant certificate error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="removeCertificate"){
            this.setState({loading:true})
            //get phone and email
            let certificate=this.state.inputValue[0]
            let username=this.state.inputValue[1]
            //send to server
            const payload = {
                certificate:certificate,
                username:username,
            }
            axios
                .post(`sales/removecertificatefromsystem`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Remove certificate success")
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
                .catch(error =>{
                    alert("Remove certificate error\n"+error)
                    this.setState({
                        loading:false,
                        executionModal:false,
                    })
                })
        }else if(this.state.modalFunction=="addMoney") {
            this.setState({loading: true})
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get money
            let agenda = this.state.inputValue[2] //get agenda
            let items = this.state.inputValue[3] // get items
            let referedUser = this.state.inputValue[4] //get referral

            //send to server
            const payLoad = {
                username: username,
                value: value,
                agenda: agenda,
                items: items,
                referedUser: referedUser
            }
            axios
                .post(`sales/addmoneyfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money added")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Add money error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "addMoneyOnly") {
            this.setState({ loading:true })
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }

            axios
                .post(`sales/addmoneyonlyfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money Added to user R wallet")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Add Money to user R Wallet error")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "addMoneyToPWallet") {
            this.setState({ loading:true })
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }

            axios
                .post(`sales/addmoneytopwalletfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money Added to user P Wallet")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("This user is not an agent")
                            this.setState({
                                loading:false,
                                executionModal: false
                            })
                            return
                        }
                    }
                    alert("Add Money to P Wallet error")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "addMoneyToPartialWallet") {
            this.setState({ loading:true })
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }

            axios
                .post(`sales/addmoneytopartialwalletfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money Added to user Partial Wallet")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    if(error.response){

                    }
                    alert("Add Money to Partial Wallet error")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "addMoneyToCashWallet") {
            this.setState({ loading:true })
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }

            axios
                .post(`sales/addmoneytocashwalletfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money Added to user Cash Wallet")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    if(error.response){
                        
                    }
                    alert("Add Money to Cash Wallet error")
                    this.setState({
                        loading:false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "deductMoney") {
            this.setState({loading: true}) //get loading turn
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }
            axios
                .post(`sales/deductmoneyfromsystem`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money deducted")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Deduct money error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "deductMoneyFromPWallet") {
            this.setState({loading: true}) //get loading turn
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }
            axios
                .post(`sales/deductmoneyfrompwallet`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money deducted")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Deduct money error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "deductMoneyFromPartialWallet") {
            this.setState({loading: true}) //get loading turn
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }
            axios
                .post(`sales/deductmoneyfrompartialwallet`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money deducted")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Deduct money error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "deductMoneyFromCashWallet") {
            this.setState({loading: true}) //get loading turn
            let username = this.state.inputValue[0] //get username
            let value = this.state.inputValue[1] //get value
            let reason = this.state.inputValue[2] //get reason

            const payLoad = {
                username: username,
                value: value,
                reason: reason
            }
            axios
                .post(`sales/deductmoneyfromcashwallet`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Money deducted")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    alert("Deduct money error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction=="buyCourseForClientWithCredit1"){
            this.getUserWalletFromSystem()
        }else if(this.state.modalFunction == "changePIC") {
            this.setState({loading:true}) //get loading indicator
            let clientUsername = this.state.inputValue[0] //get title
            let caUsername = this.state.inputValue[1] //get email
            const payLoad = {
                clientUsername:clientUsername,
                caUsername:caUsername
            }
            axios
                .post(`sales/changepic`, qs.stringify(payLoad))
                .then(async response => {
                    alert("PIC changed")
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("The employee is not a Course Advisor...")
                            this.setState({
                                loading: false,
                                executionModal: false
                            })
                            return
                        }
                    }
                    alert("Change PIC error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false
                    })
                })
        }else if(this.state.modalFunction == "generateInvoice") {
            this.setState({loading:true}) //get loading indicator
            let invoiceNumber = this.state.inputValue[0] //get invoiceNumber

            const payLoad = {
                referenceNumber: invoiceNumber,
            }
            axios
                .post(`membership/v1/generateinvoicepdf`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Invoice link:\n"+response.data.link)
                    window.open(response.data.link, '_blank')
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    alert("Generate Invoice error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                });
        }else if(this.state.modalFunction=="grantCourseCredit"){
            this.setState({loading:true}) //get loading indicator
            let username = this.state.inputValue[0]
            let course=this.state.inputValue[1]
            const payLoad = {
                username: username,
                course:course,
            }
            axios
                .post(`sales/grantcoursecredit`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Course credit granted")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    alert("Grant course credit error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                });
        }else if(this.state.modalFunction=="createCorporateAccount"){
            let companyName=this.state.inputValue[0].trim()
            let companySSM=this.state.inputValue[1].trim()
            let companyAddress=this.state.inputValue[2].trim()
            let companyPrincipalUsername=this.state.inputValue[3].trim()
            //validate
            if(companyName==null||companyName==""){
                alert("Please enter company name")
                return
            }else if(companySSM==null||companySSM==""){
                alert("Please enter company ssm")
                return
            }else if(companyAddress==null||companyAddress==""){
                alert("Please enter company address")
                return
            }else if(companyPrincipalUsername==null||companyPrincipalUsername==""){
                alert("Please enter company principal username")
                return
            }

            this.setState({loading:true})
            const payLoad = {
                companyName:companyName,
                companySSM:companySSM,
                companyAddress:companyAddress,
                companyPrincipalUsername:companyPrincipalUsername,
            }
            axios
                .post(`sales/createcorporateaccount`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Corporate account successfully created")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("User does not exists\n")
                            this.setState({
                                loading:false,
                            })
                            return
                        }
                    }
                    alert("Create corporate account error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                });
        }else if(this.state.modalFunction=="openCorpAccForAgency"){
            let companyName=this.state.inputValue[0]
            let companyPrincipalUsername=this.state.inputValue[1].trim()
            let companyAddress=this.state.inputValue[2].trim()
            //validate
            if(companyPrincipalUsername==null||companyPrincipalUsername==""){
                alert("Please enter company principal username")
                return
            }else if(companyAddress==null||companyAddress==""){
                alert("Please enter company address")
                return
            }

            this.setState({loading:true})
            const payload = {
                companyName:companyName,
                companyPrincipalUsername:companyPrincipalUsername,
                companyAddress:companyAddress,
            }
            axios
                .post(`sales/opencorpaccforagency`, qs.stringify(payload))
                .then(async response => {
                    alert("Corporate account successfully opened")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        
                    }
                    alert("Open corporate account for agency error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="setExpiry"){
            this.setState({loading:true}) //get loading indicator
            let username = this.state.inputValue[0]
            let badge=this.state.inputValue[1]
            let expiryDate=this.state.inputValue[2]
            const payLoad = {
                username: username,
                badge:badge,
                expiryDate:expiryDate,
            }
            axios
                .post(`sales/setExpiryForMember`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Expiry date set")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    alert("Set expiry date error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                });
        }else if(this.state.modalFunction=="removeCourseCredit"){
            this.setState({loading:true}) //get loading indicator
            let username = this.state.inputValue[0]
            let course=this.state.inputValue[1]
            const payLoad = {
                username: username,
                course:course,
            }
            axios
                .post(`sales/removecoursecredit`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Course credit removed")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){ //do not have any of that course credit
                            alert("Failed, the user does not have any of that course credit")
                            this.setState({
                                loading: false,
                                executionModal: false,
                            })
                            return
                        }else if(error.response.status==445){   //invalid move, the user needs to retain that last credit
                            alert("Failed, the user needs to retain at least one of that credit to attend the course him/herself")
                            this.setState({
                                loading: false,
                                executionModal: false,
                            })
                            return
                        }
                    }
                    alert("Remove course credit error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                });
        }else if(this.state.modalFunction=="transferCourseCredit"){
            this.setState({loading:true}) //get loading indicator
            let fromUsername = this.state.inputValue[0]
            let toUsername=this.state.inputValue[1]
            let course=this.state.inputValue[2]
            const payLoad = {
                fromUsername:fromUsername,
                toUsername: toUsername,
                course:course,
            }
            axios
                .post(`sales/transfercoursecredit`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Course credit transferred")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){ //do not have any of that course credit
                            alert("Failed, the from user does not have any of that course credit")
                            this.setState({
                                loading: false,
                                executionModal: false,
                            })
                            return
                        }else if(error.response.status==445){   //invalid move, the user needs to retain that last credit
                            alert("Failed, the from user needs to retain at least one of that credit to attend the course him/herself")
                            this.setState({
                                loading: false,
                                executionModal: false,
                            })
                            return
                        }
                    }
                    alert("Transfer course credit error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="createServiceAgentCompany"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let ssmNumber=this.state.inputValue[1]
            let directorUsername=this.state.inputValue[2]
            let bankAccountName=this.state.inputValue[3]
            let bankAccountNumber=this.state.inputValue[4]
            let bankName=this.state.inputValue[5]
            let shortName=this.state.inputValue[6]
            let collaboratingClassList=JSON.stringify(this.state.inputValue[7])
            const payLoad = {
                companyName:companyName,
                ssmNumber:ssmNumber,
                directorUsername:directorUsername,
                bankAccountName:bankAccountName,
                bankAccountNumber:bankAccountNumber,
                bankName:bankName,
                shortName:shortName,
                collaboratingClassList:collaboratingClassList,
            }
            axios
                .post(`sales/createagentcompany`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Agent company created")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("Same company name already exists")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==445){
                            alert("Same ssm number already exists")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==446){
                            alert("The director account does not exist")
                            this.setState({
                                loading: false,
                            })
                            return
                        }
                    }
                    alert("Create service agent company error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="createReferralAgentCompany"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let ssmNumber=this.state.inputValue[1]
            let directorUsername=this.state.inputValue[2]
            let bankAccountName=this.state.inputValue[3]
            let bankAccountNumber=this.state.inputValue[4]
            let bankName=this.state.inputValue[5]
            let shortName=this.state.inputValue[6]
            let collaboratingClassList=JSON.stringify(this.state.inputValue[7])
            const payLoad = {
                companyName:companyName,
                ssmNumber:ssmNumber,
                directorUsername:directorUsername,
                bankAccountName:bankAccountName,
                bankAccountNumber:bankAccountNumber,
                bankName:bankName,
                shortName:shortName,
                collaboratingClassList:collaboratingClassList,
            }
            axios
                .post(`sales/createreferralagentcompany`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Referral agent company created")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("Same company name already exists")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==445){
                            alert("Same ssm number already exists")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==446){
                            alert("The director account does not exist")
                            this.setState({
                                loading: false,
                            })
                            return
                        }
                    }
                    alert("Create referral agent company error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="recalculateInvoiceCommission"){
            this.setState({loading:true}) //get loading indicator
            let invoiceNumber = this.state.inputValue[0]
            const payLoad = {
                invoiceNumber:invoiceNumber,
            }
            axios
                .post(`sales/recalculateinvoicecommission`, qs.stringify(payLoad),{timeout:240000})
                .then(async response => {
                    alert("Commission recalculated for "+invoiceNumber)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("Cannot find the invoice")
                            this.setState({
                                loading: false,
                            })
                            return
                        }
                    }
                    alert("Recalculate invoice commission error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="upgradeAgencyFromReferralToService"){
            this.setState({loading:true})
            const payLoad = {
                companyName:this.state.inputValue[0],
            }
            axios
                .post(`sales/upgradeagencyfromreferraltoservice`, qs.stringify(payLoad),{timeout:240000})
                .then(async response => {
                    alert("Agency upgraded from referral to service")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        
                    }
                    alert("Upgrade agency from referral to service error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="linkAgencyGDShop"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let gdShopName=this.state.inputValue[1]
            const payLoad = {
                companyName:companyName,
                gdShopName:gdShopName,
            }
            axios
                .post(`sales/linkagencygdshop`, qs.stringify(payLoad),{timeout:240000})
                .then(async response => {
                    alert("Agency GD Shop successfully linked")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("Cannot find GD Shop with that name")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==445){
                            alert("The GD shop is already linked to an agent company")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==446){
                            alert("The agent company is already linked to GD Shop")
                            this.setState({
                                loading: false,
                            })
                            return
                        }
                    }
                    alert("Link agency GD shop error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="assignAgentLeader"){
            this.setState({loading:true}) //get loading indicator
            let companyName = this.state.inputValue[0]
            let username=this.state.inputValue[1]
            let icNumber=this.state.inputValue[2]
            let bankAccountName=this.state.inputValue[3]
            let bankAccountNumber=this.state.inputValue[4]
            let bankName=this.state.inputValue[5]
            const payLoad = {
                companyName:companyName,
                username:username,
                icNumber:icNumber,
                bankAccountName:bankAccountName,
                bankAccountNumber:bankAccountNumber,
                bankName:bankName,
            }
            axios
                .post(`sales/assignagentleader`, qs.stringify(payLoad))
                .then(async response => {
                    alert("Agent leader assigned")
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status==444){
                            alert("The username does not exist")
                            this.setState({
                                loading: false,
                            })
                            return
                        }else if(error.response.status==445){
                            alert("The company cannot be found")
                            this.setState({
                                loading:false,
                            })
                            return
                        }else if(error.response.status==446){
                            alert("Same IC number already used by another account")
                            this.setState({
                                loading:false,
                            })
                            return
                        }else if(error.response.status==447){
                            alert("Same bank account number already used by another account")
                            this.setState({
                                loading:false,
                            })
                            return
                        }else if(error.response.status==448){
                            alert("The user is already agent of a company")
                            this.setState({
                                loading:false,
                            })
                            return
                        }else if(error.response.status==449){
                            alert("Commission cannot be higher than company commission")
                            this.setState({
                                loading:false
                            })
                            return
                        }
                    }
                    alert("Assign agent leader error\n" + error)
                    this.setState({
                        loading: false,
                        executionModal: false,
                    })
                })
        }else if(this.state.modalFunction=="validateAndGrantPendingInvoice"){
            alert("test")
        }else{
            alert("Cannot find the modal function")
        }
    }

    changePIC=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "changePIC",
            infoMessage: "Change PIC of client",
            contentMessage:"",
            inputField: ["Client Username(Phone Number)", "New PIC Username"],
            inputValue: ["", ""],
            executionModal: true,
        })
    }

    createCorporateAccount=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "createCorporateAccount",
            infoMessage: "Create Corporate Account",
            contentMessage:"",
            inputField: ["Company Name","Company SSM","#Company Address","Company Principal Username (Phone Number)"],
            inputValue: ["","","",""],
            executionModal: true,
        })
    }

    openCorpAccForAgency=()=>{
        this.setState({loading:true})
        axios
        .get(`sales/getagencylistwithoutcorpacc`,{timeout:15000})
        .then(async response => {
            let agencyString="$Agency"
            for(let agency of response.data){
                agencyString+="&&"+agency.name
            }

            this.setState({
                loading:false,
                modalFunction: "openCorpAccForAgency",
                infoMessage: "Open Corporate Account for Agency",
                contentMessage:"",
                inputField: [agencyString,"Principal Username","#Company Address"],
                inputValue: [response.data[0].name,"",""],
                executionModal: true,
            })
        })
        .catch(error =>{
            alert("Load agency list error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    grantCourseCredit=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "grantCourseCredit",
            infoMessage: "Grant Course Credit",
            contentMessage:"",
            inputField: ["Client Username(Phone Number)","$Course&&战略三力&&营销三力&&组织三力&&模式三力&&总裁三力"],
            inputValue: ["", "战略三力"],
            executionModal: true,
        })
    }

    removeCourseCredit=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "removeCourseCredit",
            infoMessage: "Remove Course Credit",
            contentMessage:"",
            inputField: ["Client Username(Phone Number)","$Course&&战略三力&&营销三力&&组织三力&&模式三力&&总裁三力"],
            inputValue: ["", "战略三力"],
            executionModal: true,
        })
    }

    transferCourseCredit=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({
            modalFunction: "transferCourseCredit",
            infoMessage: "Transfer Course Credit",
            contentMessage:"",
            inputField: ["From Client Username(Phone Number)","To Client Username(Phone Number)","$Course&&战略三力&&营销三力&&组织三力&&模式三力&&总裁三力"],
            inputValue: ["","", "战略三力"],
            executionModal: true,
        })
    }

    setExpiry=()=>{
        if(!this.validateAccess){
            return
        }

        this.setState({loading:true})
        axios
            .get(`membership/v1/getbadgelist`,{timeout:15000})
            .then(async response => {
                let badgeString="$Course"
                for(let badge of response.data){
                    badgeString+="&&"+badge.title
                }

                this.setState({
                    loading:false,
                    modalFunction: "setExpiry",
                    infoMessage: "Set Expiry",
                    contentMessage:"",
                    inputField: ["Client Username(Phone Number)",badgeString,"Expiry Date e.g. DD/MM/YYYY"],
                    inputValue: ["", response.data[0].title,""],
                    executionModal: true,
                })
            })
            .catch(error =>{
                alert("Load badge list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    purchaseForClientAction=()=>{
        //get the quotation
        this.setState({loading: true}) //get loading turn
        let apv=-1
        if(this.state.theOnlineInvoice.appliedPromoVoucher){
            apv=JSON.stringify(this.state.theOnlineInvoice.appliedPromoVoucher)
        }

        //custom price
        let customPrice=-1  //-1 means no custom price
        if(this.state.productPrice!=parseFloat(this.state.theOnlineInvoice.productList[0][2]).toFixed(2)){
            customPrice=this.state.productPrice
        }

        const payload = {
            username: this.state.checkedUser.username,
            course: this.state.buyCourseSelectedProduct.sku,
            apv:apv,
            rWalletToUse:this.state.rWalletToUse,
            pWalletToUse:this.state.pWalletToUse,
            partialWalletToUse:this.state.partialWalletToUse,
            overdraft:this.state.buyCourseOverdraft,
            customPrice:customPrice,
        }
        if(this.state.payerType=="corp"){
            payload.corpID=this.state.payerID
            payload.targetID=this.state.targetID
        }

        axios
            .post(`sales/buycourseforclientwithcredit`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                alert("Course purchased with client's credit and recorded")
                this.setState({
                    loading: false,
                    buyCourseForClientModal: false,
                    executionModal:false,
                })
            })
            .catch(error => {
                if(error.response){
                    // if(error.response.status==444){
                    //     alert("The user do not have enough credit\nRequire:"+error.response.data.totalAfterTax +"\n"+ error)
                    //     this.setState({
                    //         loading: false,
                    //         executionModal: false
                    //     })
                    //     return
                    // }
                }
                alert("Buy course for client error\n" + error)
                this.setState({
                    loading: false,
                    executionModal: false
                })
            })
    }

    updateQuotation=()=>{
        //validate
        let appliedPromoVoucher=this.state.theOnlineInvoice.appliedPromoVoucher
        //do not allow 2980 system class voucher to be used wiuth any other vouchers including itself
        if(appliedPromoVoucher.length>1){
            for(let applied of appliedPromoVoucher){
                if(applied.title=="系统班 优惠券"&&applied.value==2980){
                    alert("系统班 优惠券 RM2980 不能和其他优惠券一起使用哦")
                    appliedPromoVoucher=[applied,]
                    break
                }
            }
        }
        if(this.state.cashToUse<0){
            alert("Cash / Bank payment cannot be negative")
            return
        }

        //custom price
        let customPrice=-1  //-1 means no custom price
        if(this.state.productPrice!=parseFloat(this.state.theOnlineInvoice.productList[0][2]).toFixed(2)){
            customPrice=this.state.productPrice
        }
        this.setState({loading: true}) //get loading turn
        let payload = {
            username: this.state.checkedUser.username,
            course: this.state.buyCourseSelectedProduct.sku,
            appliedPromoVoucher:JSON.stringify(appliedPromoVoucher),
            rWalletToUse:this.state.rWalletToUse,
            pWalletToUse:this.state.pWalletToUse,
            partialWalletToUse:this.state.partialWalletToUse,
            customPrice:customPrice,
        }
        if(this.state.payerType=="corp"){
            payload.corpID=this.state.payerID
            payload.targetID=this.state.targetID
        }
        axios
            .post(`sales/updatecoursequotation`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //combine the data
                response.data.onlineInvoice.appliedPromoVoucher=response.data.appliedPromoVoucher
                response.data.onlineInvoice.promoVoucherList=response.data.promoVoucherList
                this.setState({
                    loading: false,
                    theOnlineInvoice:response.data.onlineInvoice,
                    cashToUse:response.data.onlineInvoice.totalAfterTax,
                    rWalletToUse:response.data.onlineInvoice.payWithRWallet,
                    pWalletToUse:response.data.onlineInvoice.payWithPWallet,
                    partialWalletToUse:response.data.onlineInvoice.payWithPartialWallet,
                    paymentChecked:true,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){ //already has the course
                        alert("Client already has the badge")
                        this.setState({loading: false})
                        return
                    }else if(error.response.status==445){ //insufficient credit
                        alert("Insufficient credit only has "+error.response.data.pointRemain+", require "+error.response.data.totalAfterTax)
                        this.setState({loading: false})
                        return
                    }
                }
                alert("Check course quotation error\n" + error)
                this.setState({
                    loading: false,
                    executionModal: false
                })
            })
    }

    createServiceAgentCompany=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getcreatenewagentcompanydata`,{timeout:15000})
            .then(async response => {
                let offlineEventTypeList="@Collaborating Classes"
                for(let offlineEventType of response.data){
                    offlineEventTypeList+="&&"+offlineEventType.name
                }

                this.setState({
                    loading:false,
                    modalFunction: "createServiceAgentCompany",
                    infoMessage: "Create Service Agent Company",
                    contentMessage:"",
                    inputField: [
                        "Company Name",
                        "SSM Number",
                        "Director Username(Phone Number)",
                        "Bank Account Name",
                        "Bank Account Number",
                        "Bank Name",
                        "Short Name",
                        offlineEventTypeList,
                    ],
                    inputValue: ["","","","","","","",[]],
                    executionModal: true,
                })
            })
            .catch(error =>{
                alert("Get create new agent company data error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    createReferralAgentCompany=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getcreatenewagentcompanydata`,{timeout:15000})
            .then(async response => {
                let offlineEventTypeList="@Collaborating Classes"
                for(let offlineEventType of response.data){
                    offlineEventTypeList+="&&"+offlineEventType.name
                }

                this.setState({
                    loading:false,
                    modalFunction: "createReferralAgentCompany",
                    infoMessage: "Create Referral Agent Company",
                    contentMessage:"",
                    inputField: [
                        "Company Name",
                        "SSM Number (put - if not applicable)",
                        "Director Username(Phone Number)",
                        "Bank Account Name",
                        "Bank Account Number",
                        "Bank Name",
                        "Short Name",
                        offlineEventTypeList,
                    ],
                    inputValue: ["","","","","","","",[]],
                    executionModal: true,
                })
            })
            .catch(error =>{
                alert("Get create new agent company data error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    linkAgencyGDShop=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentcompanylist`,{timeout:15000})
            .then(async response => {
                let agentCompanyList="$Agent Company"
                for(let agentCompany of response.data.agentCompanyList){
                    agentCompanyList+="&&"+agentCompany.name
                }

                this.setState({
                    loading:false,
                    modalFunction: "linkAgencyGDShop",
                    infoMessage: "Link Agency GD Shop",
                    contentMessage:"",
                    inputField:[
                        agentCompanyList,
                        "GD Shop Name",
                    ],
                    inputValue: [response.data.agentCompanyList[0].name,""],
                    executionModal: true,
                })
            })
            .catch(error =>{
                alert("Load agent company list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    assignAgentLeader=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentcompanylist`,{timeout:15000})
            .then(async response => {
                let agentCompanyList="$Agent Company"
                for(let agentCompany of response.data.agentCompanyList){
                    agentCompanyList+="&&"+agentCompany.name
                }

                this.setState({
                    loading:false,
                    modalFunction:"assignAgentLeader",
                    infoMessage:"Assign Agent Leader",
                    contentMessage:"",
                    inputField:[
                        agentCompanyList,
                        "Username (Phone Number)",
                        "IC Number",
                        "Bank Account Name",
                        "Bank Account Number",
                        "Bank Name",
                    ],
                    inputValue:[response.data.agentCompanyList[0].name,"","","","",""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Load agent company list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    generateResultLink=()=>{
        let tagString=""
        for(let tag of this.state.selectedTagList){
            tagString+="+"+tag.id
        }
        tagString=tagString.substring(1)
        this.setState({resultLink:this.state.selectedLink+"?tag="+tagString})
    }

    releaseAgentCompanyCommission=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentcompanylist`,{timeout:15000})
            .then(async response => {
                let agentCompanyList="$Agent Company"
                for(let agentCompany of response.data.agentCompanyList){
                    agentCompanyList+="&&"+agentCompany.name
                }

                this.setState({
                    loading:false,
                    modalFunction:"releaseAgentCompanyCommission",
                    infoMessage:"Release Agent Company Commission",
                    contentMessage:"",
                    inputField:[
                        agentCompanyList,
                        "Payment Number",
                        "Amount",
                        "Description"
                    ],
                    inputValue:[response.data.agentCompanyList[0].name,"","",""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Release agent company commission error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    releaseAgentCompanyProfitSharing=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentcompanylist`,{timeout:15000})
            .then(async response => {
                let agentCompanyList="$Agent Company"
                for(let agentCompany of response.data.agentCompanyList){
                    agentCompanyList+="&&"+agentCompany.name
                }

                this.setState({
                    loading:false,
                    modalFunction:"releaseAgentCompanyProfitSharing",
                    infoMessage:"Release Agent Company Profit Sharing",
                    contentMessage:"",
                    inputField:[
                        agentCompanyList,
                        "Payment Number",
                        "Amount",
                        "Description"
                    ],
                    inputValue:[response.data.agentCompanyList[0].name,"","",""],
                    executionModal:true,
                })
            })
            .catch(error =>{
                alert("Release agent company profit sharing error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getAgentWWalletList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentwwalletlist`,{timeout:30000})
            .then(async response => {
                for(let agent of response.data){
                    agent.checked=true  //default is checked
                }

                this.setState({
                    loading:false,
                    agentWWalletListModal:true,
                    agentWWalletList:response.data,
                })
            })
            .catch(error =>{
                alert("Get Agent C Wallet list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    releaseAgentWWallet=()=>{
        this.setState({loading:true})
        let releaseList=[]
        for(let agent of this.state.agentWWalletList){
            if(agent.checked==true){
                releaseList.push(agent)
            }
        }
        //send to server
        const payload = {
            agentList:JSON.stringify(releaseList)
        }
        axios
            .post(`sales/releaseagentwwallet`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentWWalletListModal:false,
                    agentWWalletList:[],
                    downloadReleaseWWalletExcelModal:true,
                    excelLink:response.data.excelLink,
                })
            })
            .catch(error =>{
                alert("Release Agent C Wallet list error\n"+error)
                this.setState({
                    loading:false,
                    agentWWalletList:[],
                    agentWWalletListModal:false,
                })
            })
    }

    getAgentWWalletReleaseLog=()=>{
        this.setState({loading:true})
        //send to server
        const payload = {
            page:this.state.agentWWalletReleasePage
        }
        axios
            .post(`sales/getagentwwalletreleaselog`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse date
                for(let data of response.data){
                    let rawDateSplit=data.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    data.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    confirmReleaseWWalletModal:true,
                    releaseWWalletLogList:response.data,
                })
            })
            .catch(error =>{
                alert("Get Agent C Wallet release log list error\n"+error)
                this.setState({
                    loading:false,

                })
            })
    }

    submitReleaseWWalletLogPaymentProof=async()=>{
        //validate
        if(this.state.uploadConfirmReleaseExcel==null){
            alert("Please select Excel file with filled payment numbers to submit")
            return
        }
        if(this.state.selectedRWWL==null){
            alert("Please select the Release Log to submit payment proof")
            return
        }

        this.setState({loading:true})
        const formData = new FormData()
        formData.append("paymentProof",this.state.uploadConfirmReleaseExcel)
        formData.set("releaseLogID",this.state.selectedRWWL)
        axios
            .post(`sales/submitreleasewwalletpaymentproof`, formData, {headers: {"Content-type": "multipart/form-data"}})
            .then(async response => {
                this.setState({
                    loading:false,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("'Release ID' in submitted excel and selected release log does not match.")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Submit C Wallet Release payment proof error\n"+error)
                this.setState({loading:false})
            })
    }

    getAgentCompanyList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getagentcompanylist`,{timeout:15000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentCompanyList:response.data.agentCompanyList,
                    changeAgentCompanyTierModal:true,
                })
            })
            .catch(error =>{
                alert("Load agent company list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getAgentTierList=()=>{
        this.setState({loading:true})
        const payload={
            agentCompanyID:this.state.askCompanyID,
        }
        axios
            .post(`sales/getagenttierlist`,qs.stringify(payload),{timeout:15000})
            .then(async response => {
                this.setState({
                    loading:false,
                    combinedTierList:response.data.combinedTierList,
                })
            })
            .catch(error =>{
                alert("Load agent tier list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    checkAgentCompanyTier=()=>{
        this.setState({loading:true})
        const payload={
            phoneNum:this.state.askPhoneNum,
        }
        axios
            .post(`sales/checkagentcompanytier`,qs.stringify(payload),{timeout:15000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentDetails:response.data,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("User does not exists")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Check original agent company and tier error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    changeAgentCompanyTier=()=>{
        this.setState({loading:true})
        const payload={
            phoneNum:this.state.askPhoneNum,
            agentCompanyID:this.state.askCompanyID,
            agentTierID:this.state.askTierID,
        }
        axios
            .post(`sales/changeagentcompanytier`,qs.stringify(payload),{timeout:15000})
            .then(async response => {
                alert("Agent company and tier successfully changed")
                this.setState({
                    loading:false,
                    changeAgentCompanyTierModal:false,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("User does not exists")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Employee not agent or affiliate")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Change agent company and tier error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    recalculateInvoiceCommission=()=>{
        this.setState({
            modalFunction: "recalculateInvoiceCommission",
            infoMessage: "Recalculate Invoice Commission",
            contentMessage:"",
            inputField: [
                "Invoice Number",
            ],
            inputValue: ["",],
            executionModal: true,
        })
    }

    upgradeAgencyFromReferralToService=()=>{
        this.setState({loading:true})
        const payload={

        }
        axios
            .post(`sales/getreferralagencylist`,qs.stringify(payload),{timeout:15000})
            .then(async response => {
                let referralAgencyList="$Referral Agency"
                for(let referralAgency of response.data){
                    referralAgencyList+="&&"+referralAgency.name
                }
                this.setState({
                    loading:false,
                    modalFunction: "upgradeAgencyFromReferralToService",
                    infoMessage: "Upgrade Agency from Referral to Service",
                    contentMessage:"",
                    inputField: [
                        referralAgencyList,
                    ],
                    inputValue: [response.data[0].name,],
                    executionModal: true,
                })
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert("Get referral agency list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getTotalCredit=()=>{
        let sum=0
        if(this.state.payerType=="corp"){   //corporate account
            sum=this.state.payerRWallet+this.state.payerPWallet+this.state.payerPrWallet+this.state.payerCWallet
        }else if(this.state.payerType=="member"){   //member account
            sum=this.state.checkedUser.topupWallet+this.state.checkedUser.partialWallet
            if(this.state.checkedEmployee!=null){
                sum+=this.state.checkedEmployee.commissionWallet+this.state.checkedEmployee.withdrawWallet
            }
        }
        return sum
    }

    calculateTotalPointToUse=()=>{
        let totalPoint=this.state.rWalletToUse+this.state.pWalletToUse+this.state.partialWalletToUse
        if(totalPoint<0){
            totalPoint=0
        }
        return parseFloat((totalPoint).toFixed(2))
    }

    recalculatePayment=()=>{
        let pointToUse=this.calculateTotalPointToUse()
        let predictedPromoValue=0
        for(let promo of this.state.theOnlineInvoice.appliedPromoVoucher){
            predictedPromoValue+=promo.value
        }
        let predictedBeforeTax=parseFloat((this.state.theOnlineInvoice.productList[0][2]-predictedPromoValue).toFixed(2))
        //rounding tax, only tax needs rounding because all other prices are fixed well
        let tax=((predictedBeforeTax-pointToUse)*8/100)
        let stringTax=tax.toFixed(2)
        let lastChar=stringTax.charAt(stringTax.length-1)
        if(lastChar=="1" || lastChar=="2"){
            stringTax=stringTax.substring(0,stringTax.length-1)+"0"
            tax=parseFloat(stringTax)
        }else if(lastChar=="3" || lastChar=="4" || lastChar=="6" || lastChar=="7"){
            stringTax=stringTax.substring(0,stringTax.length-1)+"5"
            tax=parseFloat(stringTax)
        }else if(lastChar=="8" || lastChar=="9"){
            stringTax=stringTax.substring(0,stringTax.length-1)+"0"
            tax=parseFloat(stringTax)+0.10
        }
        let cashToUse=parseFloat(((predictedBeforeTax-pointToUse)+tax).toFixed(2))
        this.setState({
            pointToUse:pointToUse,
            paymentChecked:false,
            predictedPromoValue:predictedPromoValue,
            predictedBeforeTax:predictedBeforeTax,
            predictedTax:tax,
            cashToUse:cashToUse,
            predictedAfterTax:cashToUse,
        })
    }

    clearBuyCourseModal=()=>{
        this.setState({
            buyCourseSelectedProduct:null,
            paymentChecked:false,
            pointToUse:0,
            rWalletToUse:0,
            pWalletToUse:0,
            partialWalletToUse:0,
            cashToUse:0,
            theOnlineInvoice:null,
            buyCourseOverdraft:"No",
        })
    }

    getFranchiseSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getfranchisesessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    franchiseSessionList:response.data,
                    purchaseSignupModal:true,
                    selectedCourseTitle:"连锁系统",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get franchise session list error\n"+error)
                this.setState({loading:false})
            })
    }

    checkPurchaseSignupPhone=()=>{
        if(this.state.referCourseClientPhone==null||this.state.referCourseClientPhone.length==0){
            alert("Please enter client phone number")
            return
        }
        if(this.state.selectedCourseTitle=="连锁系统"&&this.state.selectedCourseSession==null){
            alert("Please select a course session")
            return
        }

        this.setState({loading:true})
        const payload = {
            clientPhone:this.state.referCourseClientPhone,
            courseTitle:this.state.selectedCourseTitle,
        }
        axios
            .post(`sales/checkpurchasesignupphone`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    purchaseSignupPage:"member",
                    referCourseClientName:response.data.clientName,
                    referCourseClientReferrer:response.data.clientReferrer,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //non member
                        this.setState({
                            loading:false,
                            purchaseSignupPage:"nonmember",
                        })
                        return
                    }else if(error.response.status==445){   //already has the badge
                        alert("User member already has the course")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Check purchase signup phone error\n"+error)
                this.setState({loading:false})
            })
    }

    fallbackCopyTextToClipboard=(text)=>{
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('分享信息成功copy到clipboard');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }

    updateProductPrice=()=>{
        let invoiceClone=this.state.theOnlineInvoice
        invoiceClone.productList[0][2]=this.state.productPrice
        this.setState({
            paymentChecked:false,
            editProductPriceModal:false,
            theOnlineInvoice:invoiceClone,
        })
    }

    getMemberCorporateAccountList=()=>{
        this.setState({loading:true})
        const payload = {
            username:this.state.checkedUser.username,
        }
        axios
            .post(`sales/getmembercorporateaccountlist`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    corporateAccountList:response.data,
                    corporateAccountListModal:true,
                })
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert("Get member corporate account list error\n"+error)
                this.setState({loading:false})
            })
    }

    getPayerWallet=(payerID,payerName,payerType)=>{
        this.setState({loading: true})
        const payload = {
            payerID:payerID,
            targetUsername:this.state.checkedUser.username,
        }
        axios
            .post(`sales/getpayerwallet`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.clearBuyCourseModal()
                this.setState({
                    loading: false,
                    corporateAccountListModal:false,
                    payerID:payerID,
                    targetID:response.data.targetID,    //corporate staff id
                    payerName:payerName,
                    payerType:payerType,
                    payerRWallet:response.data.rWallet,
                    payerPWallet:response.data.pWallet,
                    payerPrWallet:response.data.prWallet,
                    payerCWallet:response.data.cWallet,
                })
            })
            .catch(error => {
                if(error.response){

                }
                alert("Get payer wallet error\n" + error)
                this.setState({
                    loading: false,
                })
            })
    }

    getUserWalletFromSystem=()=>{
        //get client detail
        this.setState({loading: true})
        let username = this.state.inputValue[0] //get username
        const payload = {
            username: username,
        }
        axios
            .post(`sales/getuserwalletfromsystem`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.clearBuyCourseModal()
                this.setState({
                    loading:false,
                    corporateAccountListModal:false,
                    buyCourseForClientModal:true,
                    checkedUser:response.data.checkedUser,
                    checkedEmployee:response.data.checkedEmployee,
                    payerID:response.data.checkedUser.username,
                    payerName:response.data.checkedUser.name,
                    payerType:"member",
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){ //already has the course
                        alert("Client not found")
                        this.setState({loading: false})
                        return
                    }
                }
                alert("Get user wallet from system error\n" + error)
                this.setState({
                    loading: false,
                    executionModal: false
                })
            })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal style={{maxWidth:600}} isOpen={this.state.corporateAccountListModal} toggle={()=>this.setState({corporateAccountListModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>User's Corporate Account List</div>
                        {
                        this.state.checkedUser!=null?
                            <div style={{width:"100%",display:"flex",gap:5,flexDirection:"column",marginTop:15}}>
                                <Button color={this.state.payerID==this.state.checkedUser.username?"secondary":"light"} style={{border:"1px solid lightgrey"}} onClick={()=>{
                                    this.getUserWalletFromSystem()
                                }}>
                                    {this.state.checkedUser.username} {this.state.checkedUser.name}
                                </Button>
                                {
                                this.state.corporateAccountList.map((corp)=>{
                                    return(
                                        <Button color={corp.id==this.state.payerID?"secondary":"light"} style={{border:"1px solid lightgrey"}} onClick={()=>{
                                            this.getPayerWallet(corp.id,corp.name,"corp")
                                        }}>
                                            {corp.name} | {corp.job}
                                        </Button>
                                    )
                                })
                                }
                            </div>
                            :
                            null
                        }
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%"}}>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",marginRight:5,flex:1}} onClick={()=>{
                                this.setState({corporateAccountListModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.purchaseSignupModal}
                    toggle={()=>{
                        this.setState({
                            purchaseSignupModal:false,
                            purchaseSignupPage:"phone",
                            referCourseClientName:null,
                            referCourseClientPhone:null,
                            referCourseClientEmail:null,
                            referCourseClientBillingAddress:null,
                            referCourseClientNameError:false,
                            referCourseClientPhoneError:false,
                            referCourseClientEmailError:false,
                        })
                    }}
                    style={{maxWidth:600}}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Purchase, Auto Signup and PIC Change</div>
                        <div style={{marginBottom:10,gap:10,display:"flex",flexDirection:"column",width:"100%"}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",alignItems:"center",gap:10,marginTop:20}}>
                                <Button color={this.state.selectedCourseTitle=="连锁系统"?"primary":"secondary"} style={{whiteSpace:"nowrap",}} onClick={()=>{
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.getFranchiseSessionList()
                                }}>
                                    连锁系统
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="战略三力"?"primary":"secondary"} style={{whiteSpace:"nowrap",}} onClick={()=>{
                                    // this.getSMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"战略三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    战略三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="营销三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getMMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"营销三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    营销三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="组织三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getTMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"组织三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    组织三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="总裁三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getCEOSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"总裁三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    总裁三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle == "系统管理学配套" ? "primary" : "secondary"} style={{ whiteSpace: "nowrap" }} onClick={() => {
                                    if (this.state.purchaseSignupPage != "phone") {
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle: "系统管理学配套",
                                        selectedCourseSession: null,
                                    })
                                }}>
                                    系统管理学配套
                                </Button>
                            </div>
                            {
                            this.state.selectedCourseTitle=="连锁系统"?
                                <div>
                                    <div>Select Session: </div>
                                    <select disabled={this.state.purchaseSignupPage!="phone"} onChange={(e)=>{
                                        this.setState({selectedCourseSession:e.target.value})
                                    }}>
                                        {
                                        this.state.franchiseSessionList.map((option,ind)=>{
                                            return(
                                                <option value={option.id}>{option.startDate+" - "+option.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                :
                                null
                            }

                            {
                            this.state.purchaseSignupPage=="member"?
                                <div>
                                    <div>Referrer: {this.state.referCourseClientReferrer!=null?this.state.referCourseClientReferrer:"-"}</div>
                                    <div>New PIC: {reactLocalStorage.get("username")+" "+reactLocalStorage.get("name")}</div>
                                </div>
                                :
                                <div>Referrer & PIC: {reactLocalStorage.get("username")+" "+reactLocalStorage.get("name")}</div>
                            }
                           

                            {
                            this.state.purchaseSignupPage=="phone"?
                                <div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",flexWrap:"nowrap",gap:10,alignItems:"center"}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                            <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                            <Input value={this.state.referCourseClientPhone} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                                this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                            }}/>
                                        </div>
                                        <Button color="primary" style={{whiteSpace:"nowrap"}} onClick={()=>{
                                            this.checkPurchaseSignupPhone()
                                        }}>
                                            Check Number
                                        </Button>
                                    </div>
                                </div>
                                :
                            this.state.purchaseSignupPage=="member"?
                                <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                    <div style={{color:"green"}}>{this.state.referCourseClientPhone} is already a member</div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                        <Input value={this.state.referCourseClientPhone} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                        }}/>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Name:</div>
                                        <Input value={this.state.referCourseClientName} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientNameError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientName:e.target.value})
                                        }}/>
                                    </div>

                                    <Button color="primary" style={{}} onClick={()=>{
                                        let skuIDString=""
                                        if(this.state.selectedCourseTitle=="连锁系统"){
                                            skuIDString="sku=franchise&franchiseID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="战略三力"){
                                            skuIDString="sku=SMP"
                                        }else if(this.state.selectedCourseTitle=="营销三力"){
                                            skuIDString="sku=MMP"
                                        }else if(this.state.selectedCourseTitle=="组织三力"){
                                            skuIDString="sku=TMP"
                                        }else if(this.state.selectedCourseTitle=="总裁三力"){
                                            skuIDString="sku=CEO"
                                        }
                                        let introString="&intro="+reactLocalStorage.get("referCode")
                                        let memberString="&member="+this.state.referCourseClientPhone

                                        let signupLink=""
                                        if(this.state.selectedCourseTitle=="系统管理学配套"){
                                            signupLink="https://enlliance.com/sysmanagementpackage?"+introString.substring(1)+memberString
                                        }else{
                                            signupLink="https://enlliance.com/payment?"+skuIDString+introString+memberString
                                        }
                                        signupLink=encodeURI(signupLink)

                                        this.setState({
                                            purchaseSignupPage:"generate",
                                            franchisePurchaseSignupLink:signupLink,
                                        })
                                    }}>
                                        Generate Link
                                    </Button>
                                </div>
                                :
                            this.state.purchaseSignupPage=="nonmember"?
                                <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                    <div style={{color:"salmon"}}>{this.state.referCourseClientPhone} is not a member</div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                        <Input value={this.state.referCourseClientPhone} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                        }}/>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Name:</div>
                                        <Input value={this.state.referCourseClientName} style={{width:"100%",borderColor:this.state.referCourseClientNameError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientName:e.target.value})
                                        }}/>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Email:</div>
                                        <Input value={this.state.referCourseClientEmail} style={{width:"100%",borderColor:this.state.referCourseClientEmailError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientEmail:e.target.value})
                                        }}/>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <div style={{whiteSpace:"nowrap"}}>Client Billing Address:</div>
                                        <textarea rows={3} value={this.state.referCourseClientBillingAddress} style={{width:"100%"}} onChange={(e)=>{
                                            this.setState({referCourseClientBillingAddress:e.target.value})
                                        }}/>
                                    </div>

                                    <Button color="primary" style={{}} onClick={()=>{
                                        //validate
                                        let referCourseClientNameError=false
                                        if(this.state.referCourseClientName==null||this.state.referCourseClientName.trim().length==0){
                                            referCourseClientNameError=true
                                        }
                                        let referCourseClientPhoneError=false
                                        if(this.state.referCourseClientPhone==null||this.state.referCourseClientPhone.trim().length==0){
                                            referCourseClientPhoneError=true
                                        }
                                        let referCourseClientEmailError=false
                                        if(this.state.referCourseClientEmail==null||!this.validateEmail(this.state.referCourseClientEmail)){
                                            referCourseClientEmailError=true
                                        }
                                        // let referCourseClientBillingAddressError=false
                                        // if(this.state.referCourseClientBillingAddress==null||this.state.referCourseClientBillingAddress.length==0){
                                        //     referCourseClientBillingAddressError=true
                                        // }
                                        if(referCourseClientNameError||referCourseClientPhoneError||referCourseClientEmailError){
                                            this.setState({
                                                referCourseClientNameError:referCourseClientNameError,
                                                referCourseClientPhoneError:referCourseClientPhoneError,
                                                referCourseClientEmailError:referCourseClientEmailError,
                                            })
                                            alert("Please fill in all client criteria correctly")
                                            return
                                        }

                                        let skuIDString=""
                                        if(this.state.selectedCourseTitle=="连锁系统"){
                                            skuIDString="sku=franchise&franchiseID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="战略三力"){
                                            skuIDString="sku=SMP"
                                        }else if(this.state.selectedCourseTitle=="营销三力"){
                                            skuIDString="sku=MMP"
                                        }else if(this.state.selectedCourseTitle=="组织三力"){
                                            skuIDString="sku=TMP"
                                        }else if(this.state.selectedCourseTitle=="总裁三力"){
                                            skuIDString="sku=CEO"
                                        }
                                        let introString="&intro="+reactLocalStorage.get("referCode")
                                        let nonMemberString="&name="+this.state.referCourseClientName+
                                            "&phone="+this.state.referCourseClientPhone+
                                            "&email="+this.state.referCourseClientEmail+
                                            "&billingAddress="+this.state.referCourseClientBillingAddress

                                        let signupLink=""
                                        if(this.state.selectedCourseTitle=="系统管理学配套"){
                                            signupLink="https://enlliance.com/sysmanagementpackage?"+introString.substring(1)+nonMemberString
                                        }else{
                                            signupLink="https://enlliance.com/payment?"+skuIDString+introString+nonMemberString
                                        }
                                        signupLink=encodeURI(signupLink)

                                        this.setState({
                                            purchaseSignupPage:"generate",
                                            referCourseClientNameError:false,
                                            referCourseClientPhoneError:false,
                                            referCourseClientEmailError:false,
                                            franchisePurchaseSignupLink:signupLink,
                                        })
                                    }}>
                                        Generate Link
                                    </Button>
                                </div>
                                :
                            this.state.purchaseSignupPage=="generate"?
                                <div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                        <div>Link:</div>
                                        <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                            <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                GLOBAL.copyTextToClipboard(this.state.franchisePurchaseSignupLink)
                                            }}>
                                                Copy
                                            </Button>
                                        </div>
                                    </div>
                                    <textarea rows={5} style={{width:"100%"}} value={this.state.franchisePurchaseSignupLink} />
                                </div>
                                :
                                null
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:10,width:"100%"}}>
                            {
                            this.state.purchaseSignupPage!="phone"?
                                <Button onClick={()=>{
                                    this.setState({
                                        purchaseSignupPage:"phone",
                                        franchisePurchaseSignupLink:null,
                                        referCourseClientName:null,
                                        referCourseClientPhone:null,
                                        referCourseClientEmail:null,
                                        referCourseClientBillingAddress:null,
                                        referCourseClientNameError:false,
                                        referCourseClientPhoneError:false,
                                        referCourseClientEmailError:false,
                                    })
                                }}>
                                    Clear
                                </Button>
                                :
                                null
                            }
                            <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({
                                    purchaseSignupModal:false,
                                    purchaseSignupPage:"phone",
                                    referCourseClientName:null,
                                    referCourseClientPhone:null,
                                    referCourseClientEmail:null,
                                    referCourseClientBillingAddress:null,
                                    referCourseClientNameError:false,
                                    referCourseClientPhoneError:false,
                                    referCourseClientEmailError:false,
                                })
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.selectProductToBuyModal} toggle={()=>this.setState({selectProductToBuyModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>Select Product to Buy</div>
                        <div style={{marginTop:10,width:"100%",display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.buyCourseProductTypeList.map((productType)=>{
                                if(productType!="Old Product"&&productType!="Hidden"){
                                    return(
                                        <div style={{marginTop:10,width:"100%",display:"flex",flexDirection:"column",gap:5}}>
                                            <div>{productType}</div>
                                            <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                            {
                                            this.state.buyCourseInventoryList.map((inventory)=>{
                                                if(inventory.productType==productType){
                                                    return(
                                                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                            this.setState({
                                                                buyCourseSelectedProduct:inventory,
                                                                selectProductToBuyModal:false,
                                                                rWalletToUse:0,
                                                                pWalletToUse:0,
                                                                partialWalletToUse:0,
                                                                buyCourseOverdraft:"No",
                                                            },()=>{this.checkCourseQuotation()})
                                                        }}>
                                                            {inventory.name}
                                                        </Button>
                                                    )
                                                }
                                            })
                                            }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%"}}>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",marginRight:5,flex:1}} onClick={()=>{
                                this.setState({selectProductToBuyModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:1000}} isOpen={this.state.buyCourseForClientModal} toggle={()=>this.setState({buyCourseForClientModal:false,checkedUser:null,checkedEmployee:null})}>
                    {
                    this.state.checkedUser!=null?
                        <div style={{padding:20,display:"flex",flexDirection:"row",gap:20,width:"100%"}}>
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alginItems:"center",width:"100%"}}>
                                                <div>Payer</div>
                                                <Button color="primary" style={{padding:"5px 10px",fontSize:12,}} onClick={()=>{
                                                    this.getMemberCorporateAccountList()
                                                }}>
                                                    Select
                                                </Button>
                                            </div>
                                        </td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {
                                            this.state.payerType=="member"?
                                                this.state.payerID+" "+this.state.payerName
                                                :
                                            this.state.payerType=="corp"?
                                                this.state.payerName
                                                :
                                                "-"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Receiver</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {this.state.checkedUser.username} {this.state.checkedUser.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Job</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {
                                            this.state.checkedEmployee!=null?
                                                this.state.checkedEmployee.job!=null?
                                                    this.state.checkedEmployee.job=="Agent"||this.state.checkedEmployee.job=="Agent Leader"?
                                                        this.state.checkedEmployee.job+" "+this.state.checkedEmployee.tierName
                                                        :
                                                        this.state.checkedEmployee.job
                                                    :
                                                    "Member"
                                                :
                                                "Member"
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>{this.state.payerType=="corp"?"Corporate ":null}R Wallet</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {
                                            this.state.payerType=="corp"?
                                                this.state.payerRWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                :
                                            this.state.payerType=="member"?
                                                this.state.checkedUser.topupWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                :
                                                "-"
                                            }
                                        </td>
                                    </tr>

                                    {
                                    this.state.checkedEmployee!=null?
                                        <tr>
                                            <td style={{border:"1px solid lightgrey",padding:5}}>{this.state.payerType=="corp"?"Corporate ":null}P Wallet</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                                {
                                                this.state.payerType=="corp"?
                                                    this.state.payerPWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                    :
                                                this.state.payerType=="member"?
                                                    this.state.checkedEmployee.commissionWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                    :
                                                    "-"
                                                }
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>{this.state.payerType=="corp"?"Corporate ":null}Partial Wallet</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {
                                            this.state.payerType=="corp"?
                                                this.state.payerPrWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                :
                                            this.state.payerType=="member"?
                                                this.state.checkedUser.partialWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                :
                                                "-"
                                            }
                                        </td>
                                    </tr>
                                    
                                    {
                                    this.state.checkedEmployee!=null?
                                        <tr>
                                            <td style={{border:"1px solid lightgrey",padding:5}}>{this.state.payerType=="corp"?"Corporate ":null}C Wallet</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                                {
                                                this.state.payerType=="corp"?
                                                    this.state.payerCWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                    :
                                                this.state.payerType=="member"?
                                                    this.state.checkedEmployee.withdrawWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})
                                                    :
                                                    "-"
                                                }
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }

                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Total Credit</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5}}>
                                            {this.getTotalCredit().toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})}
                                        </td>
                                    </tr>
                                </table>
                                <div style={{width:"100%"}}>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",flexWrap:"nowrap",alignItems:"center"}}>
                                        <div style={{flex:1}}>Item to buy: </div>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            this.getInventoryList()
                                        }}>
                                            {
                                            this.state.buyCourseSelectedProduct==null?
                                                "Please select a product to buy"
                                                :
                                                this.state.buyCourseSelectedProduct.name
                                            }
                                        </Button>
                                    </div>
                                </div>
                                {
                                this.state.theOnlineInvoice!=null?
                                    <div>
                                        <div>Promo vouchers used</div>
                                        {
                                        this.state.theOnlineInvoice.appliedPromoVoucher&&this.state.theOnlineInvoice.appliedPromoVoucher.length>0?
                                            this.state.theOnlineInvoice.appliedPromoVoucher.map((applied,index)=>{
                                                return(
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",marginBottom:5,fontSize:13}}>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",borderStyle:"solid",borderColor:"#ffc66e",backgroundColor:"#fff8ed",borderWidth:1}}>
                                                            <div style={{padding:5,borderColor:"#ffc66e",borderStyle:"dashed",borderWidth:0,borderRightWidth:1}}>{applied.title}</div>
                                                            <div style={{color:"grey",padding:"0px 10px"}}>RM{applied.value} {applied.expiryDate}</div>
                                                        </div>
                                                        <div style={{fontSize:14,color:"blue",cursor:"pointer",margin:"0px 5px"}} onClick={()=>{
                                                            let invoiceClone=this.state.theOnlineInvoice
                                                            invoiceClone.promoVoucherList.push(applied)
                                                            invoiceClone.appliedPromoVoucher.splice(index,1)
                                                            this.setState({
                                                                theOnlineInvoice:invoiceClone,
                                                                paymentChecked:false,
                                                            },()=>{
                                                                this.recalculatePayment()
                                                            })
                                                        }}>
                                                            取消
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div>-</div>
                                        }
                                        <div>Other relevant promo vouchers</div>
                                        {
                                        this.state.theOnlineInvoice.promoVoucherList&&this.state.theOnlineInvoice.promoVoucherList.length>0?
                                            <div style={{maxHeight:200,overflowY:"scroll",border:"1px solid lightgrey"}}>
                                                {
                                                this.state.theOnlineInvoice.promoVoucherList.map((item,index)=>{
                                                    return(
                                                        <div style={{marginRight:2,marginBottom:2,display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",backgroundColor:"#e8ffee",
                                                            marginBottom:2,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5,padding:5}}>
                                                            <div style={{color:"black",fontSize:13}}>
                                                                {item.title} RM{item.value}<br/>
                                                                {item.expiryDate}
                                                            </div>
                                                            <Button color="primary" style={{marginLeft:5,padding:3,fontSize:11}}
                                                                onClick={()=>{
                                                                    let invoiceClone=this.state.theOnlineInvoice
                                                                    
                                                                    //do not allow 2980 system voucher to be used with any other voucher including itself
                                                                    //clear any other voucher in current applied promo voucher list
                                                                    for(let currentApplied of invoiceClone.appliedPromoVoucher){
                                                                        if(currentApplied.title=="系统班 优惠券"&&currentApplied.value==2980){
                                                                            alert("系统班 优惠券 RM2980 不能和其他优惠券一起使用哦")
                                                                            return
                                                                        }
                                                                    }

                                                                    invoiceClone.appliedPromoVoucher.push(item)
                                                                    invoiceClone.promoVoucherList.splice(index,1)

                                                                    this.setState({
                                                                        theOnlineInvoice:invoiceClone,
                                                                        paymentChecked:false,
                                                                    },()=>{
                                                                        this.recalculatePayment()
                                                                    })
                                                                }}>
                                                                点击使用
                                                            </Button>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            :
                                            <div>-</div>
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                <div style={{padding:"10px 0px",gap:5,display:"flex",flexDirection:"column",width:"100%"}}>
                                    <div>R Wallet amount to use (without SST)</div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5}}>
                                        <Input disabled={this.state.buyCourseSelectedProduct==null}
                                            value={this.state.rWalletToUse} style={{textAlign:"right"}} onChange={(e)=>{
                                                let rWToUse=parseFloat(e.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2"))
                                                if(isNaN(rWToUse)){
                                                    rWToUse=0
                                                }
                                                //max product price
                                                if(rWToUse>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    rWToUse=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(rWToUse>this.state.payerRWallet){
                                                        rWToUse=this.state.payerRWallet
                                                    }
                                                }else{
                                                    if(rWToUse>this.state.checkedUser.topupWallet){
                                                        rWToUse=this.state.checkedUser.topupWallet
                                                    }
                                                }
                                                this.setState({rWalletToUse:rWToUse},()=>{
                                                    this.recalculatePayment()
                                                })
                                        }} />
                                        <Button disabled={this.state.buyCourseSelectedProduct==null} color="primary" style={{}}
                                            onClick={()=>{
                                                let topupWalletValue=0
                                                if(this.state.payerType=="corp"){
                                                    topupWalletValue=this.state.payerRWallet
                                                }else{
                                                    topupWalletValue=this.state.checkedUser.topupWallet
                                                }
                                                //max product price
                                                if(topupWalletValue>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    topupWalletValue=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(topupWalletValue>this.state.payerRWallet){
                                                        topupWalletValue=this.state.payerRWallet
                                                    }
                                                }else{
                                                    if(topupWalletValue>this.state.checkedUser.topupWallet){
                                                        topupWalletValue=this.state.checkedUser.topupWallet
                                                    }
                                                }
                                                this.setState({
                                                    rWalletToUse:topupWalletValue,
                                                },()=>{
                                                    this.recalculatePayment()
                                                })
                                        }}>
                                            Max
                                        </Button>
                                    </div>
                                    <div>P Wallet amount to use (without SST)</div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5}}>
                                        <Input disabled={this.state.buyCourseSelectedProduct==null}
                                            value={this.state.pWalletToUse} style={{textAlign:"right"}} onChange={(e)=>{
                                                let rWToUse=parseFloat(e.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2"))
                                                if(isNaN(rWToUse)){
                                                    rWToUse=0
                                                }
                                                //max product price
                                                if(rWToUse>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    rWToUse=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(rWToUse>this.state.payerPWallet){
                                                        rWToUse=this.state.payerPWallet
                                                    }
                                                }else{
                                                    if(this.state.checkedEmployee==null){
                                                        rWToUse=0
                                                    }else if(rWToUse>this.state.checkedEmployee.commissionWallet){
                                                        rWToUse=this.state.checkedEmployee.commissionWallet
                                                    }
                                                }
                                                this.setState({pWalletToUse:rWToUse},()=>{
                                                    this.recalculatePayment()
                                                })
                                        }} />
                                        <Button disabled={this.state.buyCourseSelectedProduct==null} color="primary" style={{}}
                                            onClick={()=>{
                                                let pWalletValue=0
                                                if(this.state.payerType=="corp"){
                                                    pWalletValue=this.state.payerPWallet
                                                }else{
                                                    pWalletValue=this.state.checkedEmployee.commissionWallet
                                                }
                                                //max product price
                                                if(pWalletValue>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    pWalletValue=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(pWalletValue>this.state.payerPWallet){
                                                        pWalletValue=this.state.payerPWallet
                                                    }
                                                }else{
                                                    if(this.state.checkedEmployee==null){
                                                        pWalletValue=0
                                                    }else if(pWalletValue>this.state.checkedEmployee.commissionWallet){
                                                        pWalletValue=this.state.checkedEmployee.commissionWallet
                                                    }
                                                }
                                                this.setState({
                                                    pWalletToUse:pWalletValue,
                                                },()=>{
                                                    this.recalculatePayment()
                                                })
                                        }}>
                                            Max
                                        </Button>
                                    </div>
                                    <div>Partial Wallet amount to use (without SST)</div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5}}>
                                        <Input disabled={this.state.buyCourseSelectedProduct==null}
                                            value={this.state.partialWalletToUse} style={{textAlign:"right"}} onChange={(e)=>{
                                                let pWToUse=parseFloat(e.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2"))
                                                if(isNaN(pWToUse)){
                                                    pWToUse=0
                                                }
                                                //max product price
                                                if(pWToUse>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    pWToUse=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(pWToUse>this.state.payerPrWallet){
                                                        pWToUse=this.state.payerPrWallet
                                                    }
                                                }else{
                                                    if(pWToUse>this.state.checkedUser.partialWallet){
                                                        pWToUse=this.state.checkedUser.partialWallet
                                                    }
                                                }
                                                this.setState({partialWalletToUse:pWToUse},()=>{
                                                    this.recalculatePayment()
                                                })
                                        }} />
                                        <Button disabled={this.state.buyCourseSelectedProduct==null} color="primary" style={{}}
                                            onClick={()=>{
                                                let prWalletValue=0
                                                if(this.state.payerType=="corp"){
                                                    prWalletValue=this.state.payerPrWallet
                                                }else{
                                                    prWalletValue=this.state.checkedUser.partialWallet
                                                }
                                                //max product price
                                                if(prWalletValue>parseFloat(this.state.theOnlineInvoice.productList[0][2])){
                                                    prWalletValue=parseFloat(this.state.theOnlineInvoice.productList[0][2])
                                                }
                                                //max wallet value
                                                if(this.state.payerType=="corp"){
                                                    if(prWalletValue>this.state.payerPrWallet){
                                                        prWalletValue=this.state.payerPrWallet
                                                    }
                                                }else{
                                                    if(prWalletValue>this.state.checkedUser.partialWallet){
                                                        prWalletValue=this.state.checkedUser.partialWallet
                                                    }
                                                }
                                                this.setState({
                                                    partialWalletToUse:prWalletValue,
                                                },()=>{
                                                    this.recalculatePayment()
                                                })
                                        }}>
                                            Max
                                        </Button>
                                    </div>
                                    <div>Cash / Bank transfer amount (with SST)</div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                        <Input disabled={this.state.buyCourseOverdraft=="No"||this.state.paymentChecked==true}
                                            value={this.state.cashToUse} style={{textAlign:"right"}} onChange={(e)=>{
                                            this.setState({cashToUse:e.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")})
                                        }} />
                                    </div>
                                    <div style={{width:"100%",textAlign:"right",fontSize:10,display:"flex",flexDirection:"row",gap:20,justifyContent:"flex-end"}}>
                                        <div>Net: {(parseFloat(this.state.cashToUse)*100/106).toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})}</div>
                                        <div>Tax: {(parseFloat(this.state.cashToUse)*6/106).toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits:2})}</div>
                                    </div>
                                    {/*
                                    <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                        <div style={{flex:1}}>Agent P Wallet Overdraft</div>
                                        <Switch disabled={this.state.buyCourseSelectedProduct==null} onChange={()=>{
                                            if(this.state.buyCourseOverdraft=="Yes"){
                                                this.setState({buyCourseOverdraft:"No"})
                                            }else{
                                                this.setState({buyCourseOverdraft:"Yes"})
                                            }
                                        }} checked={this.state.buyCourseOverdraft=="Yes"} />
                                    </div>
                                    */}
                                </div>

                                <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                    {
                                    this.state.paymentChecked==true?
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Invoice Number</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        {this.state.theOnlineInvoice.referenceNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{borderLeft:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",gap:10,justifyContent:"space-between"}}>
                                                        <div>Item price</div>
                                                        <Button color="primary" style={{padding:"2px 10px",fontSize:14}} onClick={()=>{
                                                            this.setState({editProductPriceModal:true})
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        {parseFloat(this.state.theOnlineInvoice.productList[0][2]).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Promo voucher discount</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        -{parseFloat(this.state.theOnlineInvoice.promoValue).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr style={{}}>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Pay with point</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        -{this.state.theOnlineInvoice.payWithPoint}
                                                    </td>
                                                </tr>
                                                <tr style={{}}>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Total after discount</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        {parseFloat(this.state.theOnlineInvoice.totalBeforeTax).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Tax</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        {parseFloat(this.state.theOnlineInvoice.tax).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border:"1px solid lightgrey",padding:5}}>Total after tax</td>
                                                    <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                        {parseFloat(this.state.theOnlineInvoice.totalAfterTax).toFixed(2)}
                                                    </td>
                                                </tr>
                                            </table>

                                            <Button color="success" style={{width:"100%"}} onClick={()=>{
                                                this.purchaseForClientAction()
                                            }}>
                                                Confirm Payment
                                            </Button>
                                        </div>
                                        :
                                    this.state.buyCourseSelectedProduct!=null?
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                            {
                                            this.state.theOnlineInvoice!=null?
                                                <table style={{width:"100%"}}>
                                                    <tr>
                                                        <td style={{borderLeft:"1px solid lightgrey",borderTop:"1px solid lightgrey",
                                                            padding:5,display:"flex",flexDirection:"row",gap:10,justifyContent:"space-between"}}>
                                                            <div>Item price</div>
                                                            <Button color="primary" style={{padding:"2px 10px",fontSize:14}} onClick={()=>{
                                                                this.setState({editProductPriceModal:true})
                                                            }}>
                                                                Edit
                                                            </Button>
                                                        </td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            {parseFloat(this.state.theOnlineInvoice.productList[0][2]).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{border:"1px solid lightgrey",padding:5}}>Promo voucher discount</td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            -{parseFloat(this.state.predictedPromoValue).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{}}>
                                                        <td style={{border:"1px solid lightgrey",padding:5}}>Pay with point</td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            -{parseFloat(this.state.pointToUse).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{}}>
                                                        <td style={{border:"1px solid lightgrey",padding:5}}>Total after discount</td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            {parseFloat(this.state.predictedBeforeTax-this.state.pointToUse).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{border:"1px solid lightgrey",padding:5}}>Tax</td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            {parseFloat(this.state.predictedTax).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{border:"1px solid lightgrey",padding:5}}>Total after tax</td>
                                                        <td style={{border:"1px solid lightgrey",padding:5,textAlign:"right"}}>
                                                            {parseFloat(this.state.predictedAfterTax).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </table>
                                                :
                                                null
                                            }

                                            <Button color="warning" style={{width:"100%"}} onClick={()=>{
                                                this.updateQuotation()
                                            }}>
                                                Verify Payment
                                            </Button>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                    this.state.buyCourseSelectedProduct!=null?
                                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                            this.clearBuyCourseModal()
                                        }}>
                                            Clear
                                        </Button>
                                        :
                                        null
                                    }
                                    <Button color="light" style={{border:"none",backgroundColor:"salmon",flex:1,color:"white"}} onClick={()=>{
                                        this.setState({buyCourseForClientModal:false,checkedUser:null,checkedEmployee:null})
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :null
                    }
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.confirmReleaseWWalletModal} toggle={()=>this.setState({confirmReleaseWWalletModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>Confirm Release C Wallet</div>
                        <div style={{marginTop:15,width:"100%"}}>
                            <div>Upload excel filled with payment numbers:</div>
                            <Input type="file" style={{}} onChange={(e)=>{
                                let file=e.target.files[0]
                                this.setState({uploadConfirmReleaseExcel:file})
                            }} />
                            <div style={{marginTop:10}}>
                                <div>Select C Wallet Release Log to confirm payment:</div>
                                <div style={{display:"flex",flexDirection:"column",gap:5}}>
                                {
                                this.state.releaseWWalletLogList.map((log)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,alignItems:"center",width:"100%"}}>
                                            <div style={{cursor:"pointer",display:"flex",flexDirection:"column",padding:5,flex:1,borderRadius:5,
                                                border:this.state.selectedRWWL==log.id?"1.5px solid red":"1px solid lightgrey"}} onClick={()=>{
                                                this.setState({selectedRWWL:log.id})
                                            }}>
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                                                    <div>Release ID: {log.id}</div>
                                                    <div style={{color:"grey",fontSize:12}}>Create date: {log.createDate}</div>
                                                </div>
                                                <div>Release Number: {log.paidNumber} / {log.releaseNumber} agents</div>
                                                <div>
                                                    Total: RM{log.paidTotal.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits: 2})} / RM
                                                    {log.releaseTotal.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits: 2})}
                                                </div>
                                            </div>
                                            {
                                            log.releaseExcel!=null?
                                                <a style={{color:"#3F80F7",textDecoration:"underline",fontSize:12,padding:"2px 5px",lineHeight:1,}}
                                                    href={log.releaseExcel} target="_blank">
                                                    Download Excel
                                                </a>
                                                :
                                                null
                                            }
                                        </div>
                                    )
                                })
                                }
                                </div>

                            </div>
                        </div>
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%"}}>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",marginRight:5,flex:1}} onClick={()=>{
                                this.setState({confirmReleaseWWalletModal:false})
                            }}>
                                Close
                            </Button>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.submitReleaseWWalletLogPaymentProof()
                            }}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.downloadReleaseWWalletExcelModal} toggle={()=>this.setState({downloadReleaseWWalletExcelModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>Release Agent C Wallet</div>
                        <div style={{marginTop:10,width:"100%"}}>
                            C Wallet of the selected agents had been deducted to 0,<br/>please download and keep this excel file for to process further bank transfer.
                        </div>
                        <a style={{marginTop:5,width:"100%"}} href={this.state.excelLink} target="_blank">
                            {this.state.excelLink}
                        </a>
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%"}}>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",marginRight:5,flex:1}} onClick={()=>{
                                this.setState({downloadReleaseWWalletExcelModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.editProductPriceModal}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>Edit Product Price</div>
                        <Input style={{width:"100%"}} value={this.state.productPrice} 
                            onChange={(e)=>{this.setState({productPrice:e.target.value.replace(/[^0-9.-]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.').replace(/(?!^)-/g, '')})}} />
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.updateProductPrice()
                            }}>
                                Update
                            </Button>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",flex:1}} onClick={()=>{
                                this.setState({
                                    editProductPriceModal:false,
                                    productPrice:parseFloat(this.state.theOnlineInvoice.productList[0][2]).toFixed(2),
                                })
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.agentWWalletListModal} toggle={()=>this.setState({agentWWalletListModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontWeight:"bold",fontSize:22}}>Release Agent C Wallet</div>
                        <div style={{gap:5,display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,width:"100%"}}>
                            <table style={{width:"100%"}}>
                                <tr style={{borderBottom:"1px solid grey"}}>
                                    <td></td>
                                    <td>Company Name (type)</td>
                                    <td>Agent / Affiliate</td>
                                    <td>C Wallet Amount (RM)</td>
                                </tr>
                                {
                                this.state.agentWWalletList.map((agent,index)=>{
                                    return(
                                        <tr style={{}}>
                                            <td>
                                                <input type="checkbox" checked={agent.checked} onChange={(e)=>{
                                                    let agentWWalletListClone=this.state.agentWWalletList
                                                    agentWWalletListClone[index].checked=!agentWWalletListClone[index].checked
                                                    this.setState({agentWWalletList:agentWWalletListClone})
                                                }} />
                                            </td>
                                            <td>{agent.companyName}</td>
                                            <td>{agent.username} {agent.name}</td>
                                            <td style={{textAlign:"right"}}>
                                                {agent.withdrawWallet.toLocaleString("en-EN",{minimumFractionDigits:2,maximumFractionDigits: 2})}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </table>
                        </div>
                        <div style={{marginTop:20,display:"flex",flexDirection:"row",width:"100%"}}>
                            <Button color="light" style={{color:"white",backgroundColor:"salmon",marginRight:5,flex:1}} onClick={()=>{
                                this.setState({agentWWalletListModal:false,agentWWalletList:[]})
                            }}>
                                Cancel
                            </Button>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.releaseAgentWWallet()
                            }}>
                                Release
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.addTagModal} toggle={()=>this.setState({addTagModal:false})}>
                    {
                    this.state.loading?
                        <div style={{backgroundColor:"rgba(0,0,0,0.5)",width:"100%",height:"100%",position:"absolute"}} />
                        :null
                    }
                    <div style={{padding:20}}>
                        <div>Add Tag</div>
                        <div style={{gap:5,display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10}}>
                            {
                            this.state.generateLinkWithTagTagList.map((tag)=>{
                                return(
                                    <Button color="primary" onClick={()=>{
                                        for(let theTag of this.state.selectedTagList){
                                            if(theTag.title==tag.title){
                                                this.setState({addTagModal:false})
                                                this.generateResultLink()
                                                break
                                            }
                                        }
                                        let tagListClone=this.state.selectedTagList
                                        tagListClone.push(tag)
                                        this.setState({selectedTagList:tagListClone,addTagModal:false},()=>{
                                            this.generateResultLink()
                                        })
                                    }}>
                                        {tag.title}
                                    </Button>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.generateLinkWithTagModal} toggle={()=>this.setState({generateLinkWithTagModal:false})}>
                    {
                    this.state.loading?
                        <div style={{backgroundColor:"rgba(0,0,0,0.5)",width:"100%",height:"100%",position:"absolute"}} />
                        :null
                    }
                    <div style={{padding:20}}>
                        <div>Generate Link with Tag</div>
                        <div style={{display:"flex",flexDirection:"column",marginTop:10}}>
                            <div>Link:</div>
                            <select onChange={(e)=>{
                                this.setState({selectedLink:e.target.value},()=>{
                                    this.generateResultLink()
                                })
                            }}>
                                {
                                this.state.generateLinkWithTagLinkList.map((option,ind)=>{
                                    return(
                                        <option value={option}>{option}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                        <div style={{marginTop:10,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                            <div>Tag:</div>
                            <Button color="primary" style={{padding:"2px 10px",fontSize:13,lineHeight:1}} onClick={()=>{
                                this.setState({addTagModal:true})
                            }}>Add Tag</Button>
                        </div>
                        <div style={{gap:5,display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:5}}>
                            {
                            this.state.selectedTagList.map((tag)=>{
                                return(
                                    <Button color="primary" style={{padding:"2px 5px",lineHeight:1,fontSize:13}} onClick={()=>{
                                        let tagListClone=this.state.selectedTagList
                                        for(let i=0;i<tagListClone.length;i++){
                                            if(tagListClone[i].title==tag.title){
                                                tagListClone.splice(i,1)
                                                this.setState({selectedTagList:tagListClone},()=>{
                                                    this.generateResultLink()
                                                })
                                                break
                                            }
                                        }
                                    }}>
                                        {tag.title}
                                    </Button>
                                )
                            })
                            }
                        </div>

                        <div style={{marginTop:20}}>Result link:</div>
                        <Input style={{width:"100%"}} value={this.state.resultLink} />
                    </div>
                </Modal>

                <Modal isOpen={this.state.executionModal} toggle={()=>this.setState({executionModal:false})}>
                    {
                    this.state.loading?
                        <div style={{backgroundColor:"rgba(0,0,0,0.5)",width:"100%",height:"100%",position:"absolute"}} />
                        :null
                    }
                    <div style={{padding:20}}>
                        <div>{this.state.infoMessage.split("\n").map((line)=>{
                            return(
                                <div>{line}</div>
                            )
                        })}</div>
                        {
                        this.state.contentMessage!=null&&this.state.contentMessage.length>0?
                            <div style={{marginTop:10}}>
                                {
                                this.state.contentMessage.split("\n").map((line)=>{
                                    return(
                                        <div>{line}</div>
                                    )
                                })
                                }
                            </div>
                            :
                            null
                        }
                        {
                        this.state.inputField.map((item,index)=>{
                            return(
                                <div>
                                {
                                item.includes("$")?
                                    <div style={{display:"flex",flexDirection:"column",marginTop:10}}>
                                        <div>
                                            {item.split("&&")[0].substring(1)}:
                                        </div>
                                        <select onChange={(e)=>{
                                            let newInputValue=this.state.inputValue
                                            newInputValue[index]=e.target.value
                                            this.setState({inputValue:newInputValue})
                                        }}>
                                            {
                                            item.split("&&").slice(1).map((option,ind)=>{
                                                return(
                                                    <option value={option}>{option}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                    :
                                item.includes("@")?
                                    <div style={{marginTop:10,display:"flex",flexDirection:"column"}}>
                                        <div>{item.split("&&")[0].substring(1)}</div>
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                                            {
                                            item.split("&&").slice(1).map((option)=>{
                                                return(
                                                    <Button color="light" style={{border:this.state.inputValue[index].indexOf(option)!=-1?"2px solid steelblue":"1px solid lightgrey"}} 
                                                        onClick={()=>{
                                                            let newInputValue=this.state.inputValue
                                                            let pos=newInputValue[index].indexOf(option)
                                                            if(pos==-1){
                                                                newInputValue[index].push(option)
                                                            }else{
                                                                newInputValue[index].splice(pos,1)
                                                            }
                                                            this.setState({inputValue:newInputValue})
                                                        }}
                                                    >
                                                        {option}
                                                    </Button>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    :
                                item.includes("#")?
                                    <textarea style={{marginTop:10,padding:"5px 10px",width:"100%",border:"1px solid lightgrey",borderRadius:3}} 
                                        id={item.substring(1)} placeholder={item.substring(1)} rows={4}
                                        value={this.state.inputValue[index]} onChange={(e)=>{
                                            let newInputValue=this.state.inputValue
                                            newInputValue[index]=e.target.value
                                            this.setState({inputValue:newInputValue})
                                        }}
                                    />
                                    :
                                    <Input style={{marginTop:10}} id={item} placeholder={item}
                                        value={this.state.inputValue[index]} onChange={(e)=>{
                                            let newInputValue=this.state.inputValue
                                            newInputValue[index]=e.target.value
                                            this.setState({inputValue:newInputValue})
                                        }}/>
                                }
                                </div>
                            )
                        })
                        }
                        <div style={{display:"flex",justifyContent:"center"}}>
                            <Button color="primary" style={{marginTop:15,width:"100%"}} onClick={()=>{this.runModalFunction()}}>Submit</Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:500}} isOpen={this.state.changeAgentCompanyTierModal} toggle={()=>{this.setState({changeAgentCompanyTierModal:false})}}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:16,fontWeight:"bold"}}>Change Agent Company and Tier</div>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:7}}>
                            <Input type="tel" value={this.state.askPhoneNum} style={{marginTop:10}} placeholder="Agent Username" onChange={(e)=>{
                                this.setState({askPhoneNum:e.target.value})
                            }} />
                            <Button color="light" style={{border:"1px solid lightgrey",whiteSpace:"nowrap",marginTop:9,fontSize:15}} onClick={()=>{
                                this.checkAgentCompanyTier()
                            }}>
                                Check Original
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",fontSize:16,marginTop:5,marginBottom:20,marginLeft:5}}>
                            <div>Company: {this.state.agentDetails.companyName?this.state.agentDetails.companyName:"-"}</div>
                            <div>Tier: {this.state.agentDetails.tierName?this.state.agentDetails.tierName:"-"}
                        </div>
                        </div>
                        <Input type="select" value={this.state.askCompanyID} style={{marginTop:10}} onChange={(e)=>{
                            this.setState({askCompanyID:e.target.value},()=>{this.getAgentTierList()})
                        }}>
                            {this.state.askCompanyID === "" && (
                                <option value="">Select Company</option>
                            )}
                            {this.state.agentCompanyList.map((company) => (
                                <option key={company.id} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </Input>
                        <Input type="select" value={this.state.askTierID} style={{marginTop:10}} onChange={(e)=>{
                            this.setState({askTierID:e.target.value})
                        }}>
                            <option value="">Select Tier</option>
                            {this.state.combinedTierList.map((tier) => (
                                <option key={tier.id} value={tier.id}>
                                    {tier.name}
                                </option>
                            ))}
                        </Input>

                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            <Button color="primary" style={{width:"100%"}} onClick={()=>{
                                this.changeAgentCompanyTier()
                            }}>
                                Change
                            </Button>
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({changeAgentCompanyTierModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="execution" />
                <div style={{padding:10}}>
                    <div style={{marginBottom:10}}>User Registration</div>
                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                        <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewUser()}}>
                            Create New User
                        </Button>
                        {
                        reactLocalStorage.get("job")=="Sales Supervisor"?
                            <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.setSPClaimReferral()}}>
                                Set SP and Claim Referral
                            </Button>
                            :
                            null
                        }
                        <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                            this.getFranchiseSessionList()
                        }}>
                            Purchase and Auto Signup
                        </Button>
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.changePIC())}>
                            Change PIC
                        </Button>
                        {
                        GLOBAL.checkPermission("ZTK6+Fhr")? //create corporate account
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.createCorporateAccount())}>
                                Create Corporate Account
                            </Button>
                            :
                            null
                        }
                        {
                        GLOBAL.checkPermission(":+i619BP")? //open corp account for agency
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.openCorpAccForAgency())}>
                                Open Corporate Acc for Agency
                            </Button>
                            :
                            null
                        }
                    </div>
                </div>
                {
                reactLocalStorage.get("job")=="Sales Supervisor"?
                    <div style={{padding:10}}>
                        <div style={{marginBottom:10}}>Promo Voucher</div>
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.grantPromoCode()}}>
                            Grant Promo Voucher
                        </Button>
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.grantTicket()}}>
                            Grant 系统管理 速成班 Ticket
                        </Button>
                    </div>
                    :
                    null
                }
                <div style={{padding:10}}>
                    <div style={{marginBottom:10}}>Badge & Certificate</div>
                    {
                    reactLocalStorage.get("job")=="Sales Supervisor"?
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.grantBadge()}}>
                            Grant Badge
                        </Button>
                        :
                        null
                    }
                    {
                    reactLocalStorage.get("job")=="Sales Supervisor"?
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.removeBadge()}}>
                            Remove Badge
                        </Button>
                        :
                        null
                    }
                    {
                    reactLocalStorage.get("job")=="Sales Supervisor"?
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.grantCertificate()}}>
                            Grant Certificate
                        </Button>
                        :
                        null
                    }
                    {
                    reactLocalStorage.get("job")=="Sales Supervisor"?
                        <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.removeCertificate()}}>
                            Remove Certificate
                        </Button>
                        :
                        null
                    }
                    <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.setExpiry()}}>
                        Set Expiry
                    </Button>
                </div>
                {
                reactLocalStorage.get("job")=="Sales Supervisor"?
                    <div style={{padding:10}}>
                        <div style={{marginBottom:10}}>Transaction</div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,flexWrap:"wrap"}}>
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.validateAndGrantPendingInvoice()}}>
                                Validate and Grant Pending Invoice
                            </Button>
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.addMoney()}}>
                                Grant Referral Bonus
                            </Button>
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.buyCourseForClientWithCredit1()}}>
                                Buy Course for client
                            </Button>
                        </div>
                    </div>
                    :
                    null
                }
                {
                reactLocalStorage.get("job")=="Sales Supervisor"?
                    <div style={{padding:10}}>
                        <div style={{marginBottom:10}}>Wallet</div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,flexWrap:"wrap"}}>
                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:10,display:"flex",flexDirection:"column",gap:5}}>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.addMoneyOnly()}}>
                                    Add Money to R Wallet
                                </Button>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.deductMoney()}}>
                                    Deduct Money From R Wallet
                                </Button>
                            </div>

                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:10,display:"flex",flexDirection:"column",gap:5}}>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.addMoneyToPWallet()}}>
                                    Add Money to P Wallet
                                </Button>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.deductMoneyFromPWallet()}}>
                                    Deduct Money From P Wallet
                                </Button>
                            </div>

                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:10,display:"flex",flexDirection:"column",gap:5}}>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.addMoneyToPartialWallet()}}>
                                    Add Money to Partial Wallet
                                </Button>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.deductMoneyFromPartialWallet()}}>
                                    Deduct Money From Partial Wallet
                                </Button>
                            </div>

                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:10,display:"flex",flexDirection:"column",gap:5}}>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.addMoneyToCashWallet()}}>
                                    Add Money to Cash Wallet (充值)
                                </Button>
                                <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.deductMoneyFromCashWallet()}}>
                                    Deduct Money From Cash Wallet (充值)
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {
                reactLocalStorage.get("job")=="Sales Supervisor"?
                    <div style={{padding:10}}>
                        <div style={{marginBottom:10}}>Course Credit</div>
                        <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.grantCourseCredit()}}>
                            Grant Course Credit
                        </Button>
                        <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.removeCourseCredit()}}>
                            Remove Course Credit
                        </Button>
                        <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{this.transferCourseCredit()}}>
                            Transfer Course Credit
                        </Button>
                    </div>
                    :
                    null
                }
                <div style={{padding:10}}>
                    <div style={{marginBottom:10}}>Agent</div>
                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.createServiceAgentCompany())}>
                            Create Service Agent Company
                        </Button>
                        {
                        GLOBAL.checkPermission("=^gdhxIu")? //create referral agent company
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.createReferralAgentCompany())}>
                                Create Referral Agent Company
                            </Button>
                            :
                            null
                        }
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.linkAgencyGDShop())}>
                            Link Agency GD Shop
                        </Button>
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.assignAgentLeader())}>
                            Assign Agent Leader
                        </Button>
                        {
                        reactLocalStorage.get("job")=="Sales Supervisor"?
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                                this.releaseAgentCompanyCommission()
                            }}>
                                Release Company Wallet Commission
                            </Button>
                            :
                            null
                        }
                        {
                        reactLocalStorage.get("job")=="Sales Supervisor"?
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                                this.releaseAgentCompanyProfitSharing()
                            }}>
                                Release Profit Sharing
                            </Button>
                            :
                            null
                        }
                        {
                        reactLocalStorage.get("job")=="Sales Supervisor"?
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                                this.getAgentWWalletList()
                            }}>
                                Release Agent C Wallet
                            </Button>
                            :
                            null
                        }
                        {
                        reactLocalStorage.get("job")=="Sales Supervisor"?
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                                this.getAgentWWalletReleaseLog()
                            }}>
                                Confirm Agent C Wallet Payment
                            </Button>
                            :
                            null
                        }
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                            this.getAgentCompanyList()
                        }}>
                            Change Agent Company and Tier
                        </Button>
                        <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                            this.recalculateInvoiceCommission()
                        }}>
                            Recalculate Invoice Commission
                        </Button>
                        {
                        GLOBAL.checkPermission("tBb+LGd:")? //Upgrade Agency from Referral to Service
                            <Button color="light" style={{borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>{
                                this.upgradeAgencyFromReferralToService()
                            }}>
                                Upgrade Agency from Referral to Service
                            </Button>
                            :
                            null
                        }
                    </div>
                </div>
                <div style={{padding:10}}>
                    <div style={{marginBottom:10}}>Marketing</div>
                    <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.generateLinkWithTag())}>
                        Generate link with tag
                    </Button>
                </div>
                {
                reactLocalStorage.get("job")=="Sales Supervisor"?
                    <div style={{padding:10}}>
                        <div style={{marginBottom:10}}>Invoice and Receipt</div>
                        <Button color="light" style={{marginRight:10, borderColor:"lightgrey", borderStyle:"solid", borderWidth:1}} onClick={()=>(this.generateInvoice())}>
                            Generate Temp Invoice
                        </Button>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

Execution = Radium(Execution);

let styles = {

}
