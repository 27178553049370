import React from 'react';
import {Input,Button,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'
import WalletLog from '../components/walletLog.jsx';

import {BsFillInfoCircleFill} from 'react-icons/bs'
import {RiDeleteBin2Line} from "react-icons/ri"

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            loadingFromChild:null,
            companyDetail:null,
            payToCompany:true,
            rWallet:0.0,
            pWallet:0.0,
            wWallet:0.0,
        
            PtoRWalletModal:false,
            PtoRTransfer:0,
            PtoWWalletModal:false,
            PtoWTransfer:0,
            WtoPWalletModal:false,
            WtoCTransfer:0,
            WtoRWalletModal:false,
            WtoRTransfer:0,
            transferToOtherModal:false,
            transferToOther:0,
            receiverPhone:"",

            walletLogModal:false,
            walletLogList:[],

            wWalletInfoModal:false,

            memberAttendanceList:[],
            offlineEventBookingList:[],

            purchaseSignupModal:false,
            franchiseSessionList:[],
            // smpSessionList:[],
            // mmpSessionList:[],
            // tmpSessionList:[],
            // ceoSessionList:[],
            franchisePurchaseSignupLink:null,
            selectedCourseTitle:null,
            selectedCourseSession:null,
            purchaseSignupPage:"phone",
            referCourseClientName:null,
            referCourseClientPhone:null,
            referCourseClientEmail:null,
            referCourseClientBillingAddress:null,
            referCourseClientNameError:false,
            referCourseClientPhoneError:false,
            referCourseClientEmailError:false,

            generateWhatsappSignupLinkModal:false,
            generateLinkUsername:null,
            generateLinkName:null,

            sendWhatsappSignupLinkPhone:null,
            sendWhatsappSignupLinkName:null,
            whatsappSignupList:[],

            eventSummary:[],
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(let input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            // if(param=="agent"){  //seek user by id
            //     this.agentID=value
            // }
        }

        this.getWalletData()
    }

    handleChildLoading = (loading) => {
        // Update the parent state with data from the child
        this.setState({ loadingFromChild: loading });      
      };

    getWalletData=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getwalletdata`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse date time
                for(let attendance of response.data.memberAttendanceList){
                    let rawDateSplit=attendance.signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    attendance.signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                    let startDateSplit=attendance.courseSessionDate.split("-")
                    attendance.courseSessionDateDisplay=startDateSplit[2]+"."+startDateSplit[1]+"."+startDateSplit[0]
                    let endDateSplit=attendance.courseSessionEndDate.split("-")
                    attendance.courseSessionEndDateDisplay=endDateSplit[2]+"."+endDateSplit[1]+"."+endDateSplit[0]
                }
                for(let offlineEventBooking of response.data.offlineEventBookingList){
                    let rawDateSplit=offlineEventBooking.signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    offlineEventBooking.signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                    let startDateSplit=offlineEventBooking.offlineEvent.startDate.split("T")[0].split("-")
                    let startTimeSplit=offlineEventBooking.offlineEvent.startDate.split("T")[1].split(":")
                    offlineEventBooking.offlineEvent.startDateDisplay=startDateSplit[2]+"."+startDateSplit[1]+"."+startDateSplit[0]+"  "+startTimeSplit[0]+":"+startTimeSplit[1]
                    let endDateSplit=offlineEventBooking.offlineEvent.endDate.split("T")[0].split("-")
                    let endTimeSplit=offlineEventBooking.offlineEvent.endDate.split("T")[1].split(":")
                    offlineEventBooking.offlineEvent.endDateDisplay=endDateSplit[2]+"."+endDateSplit[1]+"."+endDateSplit[0]+"  "+endTimeSplit[0]+":"+endTimeSplit[1]
                }
                for(let token of response.data.whatsappSignupList){
                    let rawDateSplit=token.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    token.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                    let rawDateSplit2=token.lastSent.split("T")
                    let dateSplit2=rawDateSplit2[0].split("-")
                    let timeSplit2=rawDateSplit2[1].split(":")
                    token.lastSent=dateSplit2[2]+"."+dateSplit2[1]+"."+dateSplit2[0]+"  "+timeSplit2[0]+":"+timeSplit2[1]
                }

                this.setState({
                    loading:false,
                    companyDetail:response.data.companyDetail,
                    payToCompany:response.data.companyDetail.payToCompany,
                    rWallet:response.data.rWallet,
                    pWallet:response.data.pWallet,
                    wWallet:response.data.wWallet,
                    memberAttendanceList:response.data.memberAttendanceList,
                    offlineEventBookingList:response.data.offlineEventBookingList,
                    whatsappSignupList:response.data.whatsappSignupList,
                })
                this.summarizeEvents(this.state.offlineEventBookingList)
            })
            .catch(error =>{
                alert("Load wallet data error\n"+error)
                this.setState({loading:false})
            })
    }

    transferFromPToRWallet=()=>{
        this.setState({loading:true})
        const payload = {
            amount:this.state.PtoRTransfer,
        }
        axios
            .post(`sales/transferfromptorwallet`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    PtoRWalletModal:false,
                })
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Insufficient credit.")
                        this.setState({PtoRWalletModal:false})
                        this.getWalletData()
                        return
                    }
                }
                alert("Transfer from P to R wallet error\n"+error)
                this.setState({loading:false})
            })
    }

    transferFromPToWWallet=()=>{
        this.setState({loading:true})
        const payload = {
            amount:this.state.PtoWTransfer,
        }
        axios
            .post(`sales/transferfromptowwallet`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    PtoWWalletModal:false,
                })
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Insufficient credit.")
                        this.setState({PtoWWalletModal:false})
                        this.getWalletData()
                        return
                    }
                    if(error.response.status==401){
                        alert("Permission denied.")
                        this.setState({PtoWWalletModal:false})
                        this.getWalletData()
                        return
                    }
                }
                alert("Transfer from P to C wallet error\n"+error)
                this.setState({loading:false})
            })
    }

    transferFromWToRWallet=()=>{
        this.setState({loading:true})
        const payload = {
            amount:this.state.WtoRTransfer,
        }
        axios
            .post(`sales/transferfromwtorwallet`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    WtoRWalletModal:false,
                })
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Insufficient credit.")
                        this.setState({WtoRWalletModal:false})
                        this.getWalletData()
                        return
                    }
                    if(error.response.status==401){
                        alert("Permission denied.")
                        this.setState({WtoRWalletModal:false})
                        this.getWalletData()
                        return
                    }
                }
                alert("Transfer from C to R wallet error\n"+error)
                this.setState({loading:false})
            })
    }

    // transferFromWToPWallet=()=>{
    //     this.setState({loading:true})
    //     const payload = {
    //         amount:this.state.WtoCTransfer,
    //     }
    //     axios
    //         .post(`sales/transferfromwtopwallet`,qs.stringify(payload),{timeout:30000})
    //         .then(async response => {
    //             this.setState({
    //                 WtoPWalletModal:false,
    //             })
    //             this.getWalletData()
    //         })
    //         .catch(error =>{
    //             if(error.response){
    //                 if(error.response.status==444){
    //                     alert("Insufficient credit.")
    //                     this.setState({WtoPWalletModal:false})
    //                     this.getWalletData()
    //                     return
    //                 }
    //             }
    //             alert("Transfer from C to P wallet error\n"+error)
    //             this.setState({loading:false})
    //         })
    // }

    transferToOtherRWallet=()=>{
        this.setState({loading:true})
        const payload = {
            amount:this.state.transferToOther,
            receiverPhone:this.state.receiverPhone,
        }
        axios
            .post(`sales/transfertootherrwallet`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    transferToOtherModal:false,
                })
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Insufficient credit.")
                        this.setState({WtoPWalletModal:false})
                        this.getWalletData()
                        return
                    }
                }
                alert("Transfer to other R wallet error\n"+error)
                this.setState({loading:false})
            })
    }

    fallbackCopyTextToClipboard=(text)=>{
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('分享信息成功copy到clipboard');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }

    generateAttendanceUI=(attendance,startDate,endDate)=>{
        let sDate=new Date(startDate)
        let eDate=new Date(endDate)
        let difference = Math.floor((eDate.getTime()-sDate.getTime())/(1000*3600*24))+1
        let attendanceBadge=[]
        for(let i=0;i<difference;i++){
            let theDate=new Date(sDate)
            theDate.setDate(sDate.getDate()+i)
            attendanceBadge.push(
                <div style={{width:20,height:20,textAlign:"center",display:"flex",flexDirection:"row",alignItems:"center",
                    justifyContent:"center",borderRadius:"50%",
                    backgroundColor:
                        attendance!=null&&attendance[theDate.getDate()]!=undefined&&attendance[theDate.getDate()]==true?
                            "green"
                            :
                            "red"
                    ,
                }}>
                    <div style={{fontSize:13,color:"white"}}>{theDate.getDate()}</div>
                </div>
            )
        }
        return(
            <div style={{display:"flex",flexDirection:"row",gap:3,justifyContent:"center"}}>
                {attendanceBadge}
            </div>
        )
    }

    validateEmail=(email)=>{
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    getFranchiseSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getfranchisesessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse date
                for(let franchise of response.data){
                    let rawDateSplit=franchise.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    franchise.startDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    franchiseSessionList:response.data,
                    purchaseSignupModal:true,
                    selectedCourseTitle:"连锁系统",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get franchise session list error\n"+error)
                this.setState({loading:false})
            })
    }

    getSystemSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getsystemsessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse date
                for(let system of response.data){
                    let rawDateSplit=system.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    system.startDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    systemSessionList:response.data,
                    purchaseSignupModal:true,
                    selectedCourseTitle:"系统管理 实体课",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get system session list error\n"+error)
                this.setState({loading:false})
            })
    }

    getSMPSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getsmpsessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    smpSessionList:response.data,
                    selectedCourseTitle:"战略三力",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get SMP session list error\n"+error)
                this.setState({loading:false})
            })
    }

    getMMPSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getmmpsessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    mmpSessionList:response.data,
                    selectedCourseTitle:"营销三力",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get MMP session list error\n"+error)
                this.setState({loading:false})
            })
    }

    getTMPSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/gettmpsessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    tmpSessionList:response.data,
                    selectedCourseTitle:"组织三力",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get TMP session list error\n"+error)
                this.setState({loading:false})
            })
    }

    getCEOSessionList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getceosessionlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    ceoSessionList:response.data,
                    selectedCourseTitle:"总裁三力",
                    selectedCourseSession:null,
                })
                if(response.data.length>0){
                    this.setState({selectedCourseSession:response.data[0].id})
                }else{
                    this.setState({selectedCourseSession:null})
                }
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get CEO session list error\n"+error)
                this.setState({loading:false})
            })
    }

    checkPurchaseSignupPhone=()=>{
        if(this.state.referCourseClientPhone==null||this.state.referCourseClientPhone.length==0){
            alert("Please enter client phone number")
            return
        }
        if((this.state.selectedCourseTitle=="连锁系统"||this.state.selectedCourseTitle=="系统管理 实体课")&&this.state.selectedCourseSession==null){
            alert("Please select a course session")
            return
        }

        this.setState({loading:true})
        const payload = {
            clientPhone:this.state.referCourseClientPhone,
            courseTitle:this.state.selectedCourseTitle,
        }
        axios
            .post(`sales/checkpurchasesignupphone`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    purchaseSignupPage:"member",
                    referCourseClientName:response.data.clientName,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //non member
                        this.setState({
                            loading:false,
                            purchaseSignupPage:"nonmember",
                        })
                        return
                    }else if(error.response.status==445){   //already has the badge
                        alert("User member already has the course")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Check purchase signup phone error\n"+error)
                this.setState({loading:false})
            })
    }

    sendWhatsappSignupLink=()=>{
        //validate
        if(this.state.sendWhatsappSignupLinkPhone==null){
            alert("Please fill in phone number")
            return
        }
        if(this.state.sendWhatsappSignupLinkName==null){
            alert("Please fill in name of client")
            return
        }

        this.setState({loading:true})
        const payload = {
            phone:this.state.sendWhatsappSignupLinkPhone,
            name:this.state.sendWhatsappSignupLinkName,
        }
        axios
            .post(`sales/sendwhatsappsignuplink`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                alert("Whatsapp signup link sent to "+this.state.sendWhatsappSignupLinkPhone+" "+this.state.sendWhatsappSignupLinkName)
                this.setState({
                    generateWhatsappSignupLinkModal:false,
                },()=>{
                    this.getWalletData()
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("User is already a member")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("There is existing signup request, please use the resend button instead")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==447){
                        alert("Whatsapp sending failed")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Send whatsapp signup link error\n"+error)
                this.setState({loading:false})
            })
    }

    resendWhatsappSignup=(token)=>{
        this.setState({loading:true})
        const payload = {
            id:token.id,
        }
        axios
            .post(`sales/resendwhatsappsignuplink`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                alert("Whatsapp signup link sent to "+token.phone+" "+token.name)
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("User is already a member")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==447){
                        alert("Whatsapp sending failed")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Resend whatsapp signup link error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteWhatsappSignup=(token)=>{
        this.setState({loading:true})
        const payload = {
            id:token.id,
        }
        axios
            .post(`sales/deletewhatsappsignuplink`,qs.stringify(payload),{timeout:20000})
            .then(async response => {
                alert("Whatsapp signup link deleted: "+token.phone+" "+token.name)
                this.getWalletData()
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert("Delete whatsapp signup link error\n"+error)
                this.setState({loading:false})
            })
    }

    summarizeEvents = (bookingList) => {
        if (!bookingList || !Array.isArray(bookingList)) return [];
        
        const eventSummary = [];
    
        bookingList.forEach(booking => {
            if (!booking.offlineEvent) return;
    
            const eventId = booking.offlineEvent.id;
    
            // Check if the event already exists in the summary array
            let event = eventSummary.find(e => e.id === eventId);
            if (!event) {
                // If the event doesn't exist, add a new entry
                event = {
                    ...booking.offlineEvent,
                    totalParticipants: 1,
                    attendedParticipants: booking.attend?1:0,
                    bookings: [booking],
                    
                };
                eventSummary.push(event);
            } else {
                // If the event exists, update it
                event.totalParticipants += 1;
                if(booking.attend){
                    event.attendedParticipants+=1;
                }
                event.bookings.push(booking);
            }
        });
        //determine how many days span is each courses session, used for json attendance record of each day
        for(let offlineEvent of eventSummary){
            let attendance={}
            let startDate=new Date(offlineEvent.startDate)
            offlineEvent.eventDateObj=startDate.setHours(0,0,0)
            let endDate=new Date(offlineEvent.endDate)
            offlineEvent.endDateObj=endDate.setHours(23,59,59)
            let currentDate=startDate
            while(currentDate<endDate){
                attendance[""+currentDate.getDate()]=0
                currentDate.setDate(currentDate.getDate() + 1)
            }
            offlineEvent.bookings.attendance=attendance
        }

        this.setState({ eventSummary }); 
    };
    
    calculateAndGenerateAttendance=(event)=>{
        //checkpoint

        let theAttendance=event.bookings.attendance        
        let totalParticipant=event.totalParticipants
        let participant=event.bookings
        let ele=[]
        Object.keys(theAttendance).forEach(function(key,index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object

            //count
            theAttendance[key]=0
            for(let csd of participant){
                if(csd.attendance!=null&&csd.attendance[key]!=null&&csd.attendance[key]==true){
                    theAttendance[key]+=1
                }
            }

            //generate ui component
            ele.push(
                <div style={{padding:"5px 10px",border:"1px solid lightgrey"}}>
                    <i style={{color:"grey"}}>{key}th</i> : {theAttendance[key]} / {totalParticipant}
                </div>
            )
        })
        return ele
    }
    
    render(){
        return(
            <div style={{}}>
                {
                this.state.loading || this.state.loadingFromChild?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.generateWhatsappSignupLinkModal} toggle={()=>this.setState({generateWhatsappSignupLinkModal:false})} style={{}}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Generate & Send Whatsapp Signup Link</div>
                        <div style={{marginBottom:10}}>
                            <div>Phone:</div>
                            <Input value={this.state.sendWhatsappSignupLinkPhone} onChange={(e)=>{
                                this.setState({sendWhatsappSignupLinkPhone:e.target.value.replace(/\D/g,"")})
                            }} />
                            <div>Name:</div>
                            <Input value={this.state.sendWhatsappSignupLinkName} onChange={(e)=>{
                                this.setState({sendWhatsappSignupLinkName:e.target.value})
                            }} />
                            <div>Referrer:</div>
                            <div>{reactLocalStorage.get("username")+" "+reactLocalStorage.get("name")}</div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",marginTop:20,gap:10,width:"100%"}}>
                            <Button color="primary" style={{}} onClick={()=>{
                                this.sendWhatsappSignupLink()
                            }}>
                                Send
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({generateWhatsappSignupLinkModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.purchaseSignupModal}
                    toggle={()=>{
                        this.setState({
                            purchaseSignupModal:false,
                            purchaseSignupPage:"phone",
                            referCourseClientName:null,
                            referCourseClientPhone:null,
                            referCourseClientEmail:null,
                            referCourseClientBillingAddress:null,
                            referCourseClientNameError:false,
                            referCourseClientPhoneError:false,
                            referCourseClientEmailError:false,
                        })
                    }}
                    style={{maxWidth:600}}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Purchase and Signup</div>
                        <div style={{marginBottom:10,gap:10,display:"flex",flexDirection:"column",width:"100%"}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap",gap:10,marginTop:20}}>
                                {/*
                                <Button color={this.state.selectedCourseTitle=="连锁系统"?"primary":"secondary"} style={{whiteSpace:"nowrap",}} onClick={()=>{
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.getFranchiseSessionList()
                                }}>
                                    连锁系统
                                </Button>
                                */}
                                {/*
                                <Button color={this.state.selectedCourseTitle=="系统管理 实体课"?"primary":"secondary"} style={{whiteSpace:"nowrap",}} onClick={()=>{
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.getSystemSessionList()
                                }}>
                                    系统管理 实体课
                                </Button>
                                */}
                                <Button color={this.state.selectedCourseTitle=="战略三力"?"primary":"secondary"} style={{whiteSpace:"nowrap",}} onClick={()=>{
                                    // this.getSMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"战略三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    战略三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="营销三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getMMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"营销三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    营销三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="组织三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getTMPSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"组织三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    组织三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="模式三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"模式三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    模式三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle=="总裁三力"?"primary":"secondary"} style={{whiteSpace:"nowrap"}} onClick={()=>{
                                    // this.getCEOSessionList()
                                    if(this.state.purchaseSignupPage!="phone"){
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle:"总裁三力",
                                        selectedCourseSession:null,
                                    })
                                }}>
                                    总裁三力
                                </Button>
                                <Button color={this.state.selectedCourseTitle == "系统管理学配套" ? "primary" : "secondary"} style={{ whiteSpace: "nowrap" }} onClick={() => {
                                    if (this.state.purchaseSignupPage != "phone") {
                                        return  //do not allow changing of checked course in other modes
                                    }

                                    this.setState({
                                        selectedCourseTitle: "系统管理学配套",
                                        selectedCourseSession: null,
                                    })
                                }}>
                                    系统管理学配套
                                </Button>
                            </div>
                            {/*
                            this.state.selectedCourseTitle=="连锁系统"?
                                <div>
                                    <div>Select Session: </div>
                                    <select disabled={this.state.purchaseSignupPage!="phone"} onChange={(e)=>{
                                        this.setState({selectedCourseSession:e.target.value})
                                    }}>
                                        {
                                        this.state.franchiseSessionList.map((option,ind)=>{
                                            return(
                                                <option value={option.id}>{option.startDate+" - "+option.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                :
                            this.state.selectedCourseTitle=="系统管理 实体课"?
                                <div>
                                    <div>Select Session: </div>
                                    <select disabled={this.state.purchaseSignupPage!="phone"} onChange={(e)=>{
                                        this.setState({selectedCourseSession:e.target.value})
                                    }}>
                                        {
                                        this.state.systemSessionList.map((option,ind)=>{
                                            return(
                                                <option value={option.id}>{option.startDate+" - "+option.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                :
                                null
                            */}

                            <div>Referrer & PIC: {reactLocalStorage.get("username")+" "+reactLocalStorage.get("name")}</div>

                            {
                            this.state.purchaseSignupPage=="phone"?
                                <div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",flexWrap:"nowrap",gap:10,alignItems:"center"}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                            <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                            <Input value={this.state.referCourseClientPhone} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                                this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                            }}/>
                                        </div>
                                        <Button color="primary" style={{whiteSpace:"nowrap"}} onClick={()=>{
                                            this.checkPurchaseSignupPhone()
                                        }}>
                                            Check Number
                                        </Button>
                                    </div>
                                </div>
                                :
                            this.state.purchaseSignupPage=="member"?
                                <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                    <div style={{color:"green"}}>{this.state.referCourseClientPhone} is already a member</div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                        <Input value={this.state.referCourseClientPhone} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                        }}/>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Name:</div>
                                        <Input value={this.state.referCourseClientName} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientNameError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientName:e.target.value})
                                        }}/>
                                    </div>

                                    <Button color="primary" style={{}} onClick={()=>{
                                        //do not need to validate

                                        let skuIDString=""
                                        if(this.state.selectedCourseTitle=="连锁系统"){
                                            skuIDString="sku=franchise&franchiseID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="系统管理 实体课"){
                                            skuIDString="sku=system&systemID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="战略三力"){
                                            skuIDString="sku=SMP"
                                        }else if(this.state.selectedCourseTitle=="营销三力"){
                                            skuIDString="sku=MMP"
                                        }else if(this.state.selectedCourseTitle=="组织三力"){
                                            skuIDString="sku=TMP"
                                        }else if(this.state.selectedCourseTitle=="模式三力"){
                                            skuIDString="sku=BMP"
                                        }else if(this.state.selectedCourseTitle=="总裁三力"){
                                            skuIDString="sku=CEO"
                                        }
                                        let introString="&intro="+reactLocalStorage.get("referCode")
                                        let memberString="&member="+this.state.referCourseClientPhone

                                        let signupLink=""
                                        if(this.state.selectedCourseTitle=="系统管理学配套"){
                                            signupLink="https://enlliance.com/sysmanagementpackage?"+introString.substring(1)+memberString
                                        }else{
                                            signupLink="https://enlliance.com/payment?"+skuIDString+introString+memberString
                                        }
                                        signupLink=encodeURI(signupLink)

                                        this.setState({
                                            purchaseSignupPage:"generate",
                                            franchisePurchaseSignupLink:signupLink,
                                        })
                                    }}>
                                        Generate Link
                                    </Button>
                                </div>
                                :
                            this.state.purchaseSignupPage=="nonmember"?
                                <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                    <div style={{color:"salmon"}}>{this.state.referCourseClientPhone} is not a member</div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Phone:</div>
                                        <Input value={this.state.referCourseClientPhone} readOnly={true} style={{width:"100%",borderColor:this.state.referCourseClientPhoneError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientPhone:e.target.value.replace(/\D/g,"")})
                                        }}/>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Name:</div>
                                        <Input value={this.state.referCourseClientName} style={{width:"100%",borderColor:this.state.referCourseClientNameError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientName:e.target.value})
                                        }}/>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"nowrap",gap:10}}>
                                        <div style={{whiteSpace:"nowrap"}}>*Client Email:</div>
                                        <Input value={this.state.referCourseClientEmail} style={{width:"100%",borderColor:this.state.referCourseClientEmailError?"salmon":"lightgrey"}} onChange={(e)=>{
                                            this.setState({referCourseClientEmail:e.target.value})
                                        }}/>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <div style={{whiteSpace:"nowrap"}}>Client Billing Address:</div>
                                        <textarea rows={3} value={this.state.referCourseClientBillingAddress} style={{width:"100%"}} onChange={(e)=>{
                                            this.setState({referCourseClientBillingAddress:e.target.value})
                                        }}/>
                                    </div>

                                    <Button color="primary" style={{}} onClick={()=>{
                                        //validate
                                        let referCourseClientNameError=false
                                        if(this.state.referCourseClientName==null||this.state.referCourseClientName.trim().length==0){
                                            referCourseClientNameError=true
                                        }
                                        let referCourseClientPhoneError=false
                                        if(this.state.referCourseClientPhone==null||this.state.referCourseClientPhone.trim().length==0){
                                            referCourseClientPhoneError=true
                                        }
                                        let referCourseClientEmailError=false
                                        if(this.state.referCourseClientEmail==null||!this.validateEmail(this.state.referCourseClientEmail)){
                                            referCourseClientEmailError=true
                                        }
                                        // let referCourseClientBillingAddressError=false
                                        // if(this.state.referCourseClientBillingAddress==null||this.state.referCourseClientBillingAddress.length==0){
                                        //     referCourseClientBillingAddressError=true
                                        // }
                                        if(referCourseClientNameError||referCourseClientPhoneError||referCourseClientEmailError){
                                            this.setState({
                                                referCourseClientNameError:referCourseClientNameError,
                                                referCourseClientPhoneError:referCourseClientPhoneError,
                                                referCourseClientEmailError:referCourseClientEmailError,
                                            })
                                            alert("Please fill in all client criteria correctly")
                                            return
                                        }

                                        let skuIDString=""
                                        if(this.state.selectedCourseTitle=="连锁系统"){
                                            skuIDString="sku=franchise&franchiseID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="系统管理 实体课"){
                                            skuIDString="sku=system&systemID="+this.state.selectedCourseSession
                                        }else if(this.state.selectedCourseTitle=="战略三力"){
                                            skuIDString="sku=SMP"
                                        }else if(this.state.selectedCourseTitle=="营销三力"){
                                            skuIDString="sku=MMP"
                                        }else if(this.state.selectedCourseTitle=="组织三力"){
                                            skuIDString="sku=TMP"
                                        }else if(this.state.selectedCourseTitle=="模式三力"){
                                            skuIDString="sku=BMP"
                                        }else if(this.state.selectedCourseTitle=="总裁三力"){
                                            skuIDString="sku=CEO"
                                        }
                                        let introString="&intro="+reactLocalStorage.get("referCode")
                                        let nonMemberString="&name="+this.state.referCourseClientName+
                                            "&phone="+this.state.referCourseClientPhone+
                                            "&email="+this.state.referCourseClientEmail+
                                            "&billingAddress="+this.state.referCourseClientBillingAddress

                                        let signupLink=""
                                        if(this.state.selectedCourseTitle=="系统管理学配套"){
                                            signupLink="https://enlliance.com/sysmanagementpackage?"+introString.substring(1)+nonMemberString
                                        }else{
                                            signupLink="https://enlliance.com/payment?"+skuIDString+introString+nonMemberString
                                        }
                                        signupLink=encodeURI(signupLink)

                                        this.setState({
                                            purchaseSignupPage:"generate",
                                            referCourseClientNameError:false,
                                            referCourseClientPhoneError:false,
                                            referCourseClientEmailError:false,
                                            franchisePurchaseSignupLink:signupLink,
                                        })
                                    }}>
                                        Generate Link
                                    </Button>
                                </div>
                                :
                            this.state.purchaseSignupPage=="generate"?
                                <div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                        <div>Link:</div>
                                        <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                            <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                GLOBAL.copyTextToClipboard(this.state.franchisePurchaseSignupLink)
                                            }}>
                                                Copy
                                            </Button>
                                        </div>
                                    </div>
                                    <textarea rows={5} style={{width:"100%"}} value={this.state.franchisePurchaseSignupLink} />
                                </div>
                                :
                                null
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:10,width:"100%"}}>
                            {
                            this.state.purchaseSignupPage!="phone"?
                                <Button onClick={()=>{
                                    this.setState({
                                        purchaseSignupPage:"phone",
                                        franchisePurchaseSignupLink:null,
                                        referCourseClientName:null,
                                        referCourseClientPhone:null,
                                        referCourseClientEmail:null,
                                        referCourseClientBillingAddress:null,
                                        referCourseClientNameError:false,
                                        referCourseClientPhoneError:false,
                                        referCourseClientEmailError:false,
                                    })
                                }}>
                                    Clear
                                </Button>
                                :
                                null
                            }
                            <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({
                                    purchaseSignupModal:false,
                                    purchaseSignupPage:"phone",
                                    referCourseClientName:null,
                                    referCourseClientPhone:null,
                                    referCourseClientEmail:null,
                                    referCourseClientBillingAddress:null,
                                    referCourseClientNameError:false,
                                    referCourseClientPhoneError:false,
                                    referCourseClientEmailError:false,
                                })
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.wWalletInfoModal} toggle={()=>this.setState({wWalletInfoModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>About C Wallet</div>
                        <div style={{marginBottom:10}}>
                            C wallet (aka Commission Wallet) is the amount of money you would like to be transferred to you bank account every month.
                            Once transferred, a payment slip will be provided in Wallet Log. Please make sure your bank account details in you Achievemnet page is set correctly.
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({wWalletInfoModal:false})
                            }}>
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.transferToOtherModal} toggle={()=>this.setState({transferToOtherModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Transfer to another member's R Wallet</div>
                        <div style={{color:"red",marginBottom:15}}>
                            *This action cannot be reverted
                        </div>
                        <div style={{marginBottom:10}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>R Wallet:</div>
                                <div>
                                    {this.state.rWallet<0?"-RM":"RM"}{Math.abs(this.state.rWallet).toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"red"}}>
                                    &nbsp;{(this.state.rWallet-parseFloat(this.state.transferToOther))<0?"-RM":"RM"}{Math.abs((this.state.rWallet-parseFloat(this.state.transferToOther))).toLocaleFixed(2)}
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div>Receiver phone number</div>
                                <Input type="number" placeholder="01656789012" value={this.state.receiverPhone} onChange={(e)=>{
                                    this.setState({receiverPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input placeholder="Amount (RM)" style={{width:"100%"}} value={this.state.transferToOther} onChange={(e)=>{
                                let transferAmount=parseFloat(e.target.value.replace(/\D/g,''))
                                if(transferAmount>this.state.rWallet){
                                    transferAmount=this.state.rWallet
                                }
                                this.setState({transferToOther:transferAmount})
                            }} />
                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({transferToOther:this.state.rWallet})
                            }}>
                                Max
                            </Button>
                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                this.setState({transferToOther:this.state.rWallet/2})
                            }}>
                                50%
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                if(this.state.transferToOther<=0){
                                    return
                                }
                                this.transferToOtherRWallet()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({transferToOtherModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                {/* <Modal isOpen={this.state.WtoPWalletModal} toggle={()=>this.setState({WtoPWalletModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>C to P Wallet Transfer</div>
                        <div style={{color:"red",marginBottom:15}}>

                        </div>
                        <div style={{marginBottom:10}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>C Wallet:</div>
                                <div>
                                    RM{this.state.wWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"red"}}>
                                    &nbsp;RM{(this.state.wWallet-parseFloat(this.state.WtoCTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>P Wallet:</div>
                                <div>
                                    RM{this.state.pWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"green"}}>
                                    &nbsp;RM{(this.state.pWallet+parseFloat(this.state.WtoCTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input placeholder="Amount (RM)" style={{width:"100%"}} value={this.state.WtoCTransfer} onChange={(e)=>{
                                let transferAmount=parseFloat(e.target.value.replace(/\D/g,''))
                                if(transferAmount>this.state.wWallet){
                                    transferAmount=this.state.wWallet
                                }
                                this.setState({WtoCTransfer:transferAmount})
                            }} />
                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({WtoCTransfer:this.state.wWallet})
                            }}>
                                Max
                            </Button>
                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                this.setState({WtoCTransfer:this.state.wWallet/2})
                            }}>
                                50%
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                if(this.state.WtoCTransfer<=0){
                                    return
                                }
                                this.transferFromWToPWallet()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({WtoPWalletModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={this.state.WtoRWalletModal} toggle={()=>this.setState({WtoRWalletModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>C to R Wallet Transfer</div>
                        <div style={{color:"red",marginBottom:15}}>

                        </div>
                        <div style={{marginBottom:10}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>C Wallet:</div>
                                <div>
                                    RM{this.state.wWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"red"}}>
                                    &nbsp;RM{(this.state.wWallet-parseFloat(this.state.WtoRTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>R Wallet:</div>
                                <div>
                                    RM{this.state.rWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"green"}}>
                                    &nbsp;RM{(this.state.rWallet+parseFloat(this.state.WtoRTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input placeholder="Amount (RM)" style={{width:"100%"}} value={this.state.WtoRTransfer} onChange={(e)=>{
                                let transferAmount=parseFloat(e.target.value.replace(/\D/g,''))
                                if(transferAmount>this.state.wWallet){
                                    transferAmount=this.state.wWallet
                                }
                                this.setState({WtoRTransfer:transferAmount})
                            }} />
                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({WtoRTransfer:this.state.wWallet})
                            }}>
                                Max
                            </Button>
                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                this.setState({WtoRTransfer:this.state.wWallet/2})
                            }}>
                                50%
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                if(this.state.WtoRTransfer<=0){
                                    return
                                }
                                this.transferFromWToRWallet()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({WtoRWalletModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.PtoRWalletModal} toggle={()=>this.setState({PtoRWalletModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>P to R Wallet Transfer</div>
                        <div style={{color:"red",marginBottom:15}}>
                            *Please note that transfer from P to R wallet is irreversible
                        </div>
                        <div style={{marginBottom:10}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>P Wallet:</div>
                                <div>
                                    RM{this.state.pWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"red"}}>
                                    &nbsp;RM{(this.state.pWallet-parseFloat(this.state.PtoRTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>R Wallet:</div>
                                <div>
                                    RM{this.state.rWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"green"}}>
                                    &nbsp;RM{(this.state.rWallet+parseFloat(this.state.PtoRTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input placeholder="Amount (RM)" style={{width:"100%"}} value={this.state.PtoRTransfer} onChange={(e)=>{
                                let transferAmount=parseFloat(e.target.value.replace(/\D/g,''))
                                if(transferAmount>this.state.pWallet){
                                    transferAmount=this.state.pWallet
                                }
                                this.setState({PtoRTransfer:transferAmount})
                            }} />
                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({PtoRTransfer:this.state.pWallet})
                            }}>
                                Max
                            </Button>
                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                this.setState({PtoRTransfer:this.state.pWallet/2})
                            }}>
                                50%
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                if(this.state.PtoRTransfer<=0){
                                    return
                                }
                                this.transferFromPToRWallet()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({PtoRWalletModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.PtoWWalletModal} toggle={()=>this.setState({PtoWWalletModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>P to C Wallet Transfer</div>
                        <div style={{color:"red",marginBottom:15}}>
                            *Please note that transfer from P to C wallet is irreversible
                        </div>
                        <div style={{marginBottom:10}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>P Wallet:</div>
                                <div>
                                    RM{this.state.pWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"red"}}>
                                    &nbsp;RM{(this.state.pWallet-parseFloat(this.state.PtoWTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                <div>C Wallet:</div>
                                <div>
                                    RM{this.state.wWallet.toLocaleFixed(2)+" -> "}
                                </div>
                                <div style={{color:"green"}}>
                                    &nbsp;RM{(this.state.wWallet+parseFloat(this.state.PtoWTransfer)).toLocaleFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input placeholder="Amount (RM)" style={{width:"100%"}} value={this.state.PtoWTransfer} onChange={(e)=>{
                                let transferAmount=parseFloat(e.target.value.replace(/\D/g,''))
                                if(transferAmount>this.state.pWallet){
                                    transferAmount=this.state.pWallet
                                }
                                this.setState({PtoWTransfer:transferAmount})
                            }} />
                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({PtoWTransfer:this.state.pWallet})
                            }}>
                                Max
                            </Button>
                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                this.setState({PtoWTransfer:this.state.pWallet/2})
                            }}>
                                50%
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                if(this.state.PtoWTransfer<=0){
                                    return
                                }
                                this.transferFromPToWWallet()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({PtoWWalletModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="dashboard" />
                <div style={{padding:"10px 20px",display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div style={{fontSize:18,fontWeight:"bold",marginBottom:10}}>Refer Links</div>
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:5}}>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                            {
                            GLOBAL.checkPermission("CXIzgkra")? //Send Whatsapp Signup Link
                                <Button color="primary" style={{}} onClick={()=>{
                                    this.setState({
                                        generateWhatsappSignupLinkModal:true,
                                        sendWhatsappSignupLinkPhone:null,
                                        sendWhatsappSignupLinkName:null,
                                    })
                                }}>
                                    Generate & Send Whatsapp Signup link
                                </Button>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("CapU{Y$N")? //Check purchase signup phone is member or not
                                <Button color="primary" style={{}} onClick={()=>{
                                    this.setState({
                                        selectedCourseTitle:"战略三力",
                                        purchaseSignupModal:true,
                                    })
                                }}>
                                    Purchase and Signup
                                </Button>
                                :
                                null
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:5}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                {
                                this.state.companyDetail!=null&&this.state.companyDetail.systemClassLink!=null?
                                    <Button color="primary" style={{}} onClick={()=>{
                                        GLOBAL.copyTextToClipboard("https://enlliance.com/"+this.state.companyDetail.systemClassLink+"?intro="+reactLocalStorage.get("referCode"))
                                    }}>
                                        {this.state.companyDetail.name+" 系统管理 实体课"}
                                    </Button>
                                    :
                                    <Button color="primary" style={{}} onClick={()=>{
                                        GLOBAL.copyTextToClipboard("https://enlliance.com/system?intro="+reactLocalStorage.get("referCode"))
                                    }}>
                                        系统管理 实体课
                                    </Button>
                                }
                                {
                                this.state.companyDetail!=null&&this.state.companyDetail.franchiseClassLink!=null?
                                    <Button color="primary" style={{}} onClick={()=>{
                                        GLOBAL.copyTextToClipboard("https://enlliance.com/"+this.state.companyDetail.franchiseClassLink+"?intro="+reactLocalStorage.get("referCode"))
                                    }}>
                                        {this.state.companyDetail.name+" 连锁系统"}
                                    </Button>
                                    :
                                    <Button color="primary" style={{}} onClick={()=>{
                                        GLOBAL.copyTextToClipboard("https://enlliance.com/franchise?intro="+reactLocalStorage.get("referCode"))
                                    }}>
                                        连锁系统
                                    </Button>
                                }
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/strategic?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    战略三力
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/marketing?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    营销三力
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/organization?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    组织三力
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/businessmastery?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    模式三力
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/ceo?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    总裁三力
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5}}>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/deal?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    顺势开单
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/khgl?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    客户管理
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/gxyy?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    高效运营
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/process?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    系统管理 线上课
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/modebusiness?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    战略先修
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/modemember?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    营销先修
                                </Button>
                                <Button color="primary" style={{}} onClick={()=>{
                                    GLOBAL.copyTextToClipboard("https://enlliance.com/modemanage?intro="+reactLocalStorage.get("referCode"))
                                }}>
                                    组织先修
                                </Button>
                            </div>
                        </div>

                        {
                        this.state.whatsappSignupList.length>0?
                            <table style={{width:"100%"}}>
                                <tr>
                                    <td style={styles.cellWithBorder}>Phone</td>
                                    <td style={styles.cellWithBorder}>Name</td>
                                    <td style={styles.cellWithBorder}>Last Sent Date</td>
                                    <td style={styles.cellWithBorder}>Create Date</td>
                                    <td style={styles.cellWithBorder}></td>
                                </tr>
                                {
                                this.state.whatsappSignupList.map((token)=>{
                                    return(
                                        <tr>
                                            <td style={styles.cellWithBorder}>{token.phone}</td>
                                            <td style={styles.cellWithBorder}>{token.name}</td>
                                            <td style={styles.cellWithBorder}>{token.lastSent}</td>
                                            <td style={styles.cellWithBorder}>{token.createDate}</td>
                                            <td style={styles.cellWithBorder}>
                                                <div style={{display:"flex",flexDirection:"row",gap:10,width:"100%",justifyContent:"center"}}>
                                                    <Button color="primary" style={{}} onClick={()=>{
                                                        if(!window.confirm("Are you sure to resend Whatsapp signup link to "+token.phone+" "+token.name+"?")){
                                                            return
                                                        }
                                                        this.resendWhatsappSignup(token)
                                                    }}>
                                                        Resend
                                                    </Button>
                                                    <Button color="danger" style={{}} onClick={()=>{
                                                        if(!window.confirm("Are you sure to delete this token? "+token.phone+" "+token.name+"?")){
                                                            return
                                                        }
                                                        this.deleteWhatsappSignup(token)
                                                    }}>
                                                        <RiDeleteBin2Line style={{width:22,height:22}} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </table>
                            :
                            null
                        }
                    </div>

                    <div style={{width:"100%",borderBottom:"solid 1px lightgrey",marginTop:20,marginBottom:20}} />

                    <div style={{fontSize:16,fontWeight:"bold",marginBottom:10}}>Members/Non Members Coming Participations</div>
                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5,marginBottom:20,width:"100%"}}>
                        {/*
                        this.state.memberAttendanceList.map((attendance)=>{
                            return(
                                <div>{JSON.stringify(attendance)}</div>
                            )
                        })
                        */}
                        <div style={{display:"flex",flexDirection:"row",gap:5}}>
                            {
                            reactLocalStorage.get("companyOfflineEvent")!=null&&JSON.parse(reactLocalStorage.get("companyOfflineEvent")).map((offlineEvent)=>{
                                return(
                                    <div style={{}}>
                                        [{offlineEvent.name}]
                                    </div>
                                )
                            })
                            }
                        </div>
                        { 
                        this.state.offlineEventBookingList.length>0?
                            window.innerWidth<600?  
                                this.state.eventSummary.map((event)=>{
                                    return(
                                        <div style={{width:"100%",marginBottom:5,display:"flex",flexDirection:"column",borderRadius:10,border:"1px solid lightgrey",padding:10,gap:5}}>
                                            <div>{event.title}</div>
                                            <div style={{}}>
                                                {event.startDateDisplay} ~ {event.endDateDisplay}<br/>
                                                {event.venue}
                                            </div>
                                            <div style={{}}>
                                                    <Button color="primary" style={{}} onClick={()=>{
                                                    history.push("/clientbackground?type=offlineevent&class="+event.id)
                                                }}>
                                                    Client Background Page
                                                </Button>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                                                <div style={{}}>Attendance:&nbsp;</div>
                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                    {this.calculateAndGenerateAttendance(event)}
                                                    </div>
                                            </div>
                                           {/*  {
                                            attendance.user!=null?
                                                <div style={{marginTop:5}}>Member: {attendance.user.username} {attendance.user.name}</div>
                                                :
                                                <div style={{marginTop:5}}>Non member: {attendance.nonMember.phone} {attendance.nonMember.name}</div>
                                            }
                                            <div>
                                                PIC:
                                                {
                                                attendance.user!=null&&attendance.user.personInCharge!=null?
                                                    attendance.user.personInCharge.username==attendance.user.personInCharge.name?
                                                        attendance.user.personInCharge.name
                                                        :
                                                        attendance.user.personInCharge.username+" "+attendance.user.personInCharge.name
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div>
                                                Referrer:
                                                {
                                                attendance.user!=null?
                                                    attendance.user.referrer!=null?
                                                        attendance.user.referrer.username==attendance.user.referrer.name?
                                                            attendance.user.referrer.name
                                                            :
                                                            attendance.user.referrer.username+" "+attendance.user.referrer.name
                                                        :
                                                        "-"
                                                    :
                                                    attendance.nonMemberReferrer!=null?
                                                        attendance.nonMemberReferrer.username==attendance.nonMemberReferrer.name?
                                                            attendance.nonMemberReferrer.name
                                                            :
                                                            attendance.nonMemberReferrer.username+" "+attendance.nonMemberReferrer.name
                                                        :
                                                        "-"
                                                }
                                            </div>
                                            <div>Signup Date: {attendance.signupDate}</div>
                                            <div>
                                                Revision:
                                                {
                                                attendance.revision==true?
                                                    "Yes"
                                                    :
                                                    "No"
                                                }
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                Attendance:
                                                {this.generateAttendanceUI(attendance.attendance,attendance.offlineEvent.startDate,attendance.offlineEvent.endDate)}
                                            </div> */}
                                        </div>
                                    )
                                })
                                :
                                <div style={{width:"100%", display:"flex", flexDirection:"row",gap:10,justifyContent:"center", alignItems:"center",flexWrap:"wrap"}}>
                                    {
                                     this.state.eventSummary.map((event) => {
                                        return( 
                                            <div style={{width:"350px", display:"flex", flexDirection:"row"}}>
                                                <div style={{width:"100%", display:"flex", flexDirection:"column",border:"1px solid lightgrey", borderRadius:10,padding:5}}>
                                                    <div style={{padding:5}}>
                                                        {event.title}
                                                    </div>
                                                    <div style={{padding:5}}>
                                                        {event.startDateDisplay} ~ {event.endDateDisplay}<br/>
                                                        {event.venue}
                                                    </div>
                                                    <div style={{padding:5}}>
                                                            <Button color="primary" style={{}} onClick={()=>{
                                                            history.push("/clientbackground?type=offlineevent&class="+event.id)
                                                        }}>
                                                            Client Background Page
                                                        </Button>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",padding:5}}>
                                                        <div style={{}}>Attendance:&nbsp;</div>
                                                            <div   div style={{display:"flex", flexDirection:"row"}}>
                                                                {this.calculateAndGenerateAttendance(event)}

                                                            </div>
                                                        </div>

                                                    </div>

                                            </div>
                                        )
                                    })
                                    }


                                
                                </div>
                            :
                        reactLocalStorage.get("companyOfflineEvent")!=null&&JSON.parse(reactLocalStorage.get("companyOfflineEvent")).length>0?
                            <div style={{color:"grey",width:"100%",textAlign:"center"}}>No Data</div>
                            :
                            null
                        }
                        <div>系统管理学 实体课</div>
                        {
                        this.state.memberAttendanceList.length>0?
                            window.innerWidth<600?
                                this.state.memberAttendanceList.map((attendance)=>{
                                    return(
                                        <div style={{width:"100%",marginBottom:5,display:"flex",flexDirection:"column",borderRadius:10,padding:10,backgroundColor:"#f5f5f5"}}>
                                            <div>{attendance.courseSessionString}</div>
                                            <div style={{borderBottom:"1px solid lightgrey",paddingBottom:5}}>
                                                {attendance.courseSessionDateDisplay} ~ {attendance.courseSessionEndDateDisplay}<br/>
                                                {attendance.courseSessionVenue}
                                            </div>
                                            <div style={{marginTop:5}}>Member: {attendance.user.username} {attendance.user.name}</div>
                                            <div>
                                                PIC:
                                                {
                                                attendance.user.personInCharge!=null?
                                                    attendance.user.personInCharge.username==attendance.user.personInCharge.name?
                                                        attendance.user.personInCharge.name
                                                        :
                                                        attendance.user.personInCharge.username+" "+attendance.user.personInCharge.name
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div>
                                                Referrer:
                                                {
                                                attendance.user.referrer!=null?
                                                    attendance.user.referrer.username==attendance.user.referrer.name?
                                                        attendance.user.referrer.name
                                                        :
                                                        attendance.user.referrer.username+" "+attendance.user.referrer.name
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div>Signup Date: {attendance.signupDate}</div>
                                            <div>
                                                Revision:
                                                {
                                                attendance.revision==true?
                                                    "Yes"
                                                    :
                                                    "No"
                                                }
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                Attendance:
                                                {this.generateAttendanceUI(attendance.attendance,attendance.courseSessionDate,attendance.courseSessionEndDate)}
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Event</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Event Detail</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Member/Non Member</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>PIC</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Referrer</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Signup Date</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Revision</td>
                                        <td style={{border:"1px solid lightgrey",padding:5}}>Attendance</td>
                                    </tr>
                                    {
                                    this.state.memberAttendanceList.map((attendance)=>{
                                        return(
                                            <tr>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>{attendance.courseSessionString}</td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>
                                                    {attendance.courseSessionDateDisplay} ~ {attendance.courseSessionEndDateDisplay}<br/>
                                                    {attendance.courseSessionVenue}
                                                </td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>{attendance.user.username} {attendance.user.name}</td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>
                                                    {
                                                    attendance.user.personInCharge!=null?
                                                        attendance.user.personInCharge.username==attendance.user.personInCharge.name?
                                                            attendance.user.personInCharge.name
                                                            :
                                                            attendance.user.personInCharge.username+" "+attendance.user.personInCharge.name
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>
                                                    {
                                                    attendance.user.referrer!=null?
                                                        attendance.user.referrer.username==attendance.user.referrer.name?
                                                            attendance.user.referrer.name
                                                            :
                                                            attendance.user.referrer.username+" "+attendance.user.referrer.name
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>{attendance.signupDate}</td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>
                                                    {
                                                    attendance.revision==true?
                                                        "Yes"
                                                        :
                                                        "No"
                                                    }
                                                </td>
                                                <td style={{border:"1px solid lightgrey",padding:5}}>{this.generateAttendanceUI(attendance.attendance,attendance.courseSessionDate,attendance.courseSessionEndDate)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </table>
                            :
                            <div style={{color:"grey",width:"100%",textAlign:"center"}}>No Data</div>
                        }

                        
                    </div>

                    <div style={{width:"100%",borderBottom:"solid 1px lightgrey",marginTop:20,marginBottom:20}} />

                    <div style={{fontSize:18,fontWeight:"bold",marginBottom:10}}>Wallet</div>
                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10,marginBottom:20}}>
                        <Button color="primary" style={{}} onClick={()=>{
                            this.setState({walletLogModal: true})
                        }}>
                            Wallet Log
                        </Button>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",flexWrap:"wrap",gap:15}}>
                        <div style={{display:"flex",flexDirection:"column",alignItems:"center",border:"1px solid lightgrey",padding:20,borderRadius:15,minWidth:240}}>
                            <div style={{fontSize:22,fontWeight:"bold"}}>R Wallet</div>
                            <div style={{fontSize:20,color:this.state.rWallet<0?"red":"black"}}>{this.state.rWallet<0?"-RM":"RM"}{Math.abs(this.state.rWallet).toLocaleFixed(2)}</div>
                            <div style={{display:"flex",flexDirection:"column",gap:5,marginTop:10}}>
                                <Button color="primary" onClick={()=>{
                                    if(this.state.rWallet<=0){
                                        alert("Cannot transfer anything. R Wallet balance is equal/less than RM0")
                                        return
                                    }
                                    this.setState({transferToOtherModal:true,receiverPhone:"",transferToOther:0})
                                }}>
                                    Transfer to another member
                                </Button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",alignItems:"center",border:"1px solid lightgrey",padding:20,borderRadius:15,minWidth:240}}>
                            <div style={{fontSize:22,fontWeight:"bold"}}>P Wallet</div>
                            <div style={{fontSize:20,color:this.state.pWallet<0?"red":"black"}}>{this.state.pWallet<0?"-RM":"RM"}{Math.abs(this.state.pWallet).toLocaleFixed(2)}</div>
                            <div style={{display:"flex",flexDirection:"column",gap:5,marginTop:10}}>
                                <Button color="primary" onClick={()=>{
                                    if(this.state.pWallet<=0){
                                        alert("Cannot transfer anything. P Wallet balance is equal/less than RM0")
                                        return
                                    }
                                    this.setState({PtoRWalletModal:true})
                                }}>
                                    Transfer to R Wallet
                                </Button>
                                <Button style={{display:this.state.payToCompany? "none":"block"}} color="primary" onClick={()=>{
                                    if(this.state.pWallet<=0){
                                        alert("Cannot transfer anything. P Wallet balance is equal/less than RM0")
                                        return
                                    }
                                    this.setState({PtoWWalletModal:true})
                                }}>
                                    Transfer to C Wallet
                                </Button>
                            </div>
                        </div>    
                        <div style={{display:this.state.payToCompany? "none":"flex",flexDirection:"column",alignItems:"center",border:"1px solid lightgrey",padding:20,borderRadius:15,minWidth:240}}>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",alignItems:"center" }}>
                                <div style={{fontSize:22,fontWeight:"bold"}}>C Wallet</div>
                                <BsFillInfoCircleFill style={{color:"skyblue",cursor:"pointer",marginLeft:5,fontSize:20}} onClick={()=>{
                                    this.setState({wWalletInfoModal:true})
                                }}/>
                            </div>
                            <div style={{fontSize:20}}>RM{this.state.wWallet.toLocaleFixed(2)}</div>
                            <div style={{display:"flex",flexDirection:"column",gap:5,marginTop:10}}>
                                {/* <Button color="primary" onClick={()=>{
                                    if(this.state.wWallet<=0){
                                        alert("Cannot transfer anything. C Wallet balance is equal/less than RM0")
                                        return
                                    }
                                    this.setState({WtoPWalletModal:true})
                                }}>
                                    Transfer to P Wallet
                                </Button> */}
                                <Button color="primary" onClick={()=>{
                                    if(this.state.wWallet<=0){
                                        alert("Cannot transfer anything. C Wallet balance is equal/less than RM0")
                                        return
                                    }
                                    this.setState({WtoRWalletModal:true})
                                }}>
                                    Transfer to R Wallet here
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                { 
                    this.state.walletLogModal && (
                        <>                                                 
                            <WalletLog
                                sendLoadingToParent={this.handleChildLoading}
                                currentMember={localStorage.getItem('id')}
                                isOpen={this.state.walletLogModal}
                                toggle={() => this.setState({ walletLogModal: false, loading: false })}
                            />
                        </>
                    )
                }
            </div>
        )
    }
}

Number.prototype.toLocaleFixed = function(n) {
    return this.toLocaleString(undefined, {
      minimumFractionDigits: n,
      maximumFractionDigits: n
    });
};

let styles={
    tableCellStyle:{
        padding:"2px 5px"
    },
    cellWithBorder:{
        padding:"2px 5px",
        border:"1px solid lightgrey",
    }
}
