import React from 'react'
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate';
import {ImCross} from 'react-icons/im'
import {AiOutlineRight} from 'react-icons/ai'

export default class LeadPool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            poolList:[],

            leadPoolPage:0,
            leadPoolPageCount:0,

            itemPerPage:0,
            page:0,

            findField:"",
            filterModal:false,
            filterMode:null,
            filterTicketNumber:"",
            filterMember:"",
            filterClientPhone:"",
            filterClientName:"",
            filterClientEmail:"",
            filterReferrerPhone:"",
            filterChannel:[],
            filterInterest:"",
            statusList:[],
            selectedStatusList:[],
            filterLastUpdateFrom:"",
            filterLastUpdateTo:"",
            filterCreateDateFrom:"",
            filterCreateDateTo:"",
            filterDateAddedFrom:"",
            filterDateAddedTo:"",

            leadPoolHistoryModal:false,
            currentHistoryList:[],
            historyPageItem:7,
            historyPage:0,
            isStaffOrAgentLeader:false,
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        let newItemPerPage;
        if (window.innerWidth <= 600) {
            newItemPerPage = 10;
        } else {
            newItemPerPage = 15;
        }
        this.setState({ itemPerPage: newItemPerPage }, () => {
            this.loadLeadPool();
        });

        //get list of status
        const payload = {

        }
        axios
            .post(`sales/getticketstatuslist`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    statusList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get status list error\n"+error)
            })
    }

    loadLeadPool=(page=0)=>{
        this.setState({loading:true})
        const payload = {
            itemPerPage:this.state.itemPerPage,
            page:page,
        }
        axios
            .post(`sales/loadleadpool`, qs.stringify(payload))
            .then(async response => {
                //parse date
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].lastUpdate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].dateAdded.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].dateAdded=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    poolList:response.data.leadPoolList,
                    leadPoolPage:page,
                    leadPoolPageCount:response.data.pageCount,
                    allPoolCount:response.data.allPoolCount,
                    isStaffOrAgentLeader:response.data.isStaffOrAgentLeader,
                })
            })
            .catch(error =>{
                alert("Load lead pool error\n"+error)
                this.setState({loading:false})
            })
    }

    takeTicket=(ticket)=>{
        //confirm
        let personString=""
        if(ticket.target!=null){
            personString=ticket.target.username+" "+ticket.target.name
        }else if(ticket.lead!=null){
            personString=ticket.lead.phone+" "+ticket.lead.name
        }
        if(!window.confirm("Are you sure you want to take ticket "+ticket.id+"\nfor "+personString+"?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            ticketID:ticket.id
        }
        axios
            .post(`sales/taketicket`, qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                },()=>{
                    this.loadLeadPool()
                })
            })
            .catch(error =>{
                if(error.response!=null){
                    if(error.response.status==444){ //cannot take ticket from another angent company
                        alert("Cannot take ticket from another agent company")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){   //cannot take ticket more than 3 times
                        alert("Cannot take ticket more than 3 times")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Take ticket error\n"+error)
                this.setState({loading:false})
            })
    }

    handleLeadPoolPageClick=(data)=>{
        let selected = data.selected
        this.searchTicket(selected)
    }

    filterTicket=(findFilter)=>{
        let find=this.cleanFilter(findFilter)
        this.setState({
            findField:find,
            filterModal:false,
            filterMode:null,
        },()=>{
            this.searchTicket()
        })
    }

    cleanFilter=(findFilter)=>{
        let find=this.state.findField
        //remove all existing same param
        if(this.state.filterMode=="Last Update"||this.state.filterMode=="Create Date"||this.state.filterMode=="Date Added"){
            find=this.removeParam(find,findFilter+" From")
            find=this.removeParam(find,findFilter+" To")
        }else{
            find=this.removeParam(find,findFilter)
        }
        if(find.length>0){
            find+=";"
        }
        if(this.state.filterMode=="Ticket Number"){
            find+=findFilter+":"+this.state.filterTicketNumber
        }else if(this.state.filterMode=="Member"){
            find+=findFilter+":"+this.state.filterMember
        }else if(this.state.filterMode=="Client Phone"){
            find+=findFilter+":"+this.state.filterClientPhone
        }else if(this.state.filterMode=="Client Name"){
            find+=findFilter+":"+this.state.filterClientName
        }else if(this.state.filterMode=="Client Email"){
            find+=findFilter+":"+this.state.filterClientEmail
        }else if(this.state.filterMode=="Referrer Phone"){
            find+=findFilter+":"+this.state.filterReferrerPhone
        }else if(this.state.filterMode=="Channel"){
            find+=findFilter+":"+this.state.filterChannel.join(",")
        }else if(this.state.filterMode=="Interest"){
            find+=findFilter+":"+this.state.filterInterest
        }else if(this.state.filterMode=="Status"){
            find+=findFilter+":"+this.state.selectedStatusList.join(",")
        }else if(this.state.filterMode=="Last Update"){
            let gotFrom=false
            if(this.state.filterLastUpdateFrom!=""){
                find+=findFilter+" From:"+this.state.filterLastUpdateFrom
                gotFrom=true
            }
            if(this.state.filterLastUpdateTo!=""){
                if(gotFrom==true){
                    find+=";"
                }
                find+=findFilter+" To:"+this.state.filterLastUpdateTo
            }
        }else if(this.state.filterMode=="Create Date"){
            let gotFrom=false
            if(this.state.filterCreateDateFrom!=""){
                find+=findFilter+" From:"+this.state.filterCreateDateFrom
                gotFrom=true
            }
            if(this.state.filterCreateDateTo!=""){
                if(gotFrom==true){
                    find+=";"
                }
                find+=findFilter+" To:"+this.state.filterCreateDateTo
            }
        }else if(this.state.filterMode=="Date Added"){
            let gotFrom=false
            if(this.state.filterDateAddedFrom!=""){
                find+=findFilter+" From:"+this.state.filterDateAddedFrom
                gotFrom=true
            }
            if(this.state.filterDateAddedTo!=""){
                if(gotFrom==true){
                    find+=";"
                }
                find+=findFilter+" To:"+this.state.filterDateAddedTo
            }
        }

        return find
    }

    removeParam=(find,param)=>{
        let findSplit=find.split(";")
        let indexToRemove=-1
        for(let [index,f] of findSplit.entries()){
            let fSplit=f.split(":")
            let thePar=fSplit[0]
            if(thePar==param){
                indexToRemove=index
            }
        }
        if(indexToRemove!=-1){
            findSplit.splice(indexToRemove,1)
            return findSplit.join(";")
        }else{
            return find
        }
    }

    searchTicket=(page=0)=>{
        this.setState({loading:true})
        const payload = {
            findField:this.state.findField,
            itemPerPage:this.state.itemPerPage,
            page:page,
        }
        axios
            .post(`sales/searchleadpoolticket`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //parse date
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].lastUpdate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadPoolList.length;f++){
                    let rawDateSplit=response.data.leadPoolList[f].dateAdded.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadPoolList[f].dateAdded=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    poolList:response.data.leadPoolList,
                    leadPoolPageCount:response.data.pageCount,
                    leadPoolPage:response.data.page,
                    allPoolCount:response.data.allPoolCount,
                    isStaffOrAgentLeader:response.data.isStaffOrAgentLeader,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Invalid filter parameter")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Search error\n"+error)
                this.setState({loading:false})
            })
    }

    addSelectedChannel=(channel)=>{
        let foundSame=-1
        let listClone=this.state.filterChannel
        for(let [index,c] of listClone.entries()){
            if(c==channel){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(channel)
        }
        this.setState({filterChannel:listClone})
    }

    getLeadPoolHistory=()=>{
        this.setState({loading:true})
        const payload = {
            itemPerPage:this.state.historyPageItem,
            page:this.state.historyPage,
        }
        axios
            .post('sales/getleadpoolhistory',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                for(let history of response.data.currentHistoryList){
                    if(history.createDate){
                        let rawDateSplit=history.createDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        history.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }
                for(let history of response.data.currentHistoryList){
                    if(history.expiryDate){
                        let rawDateSplit=history.expiryDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        history.expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                   }
                }

                this.setState({
                    loading:false,
                    leadPoolHistoryModal:true,
                    currentHistoryList:response.data.currentHistoryList,
                    totalHistoryPage:response.data.totalHistoryPage,
                    historyPage:response.data.historyPage,
                    allHistoryCount:response.data.allHistoryCount,
                })
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Get lead pool history error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal style={{maxWidth:600}} isOpen={this.state.leadPoolHistoryModal} toggle={()=>this.setState({leadPoolHistoryModal:false})} >
                    <div style={{padding:20}}>
                        <div style={{display:"flex",justifyContent:"center",fontSize:20}}>Lead Pool History</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,marginBottom:20,gap:5,justifyContent:"center"}}>
                            {
                            this.state.currentHistoryList!=null?
                                this.state.currentHistoryList.map((history)=>{
                                    const getTypeColor = (type) => {
                                        switch (type) {
                                            case "Ticket Created":
                                                return "green"
                                            case "Expired Ticket":
                                                return "red"
                                            case "Ticket Taken from Lead Pool":
                                                return "purple"
                                            case "Ticket Transferred to Lead Pool":
                                                return "black"
                                            case "Ticket Edited to Lead Pool":
                                                return "black"
                                            default:
                                                return "black"
                                        }
                                    }
                                    return(
                                        <div style={{border:"1px solid lightgrey",padding:8,borderRadius:5,width:"100%"}}>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"3px"}}>
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",border:"1px solid skyblue",backgroundColor:"#ebf4ff",borderRadius:5,fontSize:10,marginRight:10,width:40}}><strong>{history.ticket}</strong></div>
                                                    <div style={{color:getTypeColor(history.type),fontWeight:"700"}}>{history.type}</div>
                                                </div>
                                                <div>{history.createDate}</div>
                                            </div>
                                            <div style={{fontSize:15,fontWeight:"500"}}>
                                                <div>Operator: {
                                                        history.operator!=null?
                                                            history.operator.username==history.operator.name?
                                                                history.operator.name
                                                                :
                                                                history.operator.username+" "+history.operator.name
                                                            :
                                                            "System"
                                                    }
                                                </div>
                                                {history.type === "Expired Ticket" && (
                                                    <div>Previous PIC: {
                                                        history.previousPic!=null?
                                                            history.previousPic.username==history.previousPic.name?
                                                                history.previousPic.name
                                                                :
                                                                history.previousPic.username+" "+history.previousPic.name
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                )}
                                                <div>Name: {
                                                    history.lead!=null&&history.lead.name!=null?
                                                        history.lead.name
                                                        :
                                                    history.target!=null?
                                                        history.target.name
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            <ReactPaginate
                            pageCount={this.state.totalHistoryPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.historyPage}
                            onPageChange={async ({selected}) => {
                                this.setState({historyPage:selected},()=>{
                                    this.getLeadPoolHistory()
                                })
                            }}/>

                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.filterModal} toggle={()=>this.setState({filterModal:false,filterMode:null})} style={{maxWidth:500}}>
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Filter by: {this.state.filterMode}</div>
                        {
                        this.state.filterMode=="Ticket Number"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}}
                                    value={this.state.filterTicketNumber}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterTicketNumber:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Member"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:5}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.setState({filterMember:"Member"},()=>{
                                        this.filterTicket(this.state.filterMode)
                                    })
                                }}>
                                    Member
                                </Button>
                                <Button color="secondary" style={{flex:1}} onClick={()=>{
                                    this.setState({filterMember:"Lead"},()=>{
                                        this.filterTicket(this.state.filterMode)
                                    })
                                }}>
                                    Lead
                                </Button>
                            </div>
                            :
                        this.state.filterMode=="Client Phone"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientPhone}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Client Name"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientName}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientName:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Client Email"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientEmail}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientEmail:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Referrer Phone"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterReferrerPhone}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterReferrerPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Channel"?
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5}}>
                                {
                                GLOBAL.channelList.map((channel)=>{
                                    return(
                                        <Button color="light" style={{backgroundColor:GLOBAL.getChannelColor(channel),color:"white",
                                            boxShadow:this.state.filterChannel.includes(channel)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={()=>{
                                                this.addSelectedChannel(channel)
                                        }}>
                                            {channel}
                                        </Button>
                                    )
                                })
                                }
                            </div>
                            :
                        this.state.filterMode=="Interest"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterInterest}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterInterest:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Status"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <div style={{padding:10,borderRadius:10,border:"1px solid steelblue",width:"100%",display:"flex",flexDirection:"row",gap:7,flexWrap:"wrap"}}>
                                    {
                                    this.state.selectedStatusList.map((status)=>{
                                        return(
                                            <div style={{
                                                backgroundColor:GLOBAL.getStatusColor(status),
                                                border:"none",
                                                color:GLOBAL.getStatusColor(status)=="yellow"?"black":"white",
                                                padding:5,
                                                display:"flex",flexDirection:"row",alignItems:"center",gap:7,flexWrap:"nowrap",
                                            }}>
                                                {status}
                                                <ImCross style={{cursor:"pointer"}} onClick={()=>{
                                                    let listClone=this.state.selectedStatusList
                                                    for(let [index,sta] of listClone.entries()){
                                                        if(sta==status){
                                                            listClone.splice(index,1)
                                                            break
                                                        }
                                                    }
                                                    this.setState({selectedStatusList:listClone})
                                                }} />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                <div>
                                    {
                                    this.state.statusList.map((category)=>{
                                        return(
                                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:7,marginTop:10}}>
                                                {
                                                category.statusList.map((stat)=>{
                                                    return(
                                                        <Button color="light"
                                                            style={{
                                                                backgroundColor:GLOBAL.getStatusColor(stat.title),
                                                                border:"none",
                                                                color:GLOBAL.getStatusColor(stat.title)=="yellow"?"black":"white",
                                                            }}
                                                            onClick={()=>{
                                                                let listClone=this.state.selectedStatusList
                                                                let foundSame=false
                                                                for(let sta of listClone){  //check for repeat
                                                                    if(sta==stat.title){
                                                                        foundSame=true
                                                                        break
                                                                    }
                                                                }
                                                                if(foundSame==false){
                                                                    listClone.push(stat.title)
                                                                    this.setState({selectedStatusList:listClone})
                                                                }
                                                            }}
                                                        >
                                                            {stat.title}
                                                        </Button>
                                                    )
                                                })
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                        this.state.filterMode=="Last Update"||this.state.filterMode=="Create Date"||this.state.filterMode=="Date Added"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <div style={{display:"flex",flexDirection:"row",gap:10,overflowX:"scroll"}}>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE",{
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE",{
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Today
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        fromDate.setDate(fromDate.getDate() - 13)   //today count as one day as well
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last 14 Days
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        fromDate.setDate(fromDate.getDate() - 6)   //today count as one day as well
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last 7 Days
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let curr = new Date()
                                        let fromDate = new Date(curr.setDate(curr.getDate() - curr.getDay()))
                                        fromDate.setHours(0,0,0)
                                        let toDate = new Date(); // First day is the day of the month - the day of the week
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        This Week
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setMonth(fromDate.getMonth()-1)
                                        fromDate.setDate(1)
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setDate(0)
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last Month
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setDate(1)
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setMonth(toDate.getMonth()+1)
                                        toDate.setDate(0)
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Date Added"){
                                            this.setState({
                                                filterDateAddedFrom:fromDateString,
                                                filterDateAddedTo:toDateString,
                                            })
                                        }
                                    }}>
                                        This Month
                                    </Button>
                                </div>
                                {
                                this.state.filterMode=="Last Update"?
                                    <div>
                                        <div>Last Update From</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterLastUpdateFrom}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterLastUpdateFrom:e.target.value})
                                        }} />
                                        <div>Last Update To</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterLastUpdateTo}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterLastUpdateTo:e.target.value})
                                        }} />
                                    </div>
                                    :
                                this.state.filterMode=="Create Date"?
                                    <div>
                                        <div>Create Date From</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterCreateDateFrom}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterCreateDateFrom:e.target.value})
                                        }} />
                                        <div>Create Date To</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterCreateDateTo}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterCreateDateTo:e.target.value})
                                        }} />
                                    </div>
                                    :
                                this.state.filterMode=="Date Added"?
                                    <div>
                                        <div>Date Added From</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterDateAddedFrom}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterDateAddedFrom:e.target.value})
                                        }} />
                                        <div>Date Added To</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterDateAddedTo}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterDateAddedTo:e.target.value})
                                        }} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }

                        {
                        this.state.filterMode!="Member"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.filterTicket(this.state.filterMode)
                                }}>
                                    Filter
                                </Button>
                                <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                    this.setState({filterModal:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                            :
                            null
                        }
                    </div>
                </Modal>

                <NavigationBar currentPage="ticket" />
                {window.innerWidth <= 600 ?
                    //Mobile
                    <div style={{display:"flex",flexDirection:"column",width:"100%",padding:"10px 20px",gap:10,fontSize:14}}>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center",alignItems:"center",fontWeight:"bold",fontSize:17,position:"relative"}}>
                            <Button color="primary" style={{position:"absolute",left:0,padding:"2px 10px",fontSize:10}} onClick={()=>{
                                this.getLeadPoolHistory()
                            }}>
                                Lead Pool History
                            </Button>
                            Lead Pool
                        </div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                            <Input placeholder="Use filter by below to create query"
                                value={this.state.findField} style={{width:"100%",marginRight:10,padding:"5px 10px"}}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchTicket()
                                    }
                                }}
                                onChange={(e)=>{
                                    this.setState({findField:e.target.value})
                            }} />
                            <Button color="danger" style={{whiteSpace:"nowrap",marginRight:5,fontSize:14}} onClick={()=>{
                                this.setState({findField:""})
                            }}>
                                Clear Query
                            </Button>
                            <Button color="primary" style={{padding:"5px 10px",fontSize:14}} onClick={()=>{
                                this.searchTicket()
                            }}>
                                Search
                            </Button>
                        </div>

                        <div style={{color:"grey",paddingLeft:10,fontSize:12}}>
                            Total {this.state.allPoolCount} results found.
                        </div>

                        <div style={{display:"flex",flexDirection:"column",alignItems:"left",justifyContent:"space-between",padding:"0px 0px 5px 0px",gap:10,paddingLeft:10}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap"}}>
                                <div style={{fontSize:12}}>Filter by:</div>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Ticket Number",filterTicketNumber:""})
                                }}>
                                    Ticket Number
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Member",filterMember:""})
                                }}>
                                    Member
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Phone",filterClientPhone:""})
                                }}>
                                    Client Phone
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Name",filterClientName:""})
                                }}>
                                    Client Name
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Email",filterClientEmail:""})
                                }}>
                                    Client Email
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Referrer Phone",filterReferrerPhone:""})
                                }}>
                                    Referrer Phone
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Channel",filterChannel:[]})
                                }}>
                                    Channel
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Interest",filterInterest:""})
                                }}>
                                    Interest
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Status",selectedStatusList:[]})
                                }}>
                                    Status
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Last Update",filterLastUpdateFrom:"",filterLastUpdateTo:"",})
                                }}>
                                    Last Update
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Create Date",filterCreateDateFrom:"",filterCreateDateTo:""})
                                }}>
                                    Create Date
                                </Button>
                                <Button color="light" style={styles.filterButtonStyleMobile} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Date Added",filterDateAddedFrom:"",filterDateAddedTo:""})
                                }}>
                                    Date Added
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                <Button color="light" style={{padding:"1px 5px",border:"1px solid black",fontSize:10,backgroundColor:"maroon",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:还没联系,等待回复,安排预约,已经回复,有兴趣,购买课程"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Hot
                                </Button>
                                <Button color="light" style={{padding:"1px 5px",border:"1px solid black",fontSize:10,backgroundColor:"yellow",color:"black"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:安排汇款,预付定金,分期付款,全额付款"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Payment
                                </Button>
                                <Button color="light" style={{padding:"1px 5px",border:"1px solid black",fontSize:10,backgroundColor:"steelblue",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:不精准客户,无法联系,没兴趣"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Cold
                                </Button>
                                <Button color="light" style={{padding:"1px 5px",border:"1px solid black",fontSize:10,backgroundColor:"grey",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:完成报名,他人完成,Task Terminated"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Closed
                                </Button>
                            </div>
                        </div>
                        
                        <div style={{width:"100%"}}>
                            {this.state.poolList.length>0?
                                this.state.poolList.map((ticket)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"column",borderRadius:"15px",padding:5,marginBottom:10,border:"1px solid",borderColor:"lightgrey"}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>
                                                    <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                                                            <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:14}}>
                                                                {ticket.target!=null?ticket.target.username:ticket.lead!=null?ticket.lead.phone:"-"} {ticket.target!=null?ticket.target.name:ticket.lead!=null?ticket.lead.name:"-"}
                                                            </div>
                                                            <div style={{fontSize:10,color:"grey"}}>{ticket.target!=null?"Member":ticket.lead!=null?"Lead":"-"}</div>
                                                            <div style={{fontWeight:"bold",fontSize:9,alignSelf:"flex-start"}}>
                                                                {ticket.target!=null?ticket.target.email:ticket.lead!=null?ticket.lead.email:"-"}
                                                            </div>
                                                        </div>
                                                        <Button color="primary" style={{width:70,height:30,fontSize:13,display:"flex",justifyContent:"center",alignItems:"center"}} onClick={()=>{
                                                                this.takeTicket(ticket)
                                                        }}>
                                                            Take
                                                        </Button>
                                                    </div>
                                                    <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:10,marginBottom:10,width:"100%",color:"lightgrey"}}/>
                                                    <div style={{display:"flex",flexDirection:"column",fontSize:12,gap:5}}>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                            <div style={{display:"flex",flexDirection:"column",width:"47%",gap:5}}>
                                                                <div style={{display:"flex",flexDirection:"row"}}>
                                                                    Company 
                                                                    {GLOBAL.checkPermission("l?`ZcgRh")?
                                                                        <div>: {ticket.agentCompany}</div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div style={{}}>
                                                                    Tix No.:&nbsp;
                                                                    {
                                                                    this.state.isStaffOrAgentLeader?
                                                                        <a href={"/ticket?id="+ticket.id} target="_blank" style={{color:"blue",textDecoration:"underline"}}>{ticket.id}</a>
                                                                        :
                                                                        ticket.id
                                                                    }
                                                                </div>
                                                                <div style={{}}>
                                                                    Referrer: {ticket.referrerString}
                                                                </div>
                                                                <div style={{}}>
                                                                    Channel: {ticket.channel}
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex",flexDirection:"column",width:"47%",gap:5}}>
                                                                <div style={{}}>
                                                                    Status: {ticket.status}
                                                                </div>
                                                                <div style={{}}>
                                                                    Last Update: {ticket.lastUpdate}
                                                                </div>
                                                                <div style={{}}>
                                                                    Create Date: {ticket.createDate}
                                                                </div>
                                                                <div style={{}}>
                                                                    Date Added: {ticket.dateAdded}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{}}>
                                                            Interest: {ticket.interest}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div style={{width:"100%",display:"flex",justifyContent:"center",fontSize:12,marginTop:10,color:"grey"}}> -----   No Results Found   ----- </div>
                            }
                        </div>
                        
                        <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                            {this.state.leadPoolPageCount > 0 && (
                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.leadPoolPage}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.leadPoolPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleLeadPoolPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.leadPoolPage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    //Desktop
                    <div style={{display:"flex",flexDirection:"column",width:"100%",padding:"10px 20px",gap:10,fontSize:14}}>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center",alignItems:"center",fontWeight:"bold",fontSize:20,position:"relative"}}>
                            <Button color="primary" style={{position:"absolute",left:0,padding:"2px 10px",fontSize:13}} onClick={()=>{
                                this.getLeadPoolHistory()
                            }}>
                                Lead Pool History
                            </Button>
                            Lead Pool
                        </div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10,}}>
                            <Input placeholder="Use filter by below to create query"
                                value={this.state.findField} style={{width:"100%"}}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchTicket()
                                    }
                                }}
                                onChange={(e)=>{
                                    this.setState({findField:e.target.value})
                            }} />
                            <Button color="danger" style={{whiteSpace:"nowrap"}} onClick={()=>{
                                this.setState({findField:""})
                            }}>
                                Clear Query
                            </Button>
                            <Button color="primary" style={{paddingLeft:30,paddingRight:30}} onClick={()=>{
                                this.searchTicket()
                            }}>
                                Search
                            </Button>
                        </div>

                        <div>
                            Total {this.state.allPoolCount} results found.
                        </div>

                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",padding:"0px 0px 5px 0px",gap:10}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap"}}>
                                <div>Filter by:</div>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Ticket Number",filterTicketNumber:""})
                                }}>
                                    Ticket Number
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Member",filterMember:""})
                                }}>
                                    Member
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Phone",filterClientPhone:""})
                                }}>
                                    Client Phone
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Name",filterClientName:""})
                                }}>
                                    Client Name
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Client Email",filterClientEmail:""})
                                }}>
                                    Client Email
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Referrer Phone",filterReferrerPhone:""})
                                }}>
                                    Referrer Phone
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Channel",filterChannel:[]})
                                }}>
                                    Channel
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Interest",filterInterest:""})
                                }}>
                                    Interest
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Status",selectedStatusList:[]})
                                }}>
                                    Status
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Last Update",filterLastUpdateFrom:"",filterLastUpdateTo:"",})
                                }}>
                                    Last Update
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Create Date",filterCreateDateFrom:"",filterCreateDateTo:""})
                                }}>
                                    Create Date
                                </Button>
                                <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                    this.setState({filterModal:true,filterMode:"Date Added",filterDateAddedFrom:"",filterDateAddedTo:""})
                                }}>
                                    Date Added
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"maroon",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:还没联系,等待回复,安排预约,已经回复,有兴趣,购买课程"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Hot
                                </Button>
                                <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"yellow",color:"black"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:安排汇款,预付定金,分期付款,全额付款"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Payment
                                </Button>
                                <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"steelblue",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:不精准客户,无法联系,没兴趣"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Cold
                                </Button>
                                <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"grey",color:"white"}}
                                    onClick={()=>{
                                        this.setState({findField:"Status:完成报名,他人完成,Task Terminated"},()=>{
                                            this.searchTicket()
                                        })
                                }}>
                                    Closed
                                </Button>
                            </div>
                        </div>
                        
                        <table style={{fontSize:14,width:"100%"}}>
                            <tr>
                                {
                                GLOBAL.checkPermission("l?`ZcgRh")?
                                    <td style={styles.columnTitleStyle}>Company</td>
                                    :
                                    null
                                }
                                <td style={styles.columnTitleStyle}>Tix No.</td>
                                <td style={styles.columnTitleStyle}>Member</td>
                                <td style={styles.columnTitleStyle}>Phone</td>
                                <td style={styles.columnTitleStyle}>Name</td>
                                <td style={styles.columnTitleStyle}>Email</td>
                                <td style={styles.columnTitleStyle}>Referrer</td>
                                <td style={styles.columnTitleStyle}>Channel</td>
                                <td style={styles.columnTitleStyle}>Interest</td>
                                <td style={styles.columnTitleStyle}>Status</td>
                                <td style={styles.columnTitleStyle}>Last Update</td>
                                <td style={styles.columnTitleStyle}>Create Date</td>
                                <td style={styles.columnTitleStyle}>Date Added</td>
                                <td style={{}}></td>
                            </tr>
                            {
                            this.state.poolList.map((ticket)=>{
                                return(
                                    <tr>
                                        {
                                        GLOBAL.checkPermission("l?`ZcgRh")?
                                            <td style={styles.columnTitleStyle}>{ticket.agentCompany}</td>
                                            :
                                            null
                                        }
                                        <td style={styles.cellBorder}>
                                            {
                                            this.state.isStaffOrAgentLeader?
                                                <a href={"/ticket?id="+ticket.id} target="_blank" style={{color:"blue",textDecoration:"underline"}}>{ticket.id}</a>
                                                :
                                                ticket.id
                                            }
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.target!=null?"Member":ticket.lead!=null?"Lead":"-"}
                                        </td>
                                        <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                            {ticket.target!=null?ticket.target.username:ticket.lead!=null?ticket.lead.phone:"-"}
                                        </td>
                                        <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                            {ticket.target!=null?ticket.target.name:ticket.lead!=null?ticket.lead.name:"-"}
                                        </td>
                                        <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                            {ticket.target!=null?ticket.target.email:ticket.lead!=null?ticket.lead.email:"-"}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.referrerString}
                                        </td>
                                        <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                            {ticket.channel}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.interest}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.status}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.lastUpdate}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.createDate}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            {ticket.dateAdded}
                                        </td>
                                        <td style={styles.cellBorder}>
                                            <Button color="primary" style={{}} onClick={()=>{
                                                this.takeTicket(ticket)
                                            }}>
                                                Take
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </table>

                        <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                            {this.state.leadPoolPageCount > 0 && (
                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.leadPoolPage}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.leadPoolPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleLeadPoolPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.leadPoolPage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

let styles={
    columnTitleStyle:{
        fontWeight:"bold",
        border:"1px solid lightgrey",
        backgroundColor:"#f7f7f7",
        padding:5,
        minWidth:60
    },
    cellBorder:{
        border:"1px solid lightgrey",
        padding:5,
        minWidth:60
    },
    filterButtonStyle:{
        fontSize:13,
        padding:"2px 10px",
        border:"1px solid grey"
    },
    filterButtonStyleMobile:{
        fontSize:10,
        padding:"1px 5px",
        border:"1px solid grey"
    },
}
